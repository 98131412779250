<template>
  <div class="intro-wrap">
    <div class="intro-bi">
      <router-link :to="{ name: 'IntroLogin' }" replace>
        <img src="assets/image/jangboda_bi.svg" alt="마켓나우" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
  mounted() {
    setTimeout(() => {
      let passToken = localStorage.getItem('autoLogin')
      if(passToken) {
        let formData = new FormData()
        formData.append('passToken', passToken)

        this.$post(this.$USER_AUTOLOGIN, 'intro_login_doAutoLogin', formData, (result) => {
          sessionStorage.removeItem('address')
          sessionStorage.removeItem('market')

          sessionStorage.setItem('token', result.data.accessToken)
          sessionStorage.setItem('refresh_token', result.data.refreshToken)
          localStorage.setItem('autoLogin', result.data.passToken)


          setTimeout(() => {
            this.refreshToken()
          }, this.refreshTokenRate)

          /*일반 회원 자동로그인일때만 토픽 등록*/
          if(localStorage.getItem('guest') !== 'true') {
            this.refreshPush()
            this.setPushAlarm(() => {
              this.$router.push({ name: 'Dashboard' })
            })
          } else {
            this.$router.push({ name: 'Dashboard' })
          }

        }, (result) => {

          sessionStorage.removeItem('token')
          sessionStorage.removeItem('refresh_token')
          localStorage.removeItem('autoLogin')

          this.guestLogin()
          if(localStorage.getItem('guest') !== 'true') {
            this.httpError(result.data)
          }
        })
      } else {
        this.guestLogin()
      }
    }, 500)
  },

  methods: {
    guestLogin() {
      this.$post(this.$USER_LOGIN_GUEST, 'intro_login_doGuestLogin', null, (result) => {
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('market')

        sessionStorage.setItem('token', result.data.accessToken)
        sessionStorage.setItem('refresh_token', result.data.refreshToken)
        localStorage.setItem('autoLogin', result.data.passToken)
        localStorage.setItem('guest', 'true')

        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)

        this.$router.push({ name: 'Dashboard'})
      }, (result) => {
        this.httpError(result.data)

        sessionStorage.removeItem('token')
      })
    }
  }
}
</script>

<style scoped>

</style>
