<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <button type="button" class="btn btn-back" @click="doBack($route.params.back)">
        <i class="icon-back"></i>
      </button>
      <p>주문하기</p>
    </header>
    <div class="cart-wrap mt-50">
      <p class="page-title mt-10">주문내역</p>
      <div v-for="(shop, shopIdx) in orderForm.shops" v-bind:key="shopIdx" class="product-wrap">
        <div class="title">
          {{ shop.shopName }}
          <strong class="color-pink">{{ shop.marketName }}</strong>
        </div>
        <div class="products">
          <div v-for="(product, productIdx) in shop.product" v-bind:key="productIdx" class="product">
            <strong class="name">{{ product.productName }} {{ product.quantity }}개</strong>
            {{ textOptions(product) }}
          </div>
          <div class="request-box input-group">
            <strong>주문 요청사항</strong>
            <input type="text" class="input-default mb-0" placeholder="주문시 요청사항을 입력하세요." v-model="request[shop.shopIdx]" maxlength="80"/>
          </div>
        </div>
      </div>
      <div class="payment-info">
        <ul>
          <li class="mb-15"><strong>배달정보</strong></li>
        </ul>
        <div class="address-wrap b-none">
          <div class="b-line-card  p-0 mb-20">
            <strong>{{ address.parcel }}</strong>
            <span>도로명</span>{{ address.road }} <small>({{ address.detail }})</small>
          </div>
        </div>
        <div class="input-group">
          <strong>주문자명</strong>
          <input type="text" name="request[]" class="input-default" placeholder="주문자명" v-model="address.name">
        </div>
        <div class="input-group mb-5">
          <strong>휴대폰 번호</strong>
          <input type="text" name="request[]" class="input-default mb-0" placeholder="주문시 요청사항을 입력하세요." v-model="address.hp">
        </div>
      </div>
      <div v-if="orderForm.deliverySelector.deliveryTimes.length + orderForm.nowDeliverySelector.deliveryTimes.length > 0" class="payment-info mt-10">
        <ul>
          <li class="s-margin"><strong>배송시간 선택</strong></li>
        </ul>
        <div class="radio-wrap" >
          <div v-for="(deliveryTime, deliveryTimeIdx) in orderForm.deliverySelector.deliveryTimes" v-bind:key="deliveryTime.deliveryIdx">
            <input type="radio" name="delivery_time" v-bind:id="'delivery_time_'+ deliveryTimeIdx" v-bind:value="deliveryTimeIdx" v-model="selectedDeliveryTime" @change="selectedNowDeliveryTime = ''">
            <label v-bind:for="'delivery_time_'+deliveryTimeIdx">{{ deliveryTimeIdx+1 }}차 : {{ deliveryTime.departureTimeH }}시 {{ deliveryTime.departureTimeM }}분 출발 ({{ deliveryTime.deadLineTimeH }}시 {{ deliveryTime.deadLineTimeM }}분 접수마감)</label>
          </div>
          <div v-for="(nowDeliveryTime, nowDeliveryTimeIdx) in orderForm.nowDeliverySelector.deliveryTimes" v-bind:key="nowDeliveryTime.deliveryIdx">
            <input type="radio" name="nowDelivery_time" v-bind:id="'nowDelivery_time_'+ nowDeliveryTimeIdx" v-bind:value="nowDeliveryTimeIdx" v-model="selectedNowDeliveryTime" @change="selectedDeliveryTime = ''">
            <label v-bind:for="'nowDelivery_time_'+ nowDeliveryTimeIdx">즉시배송 : {{ nowDeliveryTime.departureTimeH }}시 {{ nowDeliveryTime.departureTimeM }}분부터 {{ nowDeliveryTime.deadLineTimeH }}시 {{ nowDeliveryTime.deadLineTimeM }}분까지 접수가능</label>
          </div>
        </div>
        <p v-show="selectedDeliveryTime !== ''">
          지금 주문하시면 <strong class="color-pink">{{ textDeliveryTime() }} 출발</strong> 기준<br />
          <strong class="color-pink">{{ textEstimatedTime() }}</strong>에 도착 예정 입니다.
        </p>
        <p v-show="selectedNowDeliveryTime !== ''">
          지금 주문하시면 주문시간 기준 <br /><strong class="color-pink">1시간 이내</strong>로 도착할 예정입니다.
        </p>
      </div>
      <div class="payment-info mt-10">
        <ul>
          <li class="s-margin">
            <strong>결제 수단 선택</strong>
          </li>
        </ul>
        <div class="radio-wrap" >
          <div v-for="(method, methodIdx) in orderForm.paymentMethod" v-bind:key="methodIdx">
            <input type="radio" name="payment_type" v-bind:id="'payment_type_'+methodIdx" v-bind:value="method" v-model="paymentMethod">
            <label v-bind:for="'payment_type_'+methodIdx">{{ textMethod(method) }}</label>
          </div>
        </div>
      </div>
      <div class="payment-info mt-10 mb-0">
        <ul>
          <li class="s-margin"><strong>결제금액</strong></li>
          <hr />
          <li>총 상품금액 <span class="product-total">{{ orderForm.totalPrice|numberWithCommas }}원</span></li>
          <li>배달요금 <span class="delivery-fee">{{ orderForm.deliveryPrice|numberWithCommas }}원</span></li>
          <hr />
          <li>결제금액<strong class="color-pink">{{ orderForm.orderPrice|numberWithCommas }}원</strong></li>
        </ul>
      </div>
      <!-- 통신판매중개자 안내 추가 -->
      <div class="company-info">
        <p>
          <span class="company-info-icon">
            <img src="/assets/image/icon_caution.svg" alt="통신판매중개자 안내" />
          </span>
          <span class="company-info-ment">
            ㈜모스트피엔에스는 통신판매중개자로서 <br />‘마켓나우’ 서비스의 거래당사자가 아닙니다.
          </span>
        </p>
        <p>
          따라서 ㈜모스트피엔에스는 상품정보 및 거래에 대하여 책임을 지지 않습니다. 상품 및 거래에 관하여 정확한 정보는 해당 판매자에게 직접 확인하여 주시기 바랍니다.
        </p>
      </div>
      <div v-if="orderForm.deliverySelector.deliveryTimes.length > 0 || orderForm.nowDeliverySelector.deliveryTimes.length > 0" class="fnb-btn-group">
        <button type="button" class="btn btn-pink" @click="setPayment()">결제하기</button>
      </div>
      <div v-else class="fnb-btn-group">
        <div class="w-100" style="height: 50px; color: #909090; background-color: #e0e0e0; text-align: center;line-height: 50px; font-size: 17px; font-weight: 500;">현재 배달 불가능한 시간 입니다</div>
      </div>
    </div>
    <form id="payment-form" action="" method="POST">
    </form>
  </div>
</template>

<script>

export default {
  name: "Payment",
  data() {
    return {
      request: [],
      orderForm: {
        deliveryPrice: 0,
        deliverySelector: {
          deliveryTimes: [],
          estimatedTimeH: '',
          estimatedTimeM: ''
        },
        nowDeliverySelector: {
          deliveryTimes: [],
          estimatedTimeH: '',
          estimatedTimeM: ''
        },
        orderPrice: 0,
        shops: [],
        totalPrice: 0,
        paymentMethod: [],
      },
      address: {
        road: '',
        parcel: '',
        detail: '',
        name: '',
        hp: '',
      },
      selectedDeliveryTime: '',
      selectedNowDeliveryTime: '',
      paymentMethod: '0',
      popup: {
        closed: false
      },
      isProcessing: false,
      timer: null,
    }
  },
  mounted() {
    this.getOrderForm()
    this.getAddress((result) => {
      if(result.success) {
        this.address = result.data
        if (!this.address.road) {
          this.notify('error', '내 동네 설정이 필요합니다.')
          this.$router.push({name: 'Market'})
        }
      } else {
        this.httpError(result.data)
      }
    })

    // window.addEventListener('OPEN_SUCCESS', this.goSuccess);
    // window.addEventListener('OPEN_FAIL', this.goFail);
  },
  methods: {
    textMethod(method) {
      if(method === '0') {
        return '카드결제'
      } else if(method === '1') {
        return '계좌이체'
      } else if(method === '2') {
        return '모바일 온누리 상품권'
      } else if(method === '3') {
        return '만나서 현금결제'
      }
    },
    confirm() {
      this.$root.$refs.Confirm.showDialog('주문 하시겠습니까?', '예', '아니오', () => {
        this.setPayment()
      }, () => {})
    },
    textOptions(product) {
      let result = product.option?product.option.name+', ':''
      for(let i=0; i<product.addOption.length; i++) {
        result += product.addOption[i].name + ', '
      }
      return result.substr(0, result.length-2)
    },
    textDeliveryTime() {
      if(this.orderForm.deliverySelector.deliveryTimes.length + this.orderForm.nowDeliverySelector.deliveryTimes.length === 0) {
        return ''
      }

      let h = 0
      let m = 0

      if(this.selectedDeliveryTime !== '') {
        h = this.orderForm.deliverySelector.deliveryTimes[this.selectedDeliveryTime].departureTimeH
        m = this.orderForm.deliverySelector.deliveryTimes[this.selectedDeliveryTime].departureTimeM
      } else if(this.selectedNowDeliveryTime !== '') {
        h = this.orderForm.nowDeliverySelector.deliveryTimes[this.selectedNowDeliveryTime].departureTimeH
        m = this.orderForm.nowDeliverySelector.deliveryTimes[this.selectedNowDeliveryTime].departureTimeM
      }

      return h+'시 '+m+'분'
    },
    textEstimatedTime() {
      if(this.orderForm.deliverySelector.deliveryTimes.length + this.orderForm.nowDeliverySelector.deliveryTimes.length === 0) {
        return ''
      }

      let h = 0
      let m = 0

      if(this.selectedDeliveryTime !== '') {
        h = parseInt(this.orderForm.deliverySelector.deliveryTimes[this.selectedDeliveryTime].departureTimeH) + parseInt(this.orderForm.deliverySelector.estimatedTimeH)
        m = parseInt(this.orderForm.deliverySelector.deliveryTimes[this.selectedDeliveryTime].departureTimeM) + parseInt(this.orderForm.deliverySelector.estimatedTimeM)
      } else if(this.selectedNowDeliveryTime !== '') {
        h = parseInt(this.orderForm.nowDeliverySelector.deliveryTimes[this.selectedNowDeliveryTime].departureTimeH) + parseInt(this.orderForm.nowDeliverySelector.estimatedTimeH)
        m = parseInt(this.orderForm.nowDeliverySelector.deliveryTimes[this.selectedNowDeliveryTime].departureTimeM) + parseInt(this.orderForm.nowDeliverySelector.estimatedTimeM)
      }

      if(m >= 60) {
        h+=1
        m-=60
      }

      return h.toString().padStart(2, '0')+'시 '+m.toString().padStart(2, '0')+'분'
    },
    getOrderForm() {
      this.$get(this.$USER_ORDER_FORM, 'payment_getOrderForm', (result) => {
        if(result.data.shops.length === 0) {
          this.notify('error', '장바구니에 상품이 없습니다!')
          this.$router.push({ name: 'Dashboard'})
        }
        this.orderForm = result.data
        if (this.orderForm.deliverySelector.deliveryTimes.length > 0) {
          this.selectedDeliveryTime = '0'
          this.selectedNowDeliveryTime = ''
        } else if (this.orderForm.nowDeliverySelector.deliveryTimes.length > 0) {
          this.selectedDeliveryTime = ''
          this.selectedNowDeliveryTime = '0'
        } else {
          this.selectedDeliveryTime = ''
          this.selectedNowDeliveryTime = ''
        }
      }, (result) => {
        this.httpError(result.data)
      })
    },
    setPayment() {
      // /*test*/
      // var result = {
      //   data: {
      //     src: 'https://pg.mostpns.co.kr/paymentgateway/mobile/reqPay.do',
      //     params: {
      //       STOREID: 'MC21000001',
      //       STORE_NAME: 'mpos',
      //       STORE_URL: 'https://user.mpos.kr',
      //       STORE_LOGO: 'https://demo.mostpns.co.kr/images/logo_shop.png',
      //       CRYPTO_KEY: '4BD2CDFAF9E83DF4EAF12914CBB5F9DCF',
      //       BUSINESS_NO: '1231212345',
      //       TX_USER_DEFINE: '통장명^1231212345^PG사명^0212341234',
      //       TRAN_NO: '240729BND00014',
      //       PRODUCT_TYPE: '2',
      //       GOODS_NAME: '시루떡',
      //       AMT: '1004',
      //       QUANTITY: 1,
      //       SALE_DATE: '240729',
      //       CUSTOMER_NAME: '모스트피엔에스',
      //       CUSTOMER_EMAIL: 'tester@mostpns.co.kr',
      //       CUSTOMER_TEL: '01012341234',
      //       CUSTOMER_IP: '110.15.204.218',
      //       NOTICE_URL: 'https://user.mpos.kr/noti',
      //       RETURN_URL: 'https://demo.mostpns.co.kr/demo_mobile/prepay_payment_return.jsp',
      //       COMPLETE_URL: 'https://demo.mostpns.co.kr/demo_mobile/prepay_payment_complete.jsp',
      //       // COMPLETE_URL: 'http://user.mpos.kr/result/success.html',
      //       FAIL_URL: 'https://demo.mostpns.co.kr/demo_mobile/prepay_payment_cancel.jsp',
      //       // FAIL_URL: 'http://user.mpos.kr/result/fail.html',
      //       ETC_DATA1: '테스트 데이터 여분1',
      //       ETC_DATA2: '테스트 데이터 여분2',
      //       ETC_DATA3: '테스트 데이터 여분3',
      //       TRAN_TYPE: 'CARD',
      //       btnSubmit: '결제요청',
      //     }
      //   }
      // }
      //
      // window.$('#payment-form').attr('action', result.data.src)
      // window.$('#payment-form').html('')
      // for(const [key, value] of Object.entries(result.data.params)) {
      //   window.$('<input>').attr({
      //     type: 'hidden',
      //     name: key,
      //     value: value,
      //   }).appendTo('#payment-form')
      // }
      // window.$('#payment-form').attr('target', 'popupChk')
      // window.$('#payment-form').attr('accept-charset', 'utf-8')
      // this.popup = window.open(result.data, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      // this.timer = setTimeout(() => {
      //   this.checkPopup()
      // }, 500)
      //
      // window.$('#payment-form').submit()
      //
      // /*test end*/

      if(this.isProcessing) {
        this.notify('error', '결제가 진행중입니다!')
        return
      }

      this.isProcessing = true

      let formData = new FormData()
      if(this.selectedDeliveryTime !== '' && this.selectedNowDeliveryTime === '') {
        formData.append('deliveryTimeIdx', this.orderForm.deliverySelector.deliveryTimes[this.selectedDeliveryTime].deliveryIdx)
      } else if (this.selectedNowDeliveryTime !== '' && this.selectedDeliveryTime === '') {
        formData.append('deliveryTimeIdx', this.orderForm.nowDeliverySelector.deliveryTimes[this.selectedNowDeliveryTime].deliveryIdx)
      }

      formData.append('name', this.address.name)
      formData.append('parcel', this.address.parcel)
      formData.append('paymentMethod', this.paymentMethod)
      formData.append('road', this.address.road)
      formData.append('tel', this.address.hp)
      if (this.address.detail && this.address.detail != '') {
        formData.append('subAddress', this.address.detail)
      }
      let i=0
      for (const [key, value] of Object.entries(this.request)) {
        formData.append('requires['+i+'].require', value.trim())
        formData.append('requires['+i+'].shopIdx', key)
        i++
      }

      this.$post(this.$USER_ORDER_PAYMENT, 'payment_setPayment', formData, (result) => {
        if(this.paymentMethod === '0') {
          window.$('#payment-form').attr('action', result.data.src)
          window.$('#payment-form').html('')
          for(const [key, value] of Object.entries(result.data.params)) {
            window.$('<input>').attr({
              type: 'hidden',
              name: key,
              value: value,
            }).appendTo('#payment-form')
          }
          window.$('#payment-form').attr('target', 'popupChk')
          window.$('#payment-form').attr('accept-charset', 'utf-8')
          this.popup = window.open(result.data, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
          this.timer = setTimeout(() => {
            this.checkPopup()
          }, 500)

          window.$('#payment-form').submit()
        } else if(this.paymentMethod === '2') {
          this.popup = window.open(result.data, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
        } else if(this.paymentMethod === '3') {
          this.$router.push({ name: 'PaymentSuccess' })
        } else {
          this.notify('error', '사용 불가능한 결제방법입니다.')
          this.$router.push({ name: 'PaymentFail' })
        }
      }, (result) => {
        this.httpError(result.data)
        this.$router.push({ name: 'PaymentFail' })
      })
    },
    checkPopup() {
      if(!this.popup.closed) {
        this.timer = setTimeout(() => {
          this.checkPopup()
        }, 500)
      } else {
        this.notify('error', '결제가 취소되었습니다!')

        this.isProcessing = false
      }
    },
    goSuccess() {
      this.$router.push({ name: 'PaymentSuccess' })
    },
    goFail() {
      this.$router.push({ name: 'PaymentFail' })
    }
  },
  beforeDestroy() {
    if(this.timer) {
      clearTimeout(this.timer)
    }
    // window.removeEventListener('OPEN_SUCCESS', this.goSuccess);
    // window.removeEventListener('OPEN_FAIL', this.goFail);
  },
}
</script>

<style scoped>

</style>
