<template>
  <div class="all-content">
    <terms-use ref="termsUse"></terms-use>
    <terms-privacy ref="termsPrivacy"></terms-privacy>
    <terms-electronic ref="termsElectronic"></terms-electronic>
    <terms-marketing ref="termsMarketing"></terms-marketing>
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>약관</p>
    </header>
    <div class="mypage-content bg-white">
      <div>
        <div class="mypage-box" @click="showTermsUse()">
          <span>이용약관</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
        <div class="mypage-box" @click="showTermsPrivacy()">
          <span>개인정보 처리방침</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
        <!--<div class="mypage-box" @click="showTermsElectronic()">
          <span>전자금융거래 약관</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
        <div class="mypage-box" @click="showTermsMarketing()">
          <span>마케팅수신 약관</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import TermsElectronic from "@/components/regist/terms/electronic";
import TermsMarketing from "@/components/regist/terms/marketing";
import TermsPrivacy from "@/components/regist/terms/privacy";
import TermsUse from "@/components/regist/terms/use";

export default {
  name: "MypageAgreement",
  components: {
    TermsElectronic,
    TermsMarketing,
    TermsPrivacy,
    TermsUse,
  },
  methods: {
    showTermsUse() {
      this.$refs.termsUse.toggle()
    },
    showTermsPrivacy() {
      this.$refs.termsPrivacy.toggle()
    },
    showTermsElectronic() {
      this.$refs.termsElectronic.toggle()
    },
    showTermsMarketing() {
      this.$refs.termsMarketing.toggle()
    },
  }
}
</script>

<style scoped>

</style>