<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <div id="alert-wrap" class="alert-wrap"></div>
    <confirm ref="Confirm"></confirm>
  </div>
</template>

<script>
import Confirm from "@/components/confirm";
export default {
  name: 'App',
  components: {
    Confirm,
  },
  mounted() {
    this.$root.$refs.Confirm = this.$refs.Confirm
    let token = sessionStorage.getItem('token')
    if(token) {
      this.refreshToken()
    }
  },
  beforeCreate() {
    let console=(function(oldCons){
      return {
        log: function(){
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.log.apply(console, arguments)
          }
        },
        warn: function() {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.warn.apply(console, arguments)
          }
        },
        error: function() {
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.error.apply(console, arguments)
          }
        },
      };
    }(window.console));
    window.console = console
  }
}
</script>

<style>
#app { height: 100% }
</style>
