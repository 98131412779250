<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>1:1 문의</p>
    </header>
    <div class="board-form" v-bind:class="{ vertical: qnas.length === 0 }">
      <div v-if="qnas.length > 0" class="faq-wrap bg-white" style="padding-bottom: 50px;">
        <ul v-for="(qna, qnaIdx) in qnas" v-bind:key="qnaIdx">
          <li @click="toggleQna($event)">
            <i class="qna-helper" v-bind:class="qnaIdx===0?'icon-arrow-up':'icon-arrow-down'"></i>
            <div class="qna-helper" v-bind:class="{ 'answer-ing': !qna.answer, 'answer-end': qna.answer }">
              {{ qna.title }}
            </div>
            {{ qna.regDate }}
          </li>
          <li v-bind:style="qnaIdx!==0?'':'display: block'" class="answer bg-white">
            <div class="content">
              <div v-html="qna.title" class="title"></div>
              <hr>
              <div v-html="qna.content" class="content"></div>
              <img v-if="qna.imagePath" v-bind:src="getImage(qna.imagePath, 1000, null, null)" alt="문의 이미지" @error="getOriginImage($event, qna.imagePath)">
            </div>
            <div v-if="qna.answer" class="answer">
              <h5>1:1 문의 답변</h5><span>{{ qna.answerDate }}</span>
              <div class="content" v-html="qna.answer"></div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="qnas.length === 0" class="ment">
        <img src="/assets/image/character_sad.svg" alt="내역이 없습니다!" />
        <strong>내역이 없습니다!</strong>
      </div>
    </div>
    <div class="fnb-btn-group">
      <button type="button" class="btn btn-pink" @click="$router.push({ name: 'MypageQuestionInquiryWrite' })">1:1 문의하기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageQuestionInquiry",
  data() {
    return {
      qnas: [],
    }
  },
  mounted() {
    // this.getMarket((result2) => {
    //   if(!result2.success) {
    //     this.notify('error', '내 시장 설정이 필요합니다.')
    //     this.$router.push({ name: 'Mypage' })
    //
    //     return
    //   }
    // })
    this.getQna()
  },
  methods: {
    getQna() {
      this.$get(this.$USER_QNA, 'inquiry_getQna', (result) => {
        this.qnas = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleQna(event) {
      if(window.$(event.target).parent().children('li:last-child').is(":visible")) {
        window.$(event.target).children('i').removeClass('icon-arrow-up')
        window.$(event.target).children('i').addClass('icon-arrow-down')

        window.$(event.target).parent().children('li:last-child').slideUp()
      } else {
        window.$(event.target).children('i').removeClass('icon-arrow-down')
        window.$(event.target).children('i').addClass('icon-arrow-up')

        window.$(event.target).parent().children('li:last-child').slideDown()
      }
    },
  }
}
</script>

<style scoped>
.qna-helper {
  pointer-events: none;
}
</style>
