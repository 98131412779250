<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'Mypage' })"><i class="icon-back"></i></button>
      <p>개인정보설정</p>
    </header>
    <div class="mypage-content">
      <div class="mypage-shop-info">
        <div class="mypage-setup-box">
          <span class="subject">이메일</span>
          <span class="e-mail">{{ address.email }}</span>
        </div>
        <div class="mypage-setup-box">
          <span class="subject">휴대폰 번호</span>
          <div>
            <span class="phone-number">{{ address.hp }}</span>
            <router-link :to="{ name: 'MypageChangePhone' }" class="btn-mini btn-outline-pink br-15">변경 인증</router-link>
          </div>
        </div>
      </div>
      <div class="mypage-content-list mypage-content-setup bg-white">
        <div class="mypage-box" v-show="address.type === '0'" @click="$router.push({ name: 'MypageResetPassword' })">
          <span>비밀번호 변경</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
        <div class="mypage-box" @click="$router.push({ name: 'Market' })">
          <span>주소 변경</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
        <div class="mypage-box">
          <span class="app-push-title">앱 푸시설정</span>
          <div class="mypage-setup-push">
            <span class="subject">시장 할인 등 마케팅 정보 수신 동의</span>
            <p class="toggle-group" @click="toggleAlarm(0, $event)"><span class="btn-toggle" v-bind:class="push.alarmMarket?'on':'off'"></span></p>
          </div>
          <div class="mypage-setup-push">
            <span class="subject">서비스 관련 수신 동의</span>
            <p class="toggle-group" @click="toggleAlarm(1, $event)"><span class="btn-toggle" v-bind:class="push.alarmService?'on':'off'"></span></p>
          </div>
          <div class="mypage-setup-push">
            <span class="subject">결제 관련 수신 동의</span>
            <p class="toggle-group" @click="toggleAlarm(2, $event)"><span class="btn-toggle" v-bind:class="push.alarmPayment?'on':'off'"></span></p>
          </div>
        </div>
        <div class="mypage-box" v-show="address.type === '0'" @click="leave()">
          <span>회원 탈퇴</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
      </div>
    </div>
    <div class="fnb-btn-group">
      <button type="button" class="btn btn-pink" @click="logout()">로그아웃</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageSetup",
  data() {
    return {
      push: {
        alarmMarket: false,
        alarmPayment: false,
        alarmService: false,
      },
      address: {
        email: '',
        hp: '',
        type: '',
      },
    }
  },
  mounted() {
    if(localStorage.getItem('guest') === 'true') {
      this.$router.push({ name: 'Dashboard' })
    }
    this.getAddress((result) => {
      if(result.success) {
        this.address = result.data
      } else {
        this.httpError(result.data)
      }
    })
    this.getPush()
  },
  methods: {
    leave() {
      this.$root.$refs.Confirm.showDialog(`탈퇴하시겠습니까? <br/> 탈퇴시 현재 사용중인 아이디로 재가입이 제한될 수 있습니다.`, '탈퇴', '취소', () => {
        this.$post(this.$USER_SECESSION, 'setup_leave', null, () => {
          this.notify('success', '정상적으로 탈퇴 되었습니다.')
          this.logout()
        }, (result) => {
          this.httpError(result.data)
        })

      }, () => {

      })
    },
    getPush() {
      this.$get(this.$USER_PUSH, 'setup_getPush', (result) => {
        this.push = result.data
        localStorage.setItem('alarm', JSON.stringify(this.push))
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleAlarm(type, e) {
      let alarmBool = e.target.classList.contains('on')

      this.getMarket((result) => {
        let marketIdx = result.data.idx

        if(type === 0) {
          if(alarmBool) {
            this.push.alarmMarket = false
            window.unsubscribedTopic('event_all')
            window.unsubscribedTopic(`event_${marketIdx}`)
          } else {
            this.push.alarmMarket = true
            window.subscribedTopic('event_all')
            window.subscribedTopic(`event_${marketIdx}`)
          }
        }
        if(type === 1) {
          if(alarmBool) {
            this.push.alarmService = false
            window.unsubscribedTopic('service_all')
            window.unsubscribedTopic(`service_${marketIdx}`)
          } else {
            this.push.alarmService = true
            window.subscribedTopic('service_all')
            window.subscribedTopic(`service_${marketIdx}`)
          }
        }
        if(type === 2) {
          this.push.alarmPayment = !alarmBool
        }
        localStorage.setItem('alarm', JSON.stringify(this.push))

        let formData = new FormData()
        formData.append('type', type)

        this.$put(this.$USER_PUSH, 'setup_toggleAlarm', formData, (result) => {
          this.push = result.data
        }, (result) => {
          this.httpError(result.data)
        })

      })

    },
    logout() {
      if(localStorage.getItem('guest') === 'true') {
        localStorage.removeItem('autoLogin')
        localStorage.removeItem('guest')

        /*기존 게스트 계정 삭제 api추가*/
        this.$delete(this.$USER_GUEST, 'setup_logout', () => {
          console.log('guest deleted')

          sessionStorage.removeItem('token')
          sessionStorage.removeItem('refresh_token')
          sessionStorage.removeItem('address')
          sessionStorage.removeItem('market')
          sessionStorage.removeItem('DashboardTab')
          sessionStorage.removeItem('DashboardShopInfoTab')

          this.$router.replace({ name: 'Intro' })
        }, (result) => {
          console.log('guest delet failed', result)

          sessionStorage.removeItem('token')
          sessionStorage.removeItem('refresh_token')
          sessionStorage.removeItem('address')
          sessionStorage.removeItem('market')
          sessionStorage.removeItem('DashboardTab')
          sessionStorage.removeItem('DashboardShopInfoTab')

          this.$router.replace({ name: 'Intro' })
        })

      } else {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('market')
        sessionStorage.removeItem('DashboardTab')
        sessionStorage.removeItem('DashboardShopInfoTab')

        localStorage.removeItem('autoLogin')

        this.$router.replace({ name: 'Intro' })
      }

    },
  }
}
</script>

<style scoped>

</style>
