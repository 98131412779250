<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <button type="button" class="btn btn-sns" @click="share()">
        <img src="/assets/image/btn_sns.svg" />
      </button>
      <p>{{ order.shop.shopName }}</p>
      <button type="button" class="btn btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="cart-wrap mt-50">
      <p class="top-title">주문내역</p>
      <div class="ship-wrap">
        <div class="address-wrap" v-show="order.shop.cancelReason !== null">
          <div class="b-line-card">
            <strong class="mb-5 order-cancel">주문 취소된 주문내역입니다.</strong>
            <span class="cancel">취소사유 :</span>
            <span class="cancel" v-html="order.shop.cancelReason"></span>
          </div>
        </div>
        <div class="address-wrap">
          <div class="b-line-card">
            <strong class="mb-5">배송정보</strong>
            <p class="address">{{ order.userParcelAddress }}</p>
            <p class="address">{{ order.userDetailAddress }}</p>
            <span class="address">도로명</span>
            {{ order.userRoadAddress }}
          </div>
        </div>
        <div class="ship_info">
          <ul>
            <li>
              <span class="label">주문자명</span>
              <span class="content">{{ order.userName }}</span>
            </li>
            <li>
              <span class="label">휴대폰 번호</span>
              <span class="content">{{ order.userPh }}</span>
            </li>
            <li>
              <span class="label">배달시간</span>
              <span class="content">{{ order.deliveryTime }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="product-wrap">
        <div class="products">
          <div v-for="(product, productIdx) in order.shop.product" v-bind:key="productIdx" class="product">
            <strong class="name">{{ product.productName }} {{ product.quantity }}개</strong>
            <p v-if="product.option" class="option">{{ product.option.name }} : {{ (product.price + product.option.price)|numberWithCommas }}원</p>
            <p v-else class="option">{{ product.price|numberWithCommas }}원</p>
            <p v-for="(addOption, addOptionIdx) in product.addOption" v-bind:key="addOptionIdx" class="option">{{ addOption.name }} : {{ addOption.price|numberWithCommas }}원</p>
            <p class="price">{{ product.totalPrice|numberWithCommas }}원</p>
          </div>
        </div>
      </div>
      <div class="payment-info">
        <ul>
          <li>
            <span class="payment-total">상품합계</span>
            <span class="payment-total color-pink">{{ order.shop.totalPrice|numberWithCommas }}원</span>
          </li>
          <li>
            <span class="payment-total">결제방법</span>
            <span class="payment-method">{{ textMethod(order.paymentMethod) }}</span>
          </li>
        </ul>
      </div>
      <!--TODO: 배달완료/주문취소의 주문내역일 경우 버튼 노출-->
      <div v-if="order.deletable" class="fnb-btn-group">
        <button class="btn btn-pink" @click="deleteOrder()">주문내역 삭제</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DashboardOrderInfo",
  data() {
    return {
      shopIdx: this.$route.params.shopIdx,
      orderIdx: this.$route.params.orderIdx,
      order: {
        orderPrice: 0,
        deliveryFee: 0,
        deletable: false,
        paymentMethod: "0",
        shop: {
          product: [],
          shopIdx: 0,
          totalPrice: 0,
          cancelReason: ''
        },
        userDetailAddress: '',
        userName: '',
        userParcelAddress: '',
        userPh: '',
        userRoadAddress: '',
      },
      isLineBreak: false,
    }
  },
  mounted() {
    this.getOrder()
  },
  methods: {
    deleteOrder() {
      this.$root.$refs.Confirm.showDialog('주문내역을 삭제하시겠습니까?', '예', '아니오', () => {
        this.$delete(this.$USER_ORDER+'/'+this.shopIdx+'/'+this.orderIdx, 'order_info_deleteOrder', () => {
          this.notify('success', '주문내역이 삭제되었습니다.')
          this.$router.back()
        }, (result) => {
          this.httpError(result.data)
        })
      }, () => {})
    },
    share() {
      window.shareShop(this.order.shop.shopName, window.location.origin + '/#/dashboard/shop/info/' + this.order.shop.shopIdx)
    },
    textMethod(method) {
      if(method === '0') {
        return '카드결제'
      } else if(method === '1') {
        return '계좌이체'
      } else if(method === '2') {
        return '모바일 온누리 상품권'
      } else if(method === '3') {
        return '만나서 현금결제'
      }
    },
    getOrder() {
      this.$get(this.$USER_ORDER + '/' + this.shopIdx + '/' + this.orderIdx, 'order_info_getOrder', (result) => {
        console.log('order', result.data)
        this.order = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>
