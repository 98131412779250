<template>
  <div class="tab-category">
    <ul class="tab-category-list">
      <li v-for="(category, categoryIdx) in categories" v-bind:key="categoryIdx" class="tab-list-item">
        <router-link :to="{ name: 'DashboardProduct', params: { category: category } }">
          <img v-bind:src="category.imgPath" />
          <span>{{ category.name }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DashboardTabsProduct",
  data() {
    return {
      categories: [],
    }
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      this.$get(this.$USER_CATEGORY, 'product_getCategory', (result) => {
        this.categories = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>