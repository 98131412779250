<template>
  <div class="overlay_popup">
    <header class="header-wrap">
      <p>장바구니 담기</p>
      <button type="button" class="btn btn-close" @click="$parent.popupDone()"><i class="icon-close"></i></button>
    </header>
    <div class="cart-add-popup">
      <div class="product-list">
        <div class="product-item">
          <div class="product-item-thumbnail">
            <img v-if="productInfo.imgPaths.length > 0" v-bind:src="getImage(productInfo.imgPaths[0], 200, 200, 1)" alt="상품 이미지" @error="getOriginImage($event, productInfo.imgPaths[0])">
            <img v-else src="/assets/image/thumbnail_preparing.svg" alt="이미지 준비중">
          </div>
          <div class="product-item-content">
            <p class="product-item-name">{{ productInfo.productName }}</p>
            <p class="product-item-memo" v-html="productInfo.productMemo">s</p>
          </div>
        </div>
        <p class="product-warning">
          ※해당 이미지는 실제 상품과 다를 수 있습니다.
        </p>
        <div class="product-option-wrap">
          <span class="product-name">판매 가격</span>
          <span class="product-price">{{ productInfo.price|numberWithCommas }}원</span>
        </div>
        <div class="product-option-wrap" v-show="productInfo.options.length > 0">
          <p class="product-option-name">상품 옵션</p>
          <div class="product-option-list">
            <ul>
              <li v-for="(item,idx) in productInfo.options" v-bind:key="idx">
                <div class="radio-wrap">
                  <input type="radio" name="product-option-item" v-bind:id="'product-option-item-'+idx" v-bind:value="idx" v-model="selectedOption">
                  <label v-bind:for="'product-option-item-'+idx">{{ item.name }}</label>
                </div>
                <p class="product-option-price">+ {{ item.price|numberWithCommas }}원</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="product-option-wrap" v-show="productInfo.addOptions.length > 0">
          <p class="product-option-name">추가 옵션</p>
          <div class="product-option-list">
            <ul>
              <li v-for="(item,idx) in productInfo.addOptions" v-bind:key="idx">
                <div class="checkbox-wrap">
                  <input type="checkbox" v-bind:id="'product-check-item-'+idx" v-model="selectedAddOptions[idx]">
                  <label v-bind:for="'product-check-item-'+idx">{{ item.name }}</label>
                </div>
                <p class="product-option-price">+ {{ item.price|numberWithCommas }}원</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="option-quantity-price border-top">
        <div class="option-quantity-wrap">
          <div class="option-quantity">
            <button type="button" class="btn" @click="removeQuantity()" v-bind:disabled="quantity===1">
              <i class="icon-minus"></i>
            </button>
            <input type="text" v-bind:value="quantity" readonly="readonly">
            <button type="button" class="btn" @click="addQuantity()">
              <i class="icon-plus"></i>
            </button>
          </div>
          <p class="color-pink">{{ total|numberWithCommas }}원</p>
        </div>
        <button type="button" class="btn btn-pink btn-option-basket br-0" @click="setCart()">장바구니 담기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardUtilsProduct",
  data() {
    return {
      idx: 0,
      productInfo: {
        addOptions: [],
        imgPaths: [],
        marketIdx: 0,
        options: [],
        price: 0,
        productMemo: '',
        productName: '',
        shopIdx: 0,
        shopName: '',
      },
      selectedOption: 0,
      selectedAddOptions: [],
      total: 0,
      quantity: 1,
    }
  },
  methods: {
    setIdx(idx) {
      this.idx = idx
      this.$get(this.$USER_PRODUCT_POP+'/'+idx, 'product_setIdx', (result) => {
        this.productInfo = result.data
        for(let i=0; i<this.productInfo.addOptions.length; i++) {
          this.selectedAddOptions[i] = false
        }

        this.getTotal()
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getTotal() {
      this.total = this.productInfo.price

      if(this.productInfo.options.length > 0) {
        this.total += this.productInfo.options[this.selectedOption].price
      }

      for(let i=0; i<this.productInfo.addOptions.length; i++) {
        if(this.selectedAddOptions[i]) {
          this.total += this.productInfo.addOptions[i].price
        }
      }

      this.total = this.total * this.quantity
    },
    addQuantity() {
      this.quantity++
    },
    removeQuantity() {
      if(this.quantity > 1) {
        this.quantity--
      }
    },
    setCart() {
      if(localStorage.getItem('guest') === 'true') {
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {

        })
      } else {
        let formData = new FormData()

        if(this.productInfo.options.length > 0) {
          formData.append('option', this.productInfo.options[this.selectedOption].idx)
        }

        for(let i=0; i<this.productInfo.addOptions.length; i++) {
          if(this.selectedAddOptions[i]) {
            formData.append('addOptions', this.productInfo.addOptions[i].idx)
          }
        }

        formData.append('productIdx', this.idx)
        formData.append('quantity', this.quantity)
        formData.append('shopIdx', this.productInfo.shopIdx)
        formData.append('shopName', this.productInfo.shopName)

        this.$post(this.$USER_CART, 'utils/product_setCart', formData, () => {
          this.$parent.popupDone()
        }, (result) => {
          this.httpError(result.data)
          this.$parent.popupDone()
        })
      }

    },
  },
  watch: {
    quantity() {
      this.getTotal()
    },
    selectedOption() {
      this.getTotal()
    },
    selectedAddOptions: {
      deep: true,
      handler() {
        this.getTotal()
      }
    },
  }
}
</script>

<style scoped>
.overlay_popup {
  z-index: 99;
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
</style>
