<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>아이디 찾기</p>
    </header>
    <div class="auth-form d-table">
      <p class="mb-30">
        * 회원님의 명으로 등록한 휴대폰으로 본인확인 후<br />
        &nbsp;&nbsp;&nbsp;아이디를 일치하는 아이디를 찾습니다.
      </p>
      <a role="button" class="btn btn-pink" @click="niceEncode()">본인명의 휴대폰으로 인증</a>
    </div>
    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FindId",
  data() {
    return {
      phone: '',
      nine: '',
    }
  },
  methods: {
    niceEncode() {
      axios.post("https://nice.reappay.net/check-pay-plus/request", null, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
      }).then(response => {
        if (response.data.success === true) {
          this.niceShow(response.data.enc_data)
        } else {
          console.log(response.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
    niceDone(e) {
      if(e.origin === 'https://nice.reappay.net') {
        this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.nice = e.data.payload

        window.removeEventListener('message', this.niceDone)

        this.niceCheck()
      }
    },
    niceCheck() {
      let formData = new FormData()
      formData.append('hp', this.phone.trim())
      formData.append('niceAuth', this.nice)

      this.$post(this.$USER_FIND_ID, 'find_id_niceCheck', formData, (result) => {
        this.$router.push({ name: 'FindIdResult', params: { id: result.data.id } })
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>