<template>
  <div class="overlay-content" v-if="show">
    <header class="header-wrap">
      <p>서비스 이용 동의</p>
      <button type="button" class="btn btn-close" @click="toggle()"><i class="icon-close"></i></button>
    </header>
    <div class="terms-txt-wrap">
      <h3 class="terms-title">(선택) 마케팅수신(푸시 알림)</h3>
      <div class="terms-detail">
        <h5>제 1조 (목적)</h5>
        <p>이 약관은 “모스트피엔에스”(이하 “회사”라 합니다.)가 운영하는 서비스의 이용과 관련하여 하는 “회사”와 “회원 또는 이용자”(이하 “회원 등”이라 합니다.)의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
        <h5>제 2조 (용어의 정의)</h5>
        <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <p class="terms-number">
          <span>①</span>
          <span>“모스트피엔에스 서비스”라 함은 단말기 (PC, 스마트폰 등 각종 유무선 장치 포함)와 상관없이 “회원”에게 “재화 또는 서비스” (이하 “재화 등”)의 정보 또는 광고를 제공하고 “재화 등”을 거래할 수 있도록 “회사가” 제공하는 “마켓나우 서비스”와 그 제반 서비스를 총칭합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회원”은 “회사”의 “서비스”에 접속하여 본 약관에 따라 이용계약을 체결하고 “회사”가 제공하는 서비스를 이용하는 자를 말합니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“이용자”는 “회사”의 “서비스”에 접속하여 “서비스”를 이용하는 “회원” 및 회원가입 없이 서비스를 이용하는 자를 통칭하여 말합니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“아이디”라 함은 “회원”을 식별하고 “서비스”를 이용하기 위하여 부여한 정보를 의미합니다.</span>
        </p>
        <p class="terms-number">
          <span>⑤</span>
          <span>“비밀번호”라 함은 “회원”의 “아이디”와 함께 “회원” 본인임을 확인하고 보호하기 위해 “회원” 자신이 정한 문자 또는 숫자 혹은 그 조합을 의미합니다.</span>
        </p>
        <p class="terms-number">
          <span>⑥</span>
          <span>“가맹점”이란 “재화 등”에 관한 정보를 “모스트피엔에스”에 게제하고 “회원 등”에게 판매하거나 포인트, 쿠폰 등의 혜택을 제공하는 사업자를 말합니다.</span>
        </p>
        <p class="terms-number">
          <span>⑦</span>
          <span>“메뉴판 서비스”란 QR코드를 스캔하여 “가맹점”의 메뉴를 확인 할 수 있는 서비스를 말합니다.</span>
        </p>
        <p class="terms-number">
          <span>⑧</span>
          <span>“결제방법”이라 함은 회원이 결제서비스를 통하여 전자적 방법으로 상품 등을 이용 및 구입 시 선택한 지불방법을 말합니다.</span>
        </p>
        <p>본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 모스트피엔에스 또는 립픽 서비스 안내 페이지 또는 관계법령에서 정하는 바에 따르며, 그 외에는 일반 상관례에 따릅니다.</p>
        <h5>제 3조 (약관의 게시와 개정)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 이 약관의 내용을 “회원 등”이 보기 쉽도록 “모스트피엔에스 서비스”의 초기화면에 게시합니다. 다만, 약관의 내용은 연결화면에 게시하거나 팝업창 등을 통하여 제공할 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률”, “약관의 규제에 관한 법률” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회사”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 “서비스”의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, “회원”에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “회사”는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 “회원”이 알기 쉽도록 표시합니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회사”가 전항에 따라 개정약관을 공지 또는 통지하는 경우, “회원“은 개정된 약관에 동의하지 않을 권리가 있으며, 이 경우에는 고지나 통지를 받은 날로부터 30일 이내에 계약을 해지할 수 있습니다. 다만, “회원”이 개정 약관의 적용일 전일까지 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의한 것으로 간주합니다.</span>
        </p>
        <h5>제 4조 (약관의 해석)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 선불전자지급수단의 충전, 환급 등 전자금융거래에 대해서는 별도의 약관(이하 “전자금융거래 이용약관”)을 둘 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“전자금융거래 이용약관”과 본 서비스 이용약관은 상호 보완하여 효력을 가집니다.</span>
        </p>
        <h5>제 5조 (회원가입 및 이용계약 체결)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>"회원”이 되고자 하는 자는 “회사”가 정한 가입 양식 및 절차에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청하고, “회사”가 이를 승낙함으로써 “회원가입” 및 “이용계약” 체결이 완료됩니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 제 1항과 같이 회원으로 가입할 것을 신청한 가입신청자 중 다음 각 호에 해당하는 경우 회원가입 신청을 거부하거나 이용계약을 해지할 수 있습니다.</span>
        </p>
        <ol class="terms-number-list">
          <li>등록 내용에 허위, 기재누락, 오기가 있는 경우</li>
          <li>“회원” 본인의 실명이 아니거나 타인의 명의를 도용한 경우</li>
          <li>회원가입 신청일 기준 만 14세 미만인 경우</li>
          <li>서비스 관련 설비에 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우</li>
          <li>이전에 회원자격을 상실한 적이 있는 경우 (다만, 재가입 조건이 충족되어 “회사”의 회원재가입 승낙을 얻은 경우에는 예외로 합니다.)</li>
          <li>기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우</li>
        </ol>
        <p class="terms-number">
          <span>③</span>
          <span>“회사”는 가입신청자의 실명을 확인하기 위해 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>"회원”은 회원정보에 변경사항이 있을 경우 “회사”가 정한 절차에 따라 수정이 가능하며, “회사”는 “회원”이 회원정보를 변경하고자 하는 경우 “회사”가 정한 본인 확인 절차 등을 거치도록 할 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>⑤</span>
          <span>“회원”은 “회사”에 등록한 회원정보에 변경사항이 있는 경우, 회원정보 수정 등의 방법으로 “회사”에 그 내용을 알려야 합니다. 변경사항을 회사에 알리지 않아 발생한 불이익은 “회원”이 부담합니다.</span>
        </p>
        <h5>제 6조 (회원 탈퇴 및 자격 상실)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회원”은 ‘회사”가 정한 절차에 따라 언제든지 탈퇴를 신청할 수 있으며, 이 경우 “회사”는 “회원”의 신청을 수락합니다. 단, “회원”이 탈퇴하기 전에 확인 또는 이행하여야 할 사항이 있다면 “회사”는 “회원”에게 이를 고지하고 그 확인 또는 이행을 요구할 수 있습니다. (단, 이용계약은 “회원”의 탈퇴 의사가 “회사”에 도달할 때 또는 “회원”이 확인 또는 이행하여야 할 사항이 있는 경우 그 확인 또는 이행의 의무가 완료된 때에 종료됩니다.)</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회원”이 다음 각 호의 어느 하나에 해당하는 경우, “회사”는 회원자격을 제한하거나 정지시킬 수 있습니다.</span>
        </p>
        <ol class="terms-number-list">
          <li>가입 신청시에 허위 내용을 등록한 경우</li>
          <li>서비스를 이용하여 구입한 “재화 등”의 대금이나 기타 “회사”의 “서비스” 이용과 관련하여 “회원”이 부담하는 채무를 기일에 지급하지 않는 경우</li>
          <li>다른 사람의 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
          <li>제 12 조 (회원의 금지 의무)를 위반하는 경우</li>
        </ol>
        <p class="terms-number">
          <span>③</span>
          <span>“회사”가 제2항에 따라 “회원”의 자격을 제한 또는 정지한 후, 회사는 정해진 기한 내에 그 시정을 요구할 수 있으며, “회원”이 15일 이내에 이를 시정하지 않거나 동일한 행위가 2회 이상 반복되는 경우 “회사”는 회원자격을 상실시킬 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회사”가 회원자격을 상실시키는 경우, “회사”는 30일의 기한을 정하여 해당 “회원”에게 소명을 요구하고, “회원”이 위 기한 내에 합리적으로 소명하지 못한다면 해당 “회원”의 회원등록을 말소하고 이용계약을 해지할 수 있습니다. 단, “회원”이 이 약관을 중대하게 위반한 경우 “회사”는 그 즉시 해당 ”회원”의 자격을 상실시킬 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>⑤</span>
          <span>제1항에 의거 본인의 의지로 탈퇴한 “회원”의 경우 탈퇴일로부터 7일 이상이 경과한 시점에 “회사”의 재가입 승낙을 얻어 “회원”에 다시 가입할 수 있습니다. (단, 회사가 제공하는 서비스의 형태에 따라 재가입이 불가능할 수도 있으며, 재가입시 새로운 "아이디"를 사용하는 것을 원칙으로 합니다.)</span>
        </p>
        <p class="terms-number">
          <span>⑥</span>
          <span>이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 회원이 책임을 부담하여야 하고, 회사는 관련 법령에 규정이 없는 한 책임을 지지 않습니다.</span>
        </p>
        <h5>제 7조 (회원에 대한 통지)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”가 “회원”에게 통지를 하는 경우, 이 약관에서 달리 정하지 않는 한 “회원”이 지정한 전화 및 전자우편 주소, 휴대전화 문자메시지, “회원”의 연락처와 연동된 SNS서비스, 어플리케이션 알림(Push) 등으로 할 수 있습니다. 이 경우 “회원”의 통신환경 또는 통신요금구조 등에 따라 “회원”이 데이터요금 등을 부담할 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 불특정다수 “회원”에 대한 통지의 경우 1주일 이상 “서비스” 게시판에 게시함으로써 제1항의 개별 통지에 갈음할 수 있습니다. 다만, “회원” 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</span>
        </p>
        <h5>제 8조 (회원의 아이디 및 비밀번호에 대한 의무)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회원”의 아이디와 비밀번호에 관한 관리책임은 “회원”에게 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회원”은 자신의 아이디 및 비밀번호를 제 3자에게 이용하게 해서는 안됩니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회원”이 본인의 계정정보를 소홀히 관리하거나 제 3자에게 이용을 승낙함으로써 발생하는 손해에 대해서는 “회원”에게 책임이 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회원”이 자신의 아이디 및 비밀번호를 도난 당하거나 제 3자가 사용하고 있음을 인지한 경우에는 바로 “회사”에 통보하고 “회사”의 안내가 있는 경우에는 그에 따라야 합니다.</span>
        </p>
        <h5>제 9조 (서비스의 제공, 변경 및 중단)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 다음과 같은 업무를 수행합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 “회원”에 대해 내부 정책에 따라 등급별로 구분하여 서비스 이용에 제한 및 차등을 둘 수 있습니다.</span>
        </p>
        <ol class="terms-number-list">
          <li>선불전자지급수단의 발행, 충전, 관리</li>
          <li>“회원”이 등록한 결제수단으로 사용처에서의 결제 중계</li>
          <li>기타 “회사”가 정하는 업무</li>
        </ol>
        <p class="terms-number">
          <span>③</span>
          <span>“회사”는 “재화 또는 서비스”의 제공과 관련하여 “회사” 정책의 변경 등 운영상 또는 기술상의 필요에 따라, 제공하고 있는 “재화 또는 서비스”의 전부 또는 일부를 변경하거나 중단할 수 있으며, 그 사유를 “회원”에게 통지 가능한 수단으로 즉시 통지합니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>"회사”는 제3항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 “회원”이 입은 손해에 대하여 배상합니다. 단, 회사가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</span>
        </p>
        <p class="terms-number">
          <span>⑤</span>
          <span>“회사”는 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 “서비스”의 제공을 일시적으로 중단할 수 있습니다. 단, 불가피하게 긴급한 조치를 필요로 하는 경우 사후에 통지할 수 있습니다.</span>
        </p>
        <h5>제 10조 (서비스의 이용 제한)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 아래 각 호에 해당하는 사유가 발생한 경우 “이용자”의 서비스 이용을 제한하거나 중지시킬 수 있습니다. 이용이 정지된 회원은 해당 기간 동안 카카오페이 서비스를 이용할 수 없습니다. 회사는 이용제한의 구체적인 기준 등에 대해 운영정책을 정할 수 있습니다.</span>
        </p>
        <ol class="terms-number-list">
          <li>“서비스”의 운영을 고의, 과실로 방해하는 경우</li>
          <li>“회원”이 본 약관에 규정된 의무를 위반한 경우</li>
          <li>기타 본 약관에서 규정한 사항이나 별도 페이지에 규정한 이용 규칙을 위반한 경우</li>
          <li>“회원”의 “서비스” 이용에 있어 외부로부터 보호가 필요하다고 판단될 경우</li>
          <li>기타 중대한 사유로 인하여 “회사”가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</li>
        </ol>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 전항 규정에 따라 서비스의 이용을 제한하거나 중지한 경우 그 사유 및 제한기간 등을 “이용자”에게 사전에 알려야 합니다.</span>
        </p>
        <h5>제 11조 (“회사”의 의무)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화 또는 서비스를 제공하는데 최선을 다하여야 합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 “회원”이 안전하게 인터넷 서비스를 이용할 수 있도록 “회원”의 개인정보 (신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회사”가 재화 등에 대하여 [표시 광고의 공정화에 관한 법률 ‘제 3조 소정의 부당한 표시광고 행위’]를 함으로써 “회원”이 손해를 입은 때에는 이를 배상할 책임을 집니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회사”는 서비스 이용과 관련하여 “회원”이 제기한 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.</span>
        </p>
        <p class="terms-number">
          <span>⑤</span>
          <span>“회사”는 “회원”이 동의하지 않는 경우, 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</span>
        </p>
        <h5>제 12조 (“회원”의 금지의무)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회원”은 다음 행위를 하여서는 안됩니다.</span>
        </p>
        <ol class="terms-number-list">
          <li>회원가입 신청 또는 회원정보 변경 시 허위 내용을 등록하는 행위</li>
          <li>타인의 명의, 계좌정보 등을 도용하여 “서비스”를 이용하는 행위</li>
          <li>“회사”가 게시한 정보를 변경하는 행위</li>
          <li>“회사”가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등을 송신 또는 게시하는 행위</li>
          <li>“회사”와 기타 제 3자의 저작권 등 지적재산권을 침해하는 행위</li>
          <li>“회사”와 기타 제 3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
          <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 “서비스”에 공개 또는 게시하는 행위</li>
          <li>고의로 제 3자 및 타인에게 “아이디” 또는 “비밀번호”를 공유하여 “회사”의 영리 목적에 침해를 가하는 행위</li>
          <li>자금의 융통 또는 이의 중개, 알선을 위한 목적으로 “서비스”를 이용하는 행위</li>
          <li>영리목적으로 “서비스”를 이용하는 행위</li>
          <li>기타 위법, 탈법 또는 부정한 목적으로 “서비스”를 이용하는 행위</li>
        </ol>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 “회원”이 전항의 사항을 위반하고 있다고 의심할 만한 합리적인 근거가 있는 경우 “회원”에게 해당 근거를 제시하고 소명을 할 수 있는 기회를 제공합니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회원”이 본 조의 금지의무를 위반한 경우, “회사”는 “회원”이 요청하는 서비스를 거부하거나 서비스의 중지 또는 회원자격 상실 등의 조치를 취할 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회사”는 필요한 경우 “회원”의 금지의무 위반행위 사실을 관련 정부기관 또는 사법기관에 통지할 수 있습니다.</span>
        </p>
        <h5>제 13조 (양도금지)</h5>
        <p>“회원” 및 “이용자”가 서비스를 이용하기 위한 접근매체나 정보는 그 사용을 제3자에게 대여하거나 위임할 수 없으며, 서비스를 제공받을 권리 또한 대여나 양도, 증여 또는 질권의 목적으로 제공하거나 사용할 수 없습니다.</p>
        <h5>제 14조 (개인정보보호)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 “회원”의 개인정보 수집 시 “서비스” 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 “회원”의 회원가입 절차를 통하여 구매계약 이행에 필요한 개인정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집할 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회사” “회원”의 개인정보를 보호하기 위하여 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 『개인정보보호법』 등 관련 법령에서 정하는 바를 준수합니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회사”는 “회원”의 개인정보보호를 위하여 노력하고, 개인정보보호 및 사용에 대해서는 “회사”의 개인정보취급방침이 적용됩니다.</span>
        </p>
        <h5>제 15조 (“연결회사”와 “피연결회사” 간의 관계)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“상위 회사”와 “하위 회사”가 하이퍼 링크(예 : 하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 “연결 회사”라고 하고 후자를 “피연결회사”라고 합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“연결회사”는 「“피연결회사”가 독자적으로 제공하는 재화 등에 의하여 “회원”과 행하는 거래에 대해서 보증책임을 지지 않는다」는 뜻을 “연결회사”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.</span>
        </p>
        <h5>제 16조 (저작권의 귀속 및 이용제한)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “회사”에 귀속합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회원”은 “서비스”를 이용함으로써 얻은 정보 중 “회사”에게 저작권 및 기타 지적재산권이 귀속된 정보를 “회사”의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회원”이 전항을 위반하여 “회사”에 손해를 끼칠 경우 이에 대한 배상 책임을 집니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회원”이 “회사”에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</span>
        </p>
        <p class="terms-number">
          <span>⑤</span>
          <span>“회원”은 “회사”에 대해 “회원”의 게시물을 무상으로 사용 (복제, 배포, 전시, 재사용 등)할 권리를 부여합니다. "회원“이 “회사”에 게시한 게시물은 검색결과, 상품페이지 등 “회사” 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 게시물의 동일성을 침해하지 않는 범위에서 일부 수정, 복제, 편집되어 게시될 수 있습니다.</span>
        </p>
        <p class="terms-number">
          <span>⑥</span>
          <span>“회원”이 “회사”에 게시한 게시물이 제 3자의 저작권을 침해할 경우 “회사”는 그에 대한 책임을 지지 않습니다.</span>
        </p>
        <h5>제 17조 (분쟁해결)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 “회원”이 제기하는 정당한 의견이나 불만을 반영하고, 그 피해를 보상처리하기 위하여 피해보상 처리기구를 설치·운영합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 “회원”으로부터 제출되는 불만사항 및 의견을 신속하게 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 “회원”에게 그 사유와 처리일정을 즉시 통보합니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회사”와 “회원”간에 발생한 분쟁과 관련하여 “회원”의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</span>
        </p>
        <h5>제 18조 (회사의 면책)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>“회사”는 천재지변, 화재, 홍수, 사고 또는 정부기관의 조치 기타 사회통념상 부득이한 경우 등 합리적 통제를 위한 최선의 노력에도 불구하고 이 약관 상의 의무를 이행할 수 없는 사유가 발생한 경우, “서비스”의 제공 기타 이 약관에 따른 책임을 지지 않습니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>“회사”는 기간통신사업자 등 전기통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 “회원”에게 손해에 대하여 책임이 면제됩니다.</span>
        </p>
        <p class="terms-number">
          <span>③</span>
          <span>“회사”는 “회원”의 귀책사유로 인한 “서비스”의 이용 장애에 대하여 책임을 지지 않습니다.</span>
        </p>
        <p class="terms-number">
          <span>④</span>
          <span>“회사”는 사전에 “회원”에게 공지한 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대하여 책임이 면제됩니다.</span>
        </p>
        <p class="terms-number">
          <span>⑤</span>
          <span>“회사”는 “제휴사”와 “회원” 간의 “재화 등”의 거래를 중개하는 서비스를 제공할 뿐, “제휴사” 또는 “회원”이 게재한 정보, 자료, 사실의 신뢰도 및 정확성에 대하여 책임을 지지 않습니다.</span>
        </p>
        <p class="terms-number">
          <span>⑥</span>
          <span>“재화 등”에 대한 정보 및 배송, 품질의 보증, 하자 등에 대한 모든 책임은 “제휴사”에게 있고, “회사”는 이에 대하여 별도의 책임을 지지 않습니다.</span>
        </p>
        <p class="terms-number">
          <span>⑦</span>
          <span>“회사”는 “회원” 또는 “제휴사”가 “서비스”를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.</span>
        </p>
        <p class="terms-number">
          <span>⑧</span>
          <span>“회사”는 “회원”과 또 다른 “회원” 간, “회원”과 제 3자 간에 “서비스”를 매개로 한 거래 또는 분쟁에 대하여 책임이 면제됩니다.</span>
        </p>
        <h5>제 19조 (재판권 및 준거법)</h5>
        <p class="terms-number">
          <span>①</span>
          <span>이 약관과 관련된 사항이나 “회사”와 “회원” 및 ”이용자” 간에 발생한 분쟁은 대한민국 법률을 적용합니다.</span>
        </p>
        <p class="terms-number">
          <span>②</span>
          <span>이 약관에 관하여 “회사”와 “회원” 및 ”이용자” 간에 발생한 소송은 민사소송법상의 관할법원에 따릅니다.</span>
        </p>
        <h4>부칙</h4>
        <p class="terms-last">본 약관은 2019년 10월 15일부터 적용됩니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsMarketing",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
  }
}
</script>

<style scoped>
.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 51;
}
</style>