<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>FAQ / 문의</p>
    </header>
    <div class="mypage-content bg-white">
      <div>
        <div class="mypage-box" @click="$router.push({ name: 'MypageQuestionFaq' })">
          <span>자주묻는 질문</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
        <div class="mypage-box" @click="$router.push({ name: 'MypageQuestionInquiry' })">
          <span>1:1 문의</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageQuestion",
}
</script>

<style scoped>

</style>