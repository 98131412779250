<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-login" v-show="isGuest" @click="$router.push({ name: 'Login' })">
        <img src="/assets/image/jangboda_shop_icon_login.svg" />
      </button>
      <router-link :to="{ name: 'Market' }"><p class="address">{{ address.road }}</p>
        <button type="button" class="btn btn-down"><i class="icon-arrow-down"></i></button>
      </router-link>
      <button type="button" class="btn btn-alram" @click="goAlarm">
        <img v-bind:src="alarm ? '/assets/image/btn_alram_check.svg' : '/assets/image/btn_alram.svg'" />
      </button>
    </header>
    <div class="dashboard-wrap">
      <div class="auth-form h-unset">
        <div class="input-group">
          <div class="fl">
            <div class="input-btn-group position">
              <img src="/assets/image/icon_marker.svg" class="mr-5" /><strong>{{ market.name }}</strong>
            </div>
            <p>예상배달시간 : 출발시간 기준 {{ Math.ceil((market.deliveryTimeH*60+market.deliveryTimeM)/60) }}시간 이내</p>
          </div>
          <div class="fr">
            <router-link :to="{ name: 'MarketStep2', params: { address: this.address } }" class="btn btn-outline-pink br-10">다른 시장찾기</router-link>
          </div>
        </div>
      </div>
      <div class="map-wrap mt-3">
        <div id="map" v-bind:class="{ open: this.mapShow === true, close: this.mapShow === false }">
          <button class="btn btn-map-close" @click="toggleMap()"></button>
          <div class="my-location" @click="myLocation()">
            <img src="/assets/image/icon_my_location.svg" />
          </div>
        </div>
      </div>
      <div class="banner-wrap">
        <button class="btn btn-map-open" @click="toggleMap()"></button>
        <div id="homeSwiperContainer" class="swiper-container">
          <div class="swiper-wrapper">
            <div v-for="(banner, bannerIdx) in event" v-bind:key="bannerIdx" class="swiper-slide">
              <router-link :to="{ name: 'MypageEventInfo', params: { promotionIdx: banner.promotionIdx } }"><img v-bind:src="getImage(banner.imgPath, null, 1000, null)"  @error="getOriginImage($event, banner.imgPath)"/></router-link>
            </div>
          </div>
          <div id="homeSwiperPagination" class="swiper-pagination"></div>
        </div>
      </div>
      <div class="tab-wrap">
        <div class="tab-menu">
          <div class="tab-product" v-bind:class="{ active: tabPosition===0 }" @click="changeTab(0)">상품</div>
          <div class="tab-shop" v-bind:class="{ active: tabPosition===1 }" @click="changeTab(1)">매장</div>
          <div class="tab-recent-shop" v-bind:class="{ active: tabPosition===2 }" @click="changeTab(2)">최근이용매장</div>
        </div>
        <dashboard-tabs-product v-if="tabPosition===0"></dashboard-tabs-product>
        <dashboard-tabs-shop v-if="tabPosition===1"></dashboard-tabs-shop>
        <dashboard-tabs-used v-if="tabPosition===2"></dashboard-tabs-used>
      </div>
      <div class="cart-wrap">
        <div class="company-info">
          <p>
            <span class="company-info-icon">
              <img src="/assets/image/icon_caution.svg" alt="통신판매중개자 안내" />
            </span>
            <span class="company-info-ment">
              ㈜모스트피엔에스는 통신판매중개자로서 <br />‘마켓나우’ 서비스의 거래당사자가 아닙니다.
            </span>
          </p>
          <p>
            따라서 ㈜모스트피엔에스는 상품정보 및 거래에 대하여 책임을 지지 않습니다. 상품 및 거래에 관하여 정확한 정보는 해당 판매자에게 직접 확인하여 주시기 바랍니다.
          </p>
        </div>
      </div>
      <dashboard-utils-cart></dashboard-utils-cart>
    </div>
    <dashboard-utils-menu menu="0"></dashboard-utils-menu>
  </div>
</template>

<script>
import DashboardTabsProduct from "./tabs/product"
import DashboardTabsShop from "./tabs/shop"
import DashboardTabsUsed from "./tabs/used_shop"
import DashboardUtilsCart from "./utils/cart";
import DashboardUtilsMenu from "./utils/menu"

export default {
  name: "Dashboard",
  components: {
    DashboardUtilsCart,
    DashboardTabsProduct,
    DashboardTabsShop,
    DashboardTabsUsed,
    DashboardUtilsMenu,
  },
  data() {
    return {
      isGuest: localStorage.getItem('guest') === 'true',
      map: null,
      mapShow: true,
      delay: 300,
      timer: null,
      geocoder: null,
      address: {
        road: '',
        parcel: '',
      },
      market: {
        name: '',
        deliveryTimeH: 0,
        deliveryTimeM: 0,
        areas: '',
        operationDay: [],
        orderTimeS_H: 0,
        orderTimeS_M: 0,
        orderTimeE_H: 0,
        orderTimeE_M: 0,
      },
      lat: 35.8448187691722,
      lng: 128.74889786895,
      mapData: [],
      mapMarker: [],
      tabPosition: 0,
      alarm: false,
      event: [],
    }
  },
  mounted() {
    let tab = sessionStorage.getItem('DashboardTab')
    if (tab) {
      this.changeTab(parseInt(tab))
    }
    if(localStorage.getItem('guest') !== 'true') {
      this.getAlarm()
    }

    this.getAddress((result) => {
      if(result.success) {
        this.address = result.data

        if(localStorage.getItem('guest') !== 'true') {
          if (!this.address.hp) {
            this.notify('error', '휴대폰 번호 인증이 필요합니다.')
            this.$router.push({name: 'MypageSetup'})

            return
          }

          if (!this.address.road) {
            this.notify('error', '내 주소 설정이 필요합니다.')
            this.$router.push({name: 'MypageSetup'})

            return
          }
        }

        this.getMarket((result2) => {
          if(!result2.success) {
            if(localStorage.getItem('guest') === 'true') {
              localStorage.removeItem('autoLogin')
              localStorage.removeItem('guest')

              /*기존 게스트 계정 삭제 api추가*/
              this.$delete(this.$USER_GUEST, 'setup_logout', () => {
                console.log('guest deleted')

                sessionStorage.removeItem('token')
                sessionStorage.removeItem('refresh_token')
                sessionStorage.removeItem('address')
                sessionStorage.removeItem('market')
                sessionStorage.removeItem('DashboardTab')
                sessionStorage.removeItem('DashboardShopInfoTab')

                this.$router.replace({ name: 'Intro' })
              }, (result) => {
                console.log('guest delet failed', result)

                sessionStorage.removeItem('token')
                sessionStorage.removeItem('refresh_token')
                sessionStorage.removeItem('address')
                sessionStorage.removeItem('market')
                sessionStorage.removeItem('DashboardTab')
                sessionStorage.removeItem('DashboardShopInfoTab')

                this.$router.replace({ name: 'Intro' })
              })
            } else {
              this.notify('error', '내 시장 설정이 필요합니다.')
              if(localStorage.getItem('guest') !== 'true') {
                this.$router.push({ name: 'Mypage', params: { address: this.address } })
              } else {
                this.$router.push({name: 'Market'})
              }
            }
            return
          }

          this.market = result2.data

          this.lat = this.market.lat
          this.lng = this.market.lng
          this.panToMap(this.lat, this.lng)
        })
      } else {
        this.httpError(result.data)
      }
    })
    // this.getEvent()

    //지도 노출 초기 기본값은 안보이도록
    // if (localStorage.getItem('mapShow') === null) {
      localStorage.setItem('mapShow', '0')
    // }

    if(window.kakao) {
      this.initMap()

      if(localStorage.getItem('mapShow') === '0') {
        this.toggleMap(false)
      }
    } else {
      this.toggleMap(false)
      window.$(".btn-map-open").hide()
    }
  },
  methods: {
    goAlarm() {
      if(localStorage.getItem('guest') !== 'true') {
        this.$router.push({ name: 'DashboardAlarm' })
      } else {
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {
        })
      }
    },

    myLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude
        this.lng = position.coords.longitude
        this.panToMap(this.lat, this.lng)
      }, () => {
        this.panToMap(this.lat, this.lng)
      })
    },
    panToMap(_lat, _lng) {
      if (this.map) {
        this.map.panTo(new window.kakao.maps.LatLng(_lat, _lng))
        this.getMapData(_lat, _lng)
      } else {
        setTimeout(() => {
          this.panToMap(_lat, _lng)
        }, 1000)
      }
    },
    getEvent() {
      this.$get(this.$USER_EVENT_BANNER, 'dashboard_getEvent', (result) => {
        this.event = result.data
        setTimeout(() => {
          this.initSwiper()
        }, 500)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getMapData(lat, lng) {
      this.$get(this.$USER_AROUND_MAP+"?lat="+lat+"&lng="+lng, 'dashboard_getMapData', (result) => {
        this.mapData = result.data
        this.setMapData()
      }, (result) => {
        this.httpError(result.data)
      })
    },
    setMapData() {
      for(let i = 0; i < this.mapMarker.length; i++) {
        window.kakao.maps.event.removeListener(this.mapMarker[i], 'click', this.getMarkerInfo);
        this.mapMarker[i].setMap(null)
      }
      this.mapMarker = []
      for(let i = 0; i < this.mapData.length; i++) {
        let cat = "/assets/image/icon_marker_here.svg"
        if(this.mapData[i].category) {
          cat = "/assets/image/icon_marker_"+this.getCategoryName(this.mapData[i].category).replace('-', '_')+".svg"
        }

        let pos = new window.kakao.maps.LatLng(this.mapData[i].lat, this.mapData[i].lan)

        let marker = new window.kakao.maps.Marker({
          map: this.map,
          position: pos,
          title : this.mapData[i].shopIdx,
          image : new window.kakao.maps.MarkerImage(cat, new window.kakao.maps.Size(32, 40)),
          clickable: true,
        })

        window.kakao.maps.event.addListener(marker, 'click', () => {
          this.$router.push({ name: 'DashboardShopInfo', params: { shopIdx: marker.getTitle() } })
        });
        marker.setDraggable(false)

        this.mapMarker.push(marker)
      }
    },
    getAlarm() {
      this.$get(this.$USER_NOTIFICATION_STATE, 'dashboard_getAlarm', (result) => {
        this.alarm = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    changeTab(idx) {
      this.tabPosition = idx
      if(localStorage.getItem('guest') === 'true') {
        sessionStorage.setItem('DashboardTab', '0')
      } else {
        sessionStorage.setItem('DashboardTab', idx.toString())
      }
    },
    setLocation(position) {
      this.lat = position.coords.latitude
      this.lng = position.coords.longitude

      this.initMap()

      if(localStorage.getItem('mapShow') === '0') {
        this.toggleMap(false)
      }
    },
    setPosition() {
      let position = this.map.getCenter()
      this.getMapData(position.getLat(), position.getLng())
      this.map.panTo(new window.kakao.maps.LatLng(position.getLat(), position.getLng()))
    },
    initMap() {
      this.geocoder = new window.kakao.maps.services.Geocoder()

      let mapContainer = document.getElementById('map')
      let mapOption = {
        center: new window.kakao.maps.LatLng(this.lat, this.lng),
        level: 4
      }

      window.$('#map').css('height', 280)

      this.map = new window.kakao.maps.Map(mapContainer, mapOption)

      window.kakao.maps.event.addListener(this.map, 'dragend', () => {
        this.setPosition()
      })
    },
    toggleMap(delay=true) {
      if(delay) {
        window.$(".dashboard-wrap #map").css('transition', 'all .6s');
      } else {
        window.$(".dashboard-wrap #map").css('transition', 'none');
      }
      //맵 토글
      if(this.mapShow) {
        window.$(".btn-search-again, .my-location").hide();
        if(delay) {
          window.$(".btn-map-open").delay(600).show(0);
          window.$(".btn-map-close").hide(0);
        } else {
          window.$(".btn-map-open").show();
          window.$(".btn-map-close").hide();
        }
      } else {
        window.$(".btn-search-again, .my-location").show();
        window.$(".btn-map-open").hide();
        window.$(".btn-map-close").show();
      }

      this.mapShow = !this.mapShow

      localStorage.setItem('mapShow', this.mapShow?'1':'0')
    },
    initSwiper() {
      // swiper
      new window.Swiper('#homeSwiperContainer', {
        slidesPerView: "auto",
        speed: 400,
        autoplay: {
          delay : 5000,
          disableOnInteraction : false
        },
        pagination: {
          el: "#homeSwiperPagination",
          type: "fraction",
          renderFraction: function(currentClass, totalClass) {
            return '<div class="fr swiper-custom-pagination br-10 mr-10"><span class="' + currentClass + '"></span><span> / </span><span class="' + totalClass + '"></span></div>'
          }
        },
      })
    }
  }
}
</script>

<style scoped>
.swiper-slide {
  height: 150px;
}
</style>
