<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'Mypage' })"><i class="icon-back"></i></button>
      <p>내 시장</p>
    </header>
    <div class="mypage-content">
      <div class="market-wrap">
        <div class="markget-card">
          <strong>{{ market.name }}</strong>
          <hr />
          <dl>
            <dt>배달지역</dt>
            <dd>{{ market.areas }}</dd>
            <dt>운영일자</dt>
            <dd>
              {{ textOperationDay(market.operationDay) }}
            </dd>
            <dt>운영시간</dt>
            <dd>
              {{ market.orderTimeS_H }}:{{ market.orderTimeS_M }} ~ {{ market.orderTimeE_H }}:{{ market.orderTimeE_M }}
            </dd>
            <dt>배달시간</dt>
            <dd>{{ market.deliveryTimeH }}시간 {{ market.deliveryTimeM }}분 이내</dd>
          </dl>
        </div>
        <button type="button" class="btn btn-pink" @click="$router.push({ name: 'MarketStep2', params: { address: address } })">다른시장 선택</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageMyMarket",
  data() {
    return {
      address: {
        road: '',
        parcel: '',
      },
      market: {
        name: '',
        deliveryTimeH: 0,
        deliveryTimeM: 0,
        areas: '',
        operationDay: [],
        orderTimeS_H: 0,
        orderTimeS_M: 0,
        orderTimeE_H: 0,
        orderTimeE_M: 0,
      },
    }
  },
  mounted() {
    this.getAddress((result) => {
      if(result.success) {
        this.address = result.data
      } else {
        this.httpError(result.data)
      }
    })

    this.getMarket((result) => {
      if(result.success) {
        this.market = result.data
      } else {
        this.httpError(result.data)
      }
    })
  },
  methods: {
    textOperationDay(operationDay) {
      let week = ['일', '월', '화', '수', '목', '금', '토']

      let result = '';

      for(let i=0; i<operationDay.length; i++) {
        result += week[operationDay[i]] + ', '
      }

      result = result.substr(0, result.length-2)

      if(result === '일, 월, 화, 수, 목, 금, 토') {
        result = "매일";
      }

      return result
    },
  }
}
</script>

<style scoped>

</style>