<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>내 동네 설정</p>
    </header>
    <div class="map-wrap">
      <div id="map"></div>
    </div>
    <div class="auth-form h-unset pt-0 mb-0 location-wrap bg-white">
      <div class="my-location" @click="myLocation()">
        <img src="/assets/image/icon_my_location.svg" />
      </div>
      <strong>{{ address.address_name }}</strong>
      <span>도로명</span> <small>{{ address.road_address?address.road_address.address_name:'도로명 주소가 없습니다' }}</small>
      <div class="input-group">
        <input type="text" class="input-default" placeholder="상세주소를 입력하세요. (건물명, 동/호수 등)" v-model="detail">
      </div>
      <button type="button" class="btn btn-pink w-100 br-20" @click="setAddress()">이 위치로 주소 설정</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketStep1",
  data() {
    return {
      map: null,
      delay: 300,
      timer: null,
      geocoder: null,
      address: {
        address_name: '',
        road_address: null,
      },
      detail: '',
      lat: 35.8448187691722,
      lng: 128.74889786895,
      marker: null,
    }
  },
  mounted() {
    if(typeof this.$route.params.address !== 'undefined') {
      this.address = this.$route.params.address
      this.lat = this.address.y
      this.lng = this.address.x
      this.initMap()
      this.setPosition()
    } else {
      navigator.geolocation.getCurrentPosition(this.setLocation, () => {
        this.initMap()
        this.setPosition()
      })
    }
  },
  methods: {
    myLocation() {
      this.map.panTo(new window.kakao.maps.LatLng(this.lat, this.lng))
    },
    setLocation(position) {
      this.lat = position.coords.latitude
      this.lng = position.coords.longitude

      this.initMap()
      this.setPosition()
    },
    setAddress() {
      if(this.detail !== '') {
        this.getMarket((result) => {
          let oldMarketIdx = result.data.idx

          let formData = new FormData()
          formData.append('BCode', this.address.address.b_code)
          formData.append('lat', this.address.y)
          formData.append('lng ', this.address.x)
          formData.append('parcel', this.address.address.address_name)
          formData.append('road', this.address.road_address.address_name)
          formData.append('subAddress', this.detail.trim())

          this.$put(this.$USER_UPDATEADDRESS, 'market_step1_setAddress', formData, () => {
            let return_address = { parcel: this.address.address.address_name, road: this.address.road_address.address_name }

            this.getAddress(null, true)
            sessionStorage.removeItem('market')
            window.unsubscribedTopic(`event_${oldMarketIdx}`)
            window.unsubscribedTopic(`service_${oldMarketIdx}`)

            this.$router.replace({ name: 'MarketStep2', params: { address: return_address } })
          }, (result) => {
            this.httpError(result.data)
          })

        })
      } else {
        this.notify('error', '상세주소를 입력하세요.')
      }

    },
    setPosition() {
      let position = this.map.getCenter()
      this.marker.setPosition(position)
      this.map.panTo(new window.kakao.maps.LatLng(position.getLat(), position.getLng()))

      this.geocoder.coord2Address(position.getLng(), position.getLat(), (result) => {
        console.log(result)
        if (result[0].road_address) {
          this.geocoder.addressSearch(result[0].road_address.address_name, (result) => {
            this.address = result[0]
            this.address.address_name = result[0].address.address_name
          })
        } else {
          this.geocoder.addressSearch(result[0].address.address_name, (result) => {
            this.address = result[0]
          })
        }

      })
    },
    initMap() {
      this.geocoder = new window.kakao.maps.services.Geocoder()

      let imageSrc = '/assets/image/icon_marker_here.svg',
          imageSize = new window.kakao.maps.Size(48, 60);

      let mapContainer = document.getElementById('map')
      let mapOption = {
        center: new window.kakao.maps.LatLng(this.lat, this.lng),
        level: 2
      }

      window.$('#map').css('height', window.$('.location-wrap').position().top-53)

      this.map = new window.kakao.maps.Map(mapContainer, mapOption);

      this.marker = new window.kakao.maps.Marker({
        position: new window.kakao.maps.LatLng(this.lat, this.lng),
        image: new window.kakao.maps.MarkerImage(imageSrc, imageSize)
      })

      this.marker.setMap(this.map)
      this.marker.setDraggable(false)

      // window.$(window).resize(() => {
      //   clearTimeout(this.timer);
      //   this.timer = setTimeout(() => {
      //     window.$('#map').css('height', window.$('.location-wrap').position().top-53)
      //     if(window.$('#map').height() > 500) {
      //       this.map.setLevel(2)
      //     } else {
      //       this.map.setLevel(1);
      //     }
      //     this.map.relayout();
      //     this.setPosition();
      //   }, this.delay);
      // })

      window.$('.my-location > img').click(() => {
        this.setPosition();
      })

      window.kakao.maps.event.addListener(this.map, 'dragend', () => {
        this.setPosition()
      })

      window.kakao.maps.event.addListener(this.map, 'drag', () => {
        let position = this.map.getCenter()
        this.marker.setPosition(position)
      })

      window.kakao.maps.event.addListener(this.map, 'zoom_changed', () => {
        this.setPosition();
      });
    }
  }
}
</script>

<style scoped>

</style>
