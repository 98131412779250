<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>시장 선택</p>
    </header>
    <div class="auth-form bg-gray h-unset pt-65">
      <div class="address-wrap b-none">
        <div class="b-line-card only-p-b  b-none pb-20">
          <strong>{{ address.parcel }}</strong>
          <span>도로명</span> {{ address.road }}
        </div>
      </div>
      <router-link :to="{ name: 'Market' }" replace class="btn btn-outline-pink w-100 br-20">주소 재설정</router-link>
    </div>
    <div class="market-wrap">
      <div class="page-header">
        <h3>시장 선택</h3>
        <sub>현재 이용가능한 시장입니다.</sub>
      </div>
      <div class="tip">
        <img src="/assets/image/icon_speech_bubble.svg" />
        시장명을 터치하여 선택해주세요.
      </div>
      <div v-for="(item,idx) in markets" v-bind:key="idx"  class="markget-card" @click="setMarket(item.idx)">
        <strong>{{ item.name }}</strong>
        <span>{{ item.distance }}km</span>
        <hr />
        <dl>
          <dt>배달지역</dt>
          <dd>{{ item.areas }}</dd>
          <dt>운영일자</dt>
          <dd>
            {{ textOperationDay(item.operationDay) }}
          </dd>
          <dt>운영시간</dt>
          <dd>
            {{ item.orderTimeS_H }}:{{ item.orderTimeS_M }} ~ {{ item.orderTimeE_H }}:{{ item.orderTimeE_M }}
          </dd>
          <dt>배달시간</dt>
          <dd>{{ Math.floor(item.estimatedDeliveryTime/60) }}시간 {{ item.estimatedDeliveryTime%60 }}분 이내</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketStep2",
  data() {
    return {
      address: {
        parcel: '',
        road: '',
      },
      markets: [],
    }
  },
  mounted() {
    if(typeof this.$route.params.address !== 'undefined') {
      this.address = this.$route.params.address
    } else {
      this.getAddress((result) => {
        if(result.success) {
          this.address = result.data
          if (!this.address.road) {
            this.notify('error', '내 동네 설정이 필요합니다.')
            this.$router.replace({name: 'Market'})
          }
        } else {
          this.httpError(result.data)
        }
      })
    }

    this.getMarketList()
  },
  methods: {
    setMarket(idx) {
      this.getMarket((oldResult) => {
        if (oldResult) {
          let oldMarketIdx = oldResult.data.idx
          if (oldMarketIdx) {
            window.unsubscribedTopic(`event_${oldMarketIdx}`)
            window.unsubscribedTopic(`service_${oldMarketIdx}`)
          }
        }

        let formData = new FormData()
        formData.append('marketIdx', idx)

        this.$put(this.$USER_UPDATEMARKET, 'market_step2_setMarket', formData, () => {
          this.getMarket(() => {
            this.setPushAlarm(() => {
              this.$router.back()
            })
          }, true)
        }, (result) => {
          this.httpError(result.data)
        })

      })

    },
    textOperationDay(operationDay) {
      let week = ['일', '월', '화', '수', '목', '금', '토']

      let result = '';

      for(let i=0; i<operationDay.length; i++) {
        result += week[operationDay[i]] + ', '
      }

      result = result.substr(0, result.length-2)

      if(result === '일, 월, 화, 수, 목, 금, 토') {
        result = "매일";
      }

      return result
    },
    getMarketList() {
      this.$get(this.$USER_MARKET, 'market_step2_getMarketList', (result) => {
        this.markets = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
