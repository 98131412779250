<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-arrow-left"></i></button>
      <!--TODO: 스크롤 내리면 hrader에 상품명 나타나면 됩니다. (상단 150px 정도) -->
      <p>{{ product.shopName }} - {{ product.productName }}</p>
      <button type="button" class="btn btn-shop" @click="$router.push({ name: 'DashboardShopInfo', params: { tab: 1, shopIdx: product.shopIdx } })">
        <img src="/assets/image/btn_shop.svg" alt="매장홈" />
      </button>
    </header>
    <div class="product-info-wrap">
      <div class="product-info">
        <p class="product-name">{{ product.productName }}</p>
      </div>
      <div id="productSwiperContainer" class="swiper-container">
        <!-- 사진 스와이프  - 상품 사진 최대 5장 / 사이즈 375*300 -->
        <div class="swiper-wrapper product-photo">
          <div v-for="(image, imageIdx) in product.imgPaths" v-bind:key="imageIdx" class="swiper-slide">
            <a role="button" @click="showImage()"><img v-bind:src="getImage(image, null, 800, null)" alt="상품 이미지" @error="getOriginImage($event, image)"/></a>
          </div>
          <div v-if="product.imgPaths.length === 0" class="swiper-slide">
            <img src="/assets/image/photo_preparing_product.svg" alt="이미지 준비중입니다.">
          </div>
        </div>
        <div id="productSwiperPagination" class="product-photo swiper-pagination"></div>
        <div class="product-photo">
          <p class="simple_info" v-html="product.productMemo"></p>
        </div>
      </div>
      <p class="product-info product-price">
        <span class="title">판매가격</span>
        <span class="default-price">{{ product.price|numberWithCommas }}원</span>
      </p>
      <div class="product-info" v-if="product.options.length > 0">
        <p class="sub-title">상품 옵션</p>
        <ul class="product-info-option">
          <li v-for="(option,optionIdx) in product.options" v-bind:key="optionIdx" class="radios">
            <input type="radio" v-bind:id="'product-option-item-' + optionIdx" name="product-option-item" v-model="selectedOption" v-bind:value="optionIdx">
            <label v-bind:for="'product-option-item-' + optionIdx">{{ option.name }}</label>
            <span class="option-price">+ {{ option.price|numberWithCommas }}원</span>
          </li>
        </ul>
      </div>
      <div class="product-info" v-if="product.addOptions.length > 0">
        <p class="sub-title">추가 옵션</p>
        <ul class="product-info-option">
          <li v-for="(addOption,addOptionIdx) in product.addOptions" v-bind:key="addOptionIdx" class="checks">
            <input type="checkbox" v-bind:id="'product-check-item-' + addOptionIdx" v-model="selectedAddOptions[addOptionIdx]">
            <label v-bind:for="'product-check-item-' + addOptionIdx">{{ addOption.name }}</label>
            <span class="option-price">+ {{ addOption.price|numberWithCommas }}원</span>
          </li>
        </ul>
      </div>
      <div class="product-info">
        <ul class="product-info-detail">
          <li>
            <span class="detail-title">상품명</span>
            <span class="detail-content">{{ product.productName }}</span>
          </li>
          <li>
            <span class="detail-title">판매단위</span>
            <span class="detail-content">{{ product.salesUnit }}</span>
          </li>
          <li>
            <span class="detail-title">원산지</span>
            <span class="detail-content">{{ product.country }}</span>
          </li>
          <li>
            <span class="detail-title">배송지역</span>
            <span class="detail-content" style="word-break:break-all;">{{ product.deliveryAreas }}</span>
          </li>
          <li>
            <span class="detail-title">매장문의</span>
            <span class="detail-content detail-phone" @click="callPhone(product.shopTel)">{{ product.shopTel }}</span>
          </li>
        </ul>
      </div>
      <div class="product-info">
        <p class="sub-title">{{ product.productName }}</p>
        <p class="product-description" v-html="product.description">
        </p>
      </div>
      <div class="product-info" v-if="product.faq.length > 0">
        <p class="sub-title">FAQ</p>
        <div v-for="(faq, faqIdx) in product.faq" v-bind:key="faqIdx" class="qna-set" style="padding-top: 0;">
          <p class="question" @click="toggleFaq($event)">
            <span class="faq-helper">Q. {{ faq.title }}</span>
            <i class="faq-helper" v-bind:class="faqIdx===0?'icon-arrow-up':'icon-arrow-down'"></i>
          </p>
          <p class="answer faq-helper" v-bind:style="faqIdx===0?'':'display: none'" v-html="faq.content">
          </p>
        </div>
      </div>
    </div>
    <div v-if="product.state" class="fnb-btn-group">
      <!--TODO:주문하기 버튼 탭시 주문하기 팝업(cart-add-popup) 하단에서 슬라이드 move in 해주시면 됩니다.-->
      <button class="btn btn-pink btn-order" @click="showPopup()">주문하기</button>
      <button class="btn btn-heart" @click="toggleHeart()"><img v-bind:src="product.wish?'/assets/image/btn_heart_on.svg':'/assets/image/btn_heart_off.svg'" alt="찜하기"></button>
    </div>
    <div v-else class="fnb-btn-group">
      <div class="w-100" style="height: 50px; color: #909090; background-color: #e0e0e0; text-align: center;line-height: 50px; font-size: 17px; font-weight: 500;">현재 주문이 불가능한 상품 입니다</div>
    </div>
    <div v-show="popup">
      <div class="bg-block-ui"></div>
      <div class="cart-add-popup cart-add-popup-m cart-product" style="overflow: unset;">
        <button type="button" class="btn btn-close" @click="hidePopup()"><i class="icon-close"></i></button>
        <div class="product-list">
          <div class="product-item b-none pb-0">
            <div class="product-item-thumbnail" v-if="product.imgPaths.length > 0">
              <img v-bind:src="getImage(product.imgPaths[0], 200, 200, 1)" alt="상품 이미지" @error="getOriginImage($event, product.imgPaths[0])">
            </div>
            <div class="product-item-content">
              <p class="product-item-name">{{ product.productName }}</p>
              <p class="product-item-option1">{{ product.options.length>0?product.options[selectedOption].name:'' }}</p>
              <p class="product-item-option2">{{ textAddOption() }}</p>
            </div>
          </div>
        </div>
        <div class="option-quantity-price">
          <div class="option-quantity-wrap">
            <div class="option-quantity">
              <button type="button" class="btn" @click="removeQuantity()" v-bind:disabled="quantity===1">
                <i class="icon-minus"></i>
              </button>
              <input type="text" v-bind:value="quantity" readonly="readonly">
              <button type="button" class="btn" @click="addQuantity()">
                <i class="icon-plus"></i>
              </button>
            </div>
            <p class="color-pink">{{ total|numberWithCommas }}원</p>
          </div>
          <div class="fnb-btn-group">
            <button class="btn btn-darkgray" @click="confirm()">장바구니 담기</button>
            <button class="btn btn-pink" @click="setCart('Payment')">결제하기</button>
          </div>
        </div>
      </div>
    </div>
    <photo ref="Photo"></photo>
  </div>
</template>

<script>
import Photo from "@/components/photo";

export default {
  name: "DashboardProductInfo",
  components: {
    Photo,
  },
  data() {
    return {
      productIdx: this.$route.params.productIdx,
      product: {
        addOptions: [],
        country: '',
        deliveryAreas: '',
        description: '',
        faq: [],
        imgPaths: [],
        marketIdx: 0,
        options: [],
        price: 0,
        productMemo: '',
        productName: '',
        salesUnit: '',
        shopIdx: 0,
        shopName: '',
        wish: false,
      },
      selectedOption: 0,
      selectedAddOptions: [],
      quantity: 1,
      total: 0,
      popup: false,
    }
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    showImage() {
      this.$refs.Photo.showPhoto(this.product.imgPaths);
    },
    confirm() {
      this.$root.$refs.Confirm.showDialog('장바구니로 이동하시겠습니까?', '예', '아니오', () => {
        this.setCart('PaymentCart')
      }, () => {
        this.setCart(null)
        this.$router.back()
      })
    },
    textAddOption() {
      let result = ''
      for(let i=0; i<this.product.addOptions.length; i++) {
        if(this.selectedAddOptions[i]) {
          result += this.product.addOptions[i].name + ', '
        }
      }
      return result.substr(0, result.length-2)
    },
    showPopup() {
      if(localStorage.getItem('guest') === 'true') {
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {

        })
      } else {
        this.popup = true
      }
    },
    hidePopup() {
      this.popup = false
    },
    getProduct() {
      this.$get(this.$USER_PRODUCT+'/'+this.productIdx, 'product_info_getProduct', (result) => {
        this.product = result.data

        setTimeout(() => {
          this.initSwiper()
        }, 500)

        this.getTotal()
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleFaq(event) {
      if(window.$(event.target).parent().children('.answer').is(":visible")) {
        window.$(event.target).children('i').removeClass('icon-arrow-up')
        window.$(event.target).children('i').addClass('icon-arrow-down')

        window.$(event.target).parent().children('.answer').slideUp()
      } else {
        window.$(event.target).children('i').removeClass('icon-arrow-down')
        window.$(event.target).children('i').addClass('icon-arrow-up')

        window.$(event.target).parent().children('.answer').slideDown()
      }
    },
    toggleHeart() {
      if(localStorage.getItem('guest') === 'true') {
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {

        })
      } else {
        let formData = new FormData()
        formData.append('type', '0')

        this.$put(this.$USER_WISH+'/'+this.productIdx, 'product_info_toggleHeart', formData, (result) => {
          this.product.wish = result.data.state
        }, (result) => {
          this.httpError(result.data)
        })
      }

    },
    setCart(redirect) {
      let formData = new FormData()

      if(this.product.options.length > 0) {
        formData.append('option', this.product.options[this.selectedOption].idx)
      }

      for(let i=0; i<this.product.addOptions.length; i++) {
        if(this.selectedAddOptions[i]) {
          formData.append('addOptions', this.product.addOptions[i].idx)
        }
      }

      formData.append('productIdx', this.productIdx)
      formData.append('quantity', this.quantity)
      formData.append('shopIdx', this.product.shopIdx)
      formData.append('shopName', this.product.shopName)

      this.$post(this.$USER_CART, 'product_info_setCart', formData, () => {
        if(redirect) {
          this.$router.push({name: redirect})
        }
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getTotal() {
      this.total = this.product.price

      if(this.product.options.length > 0) {
        this.total += this.product.options[this.selectedOption].price
      }

      for(let i=0; i<this.product.addOptions.length; i++) {
        if(this.selectedAddOptions[i]) {
          this.total += this.product.addOptions[i].price
        }
      }

      this.total = this.total * this.quantity
    },
    addQuantity() {
      this.quantity++
    },
    removeQuantity() {
      if(this.quantity > 1) {
        this.quantity--
      }
    },
    initSwiper() {
      new window.Swiper('#productSwiperContainer', {
        slidesPerView: "auto",
        speed: 400,
        autoplay: {
          delay : 5000,
          disableOnInteraction : false
        },
        pagination: {
          el: "#productSwiperPagination",
          type: "fraction",
          renderFraction: function (currentClass, totalClass) {
            return '<div class="fr swiper-custom-pagination br-10 mr-10"><span class="' + currentClass + '"></span>' +
                '<span> / </span>' +
                '<span class="' + totalClass + '"></span></div>';
          }
        },
      });
    },
    callPhone(_phone) {
      window.location.href = 'tel:' + _phone
    }
  },
  watch: {
    quantity() {
      this.getTotal()
    },
    selectedOption() {
      this.getTotal()
    },
    selectedAddOptions: {
      deep: true,
      handler() {
        this.getTotal()
      }
    },
  }
}
</script>

<style scoped>
.cart-product {
  bottom: 120px;
  z-index: 99;
  min-height: auto;
  top: auto;
}

.cart-product .product-item {
  margin-bottom: 0;
}

.faq-helper {
  pointer-events: none;
  word-break: break-all;
}
</style>
