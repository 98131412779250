import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Intro from "../components/intro"
import IntroLogin from "../components/intro_login"
import Login from "../components/login"
import Regist from "../components/regist/intro"
import RegistStep1 from "../components/regist/regist_step1"
import RegistStep2 from "../components/regist/regist_step2"
import RegistStep3 from "../components/regist/regist_step3"
import RegistStep4 from "../components/regist/regist_step4"
import FindId from "../components/find_id"
import FindIdResult from "../components/find_id_result"
import FindPassword from "../components/find_password"
import ResetPassword from "../components/reset_password"
import Dashboard from "../components/dashboard/dashboard"
import DashboardAlarm from "../components/dashboard/alarm"
import DashboardProduct from "../components/dashboard/product_list"
import DashboardProductInfo from "../components/dashboard/product_info"
import DashboardShop from "../components/dashboard/shop_list"
import DashboardShopInfo from "../components/dashboard/shop_info"
import Market from "../components/market/intro"
import MarketStep1 from "../components/market/market_step1"
import MarketStep2 from "../components/market/market_step2"
import Payment from "../components/payment/payment"
import PaymentCart from "../components/payment/cart"
import PaymentFail from "../components/payment/payment_fail"
import PaymentSuccess from "../components/payment/payment_success"
import PaymentReview from "../components/payment/review_write"
import Mypage from "../components/mypage/main"
import MypageSetup from "../components/mypage/setup"
import MypageAgreement from "../components/mypage/agreement"
import MypageEvent from "../components/mypage/event"
import MypageEventInfo from "../components/mypage/event_info"
import MypageMyMarket from "../components/mypage/my_market"
import MypageResetPassword from "../components/mypage/reset_password"
import MypageChangePhone from "../components/mypage/change_phone"
import MypageNotice from "../components/mypage/notice"
import MypageQuestion from "../components/mypage/question"
import MypageQuestionFaq from "../components/mypage/question/faq"
import MypageQuestionInquiry from "../components/mypage/question/inquiry"
import MypageQuestionInquiryWrite from "../components/mypage/question/inquiry_write"
import DashboardOrderList from "../components/dashboard/order_list"
import DashboardOrderInfo from "../components/dashboard/order_info"
import DashboardHeartList from "../components/dashboard/heart_list"

export default new Router({
  routes: [
      {
          path: '/',
          name: 'Intro',
          component: Intro,
      },
      {
          path: '/intro',
          name: 'IntroLogin',
          component: IntroLogin,
      },
      {
          path: '/login_1',
          name: 'Login',
          component: Login,
      },
      {
          path: '/regist',
          name: 'Regist',
          component: Regist,
          meta: {
              keepAlive: true
          },
      },
      {
          path: '/regist/step1',
          name: 'RegistStep1',
          component: RegistStep1,
          meta: {
              keepAlive: true
          },
      },
      {
          path: '/regist/step2',
          name: 'RegistStep2',
          component: RegistStep2,
          meta: {
              keepAlive: true
          },
      },
      {
          path: '/regist/step3',
          name: 'RegistStep3',
          component: RegistStep3,
          meta: {
              keepAlive: true
          },
      },
      {
          path: '/regist/step4',
          name: 'RegistStep4',
          component: RegistStep4,
      },
      {
          path: '/find/id',
          name: 'FindId',
          component: FindId,
      },
      {
          path: '/find/id/result',
          name: 'FindIdResult',
          component: FindIdResult
      },
      {
          path: '/find/password',
          name: 'FindPassword',
          component: FindPassword,
      },
      {
          path: '/find/password/reset',
          name: 'ResetPassword',
          component: ResetPassword,
      },
      {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
      },
      {
          path: '/dashboard/alarm',
          name: 'DashboardAlarm',
          component: DashboardAlarm,
      },
      {
          path: '/dashboard/product/list',
          name: 'DashboardProduct',
          component: DashboardProduct,
      },
      {
          path: '/dashboard/product/info/:productIdx',
          name: 'DashboardProductInfo',
          component: DashboardProductInfo,
      },
      {
          path: '/dashboard/shop/list',
          name: 'DashboardShop',
          component: DashboardShop,
      },
      {
          path: '/dashboard/shop/info/:shopIdx',
          name: 'DashboardShopInfo',
          component: DashboardShopInfo,
      },
      {
          path: '/market',
          name: 'Market',
          component: Market,
      },
      {
          path: '/market/step1',
          name: 'MarketStep1',
          component: MarketStep1,
      },
      {
          path: '/market/step2',
          name: 'MarketStep2',
          component: MarketStep2,
      },
      {
          path: '/payment',
          name: 'Payment',
          component: Payment,
      },
      {
          path: '/payment/cart',
          name: 'PaymentCart',
          component: PaymentCart,
      },
      {
          path: '/payment/fail',
          name: 'PaymentFail',
          component: PaymentFail,
      },
      {
          path: '/payment/success',
          name: 'PaymentSuccess',
          component: PaymentSuccess,
      },
      {
          path: '/payment/review',
          name: 'PaymentReview',
          component: PaymentReview,
      },
      {
          path: '/mypage',
          name: 'Mypage',
          component: Mypage,
      },
      {
          path: '/mypage/setup',
          name: 'MypageSetup',
          component: MypageSetup,
      },
      {
          path: '/mypage/agreement',
          name: 'MypageAgreement',
          component: MypageAgreement,
      },
      {
          path: '/mypage/event',
          name: 'MypageEvent',
          component: MypageEvent,
      },
      {
          path: '/mypage/event/:promotionIdx',
          name: 'MypageEventInfo',
          component: MypageEventInfo,
      },
      {
          path: '/mypage/market',
          name: 'MypageMyMarket',
          component: MypageMyMarket,
      },
      {
          path: '/mypage/password',
          name: 'MypageResetPassword',
          component: MypageResetPassword,
      },
      {
          path: '/mypage/phone',
          name: 'MypageChangePhone',
          component: MypageChangePhone,
      },
      {
          path: '/mypage/notice',
          name: 'MypageNotice',
          component: MypageNotice,
      },
      {
          path: '/mypage/question',
          name: 'MypageQuestion',
          component: MypageQuestion,
      },
      {
          path: '/mypage/question/faq',
          name: 'MypageQuestionFaq',
          component: MypageQuestionFaq,
      },
      {
          path: '/mypage/question/inquiry',
          name: 'MypageQuestionInquiry',
          component: MypageQuestionInquiry,
      },
      {
          path: '/mypage/question/inquiry/write',
          name: 'MypageQuestionInquiryWrite',
          component: MypageQuestionInquiryWrite,
      },
      {
          path: '/dashboard/order',
          name: 'DashboardOrderList',
          component: DashboardOrderList,
      },
      {
          path: '/dashboard/order/:shopIdx/:orderIdx',
          name: 'DashboardOrderInfo',
          component: DashboardOrderInfo,
      },
      {
          path: '/dashboard/heart',
          name: 'DashboardHeartList',
          component: DashboardHeartList,
      },
  ]
})