<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>로그인</p>
    </header>
    <div class="auth-form">
      <input type="text" class="input-default" placeholder="아이디를 입력하세요." v-model="id" @keyup.enter="login()">
      <input type="password" class="input-default" placeholder="비밀번호를 입력하세요." v-model="password" @keyup.enter="login()">
      <div class="checks auth-login">
        <input type="checkbox" id="autoLogin" v-model="autoLogin">
        <label for="autoLogin">자동로그인</label>
      </div>
      <button type="button" class="btn btn-pink" @click="login()">로그인</button>
      <div class="auth-link">
        <router-link :to="{ name: 'FindId' }" class="btn">아이디 찾기</router-link>
        <router-link :to="{ name: 'FindPassword' }" class="btn">비밀번호 찾기</router-link>
        <router-link :to="{ name: 'Regist' }" class="btn">회원가입</router-link>
      </div>
      <div v-if="checkSocial()" class="social-login-wrap">
        <button type="button" class="btn btn-naver" @click="oAuth('naver')">
          <img src="/assets/image/icon_naver.svg" alt="네이버" />
          <span>네이버계정으로 로그인</span>
        </button>
        <button type="button" class="btn btn-kakao" @click="oAuth('kakao')">
          <img src="/assets/image/icon_kakao.svg" alt="카카오" />
          <span class="kakao">카카오계정으로 로그인</span>
        </button>
        <button type="button" class="btn btn-google" @click="oAuth('google')">
          <img src="/assets/image/icon_google.svg" alt="구글" />
          <span>구글계정으로 로그인</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      id: 'mostpns',
      password: 'test1234!',
      autoLogin: false,
    }
  },
  mounted() {
    if(localStorage.getItem('guest') === 'true') {
      localStorage.removeItem('autoLogin')
      localStorage.removeItem('guest')



      /*기존 게스트 계정 삭제 api추가*/
      this.$delete(this.$USER_GUEST, 'login_mounted', () => {
        console.log('guest deleted')

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('market')
        sessionStorage.removeItem('DashboardTab')
        sessionStorage.removeItem('DashboardShopInfoTab')

      }, (result) => {
        console.log('guest delet failed', result)

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('market')
        sessionStorage.removeItem('DashboardTab')
        sessionStorage.removeItem('DashboardShopInfoTab')

      })

    }
    if(typeof this.$route.params.msg !== 'undefined') {
      this.notify('error', this.$route.params.msg)
    }

    let autoLogin = localStorage.getItem('autoLogin')
    if(autoLogin) {
      this.doAutoLogin(autoLogin)
    }
  },
  methods: {
    checkSocial() {
      return window.useSocial()
    },
    oAuth(service) {
      window.removeEventListener('message', this.oAuthDone)

      window.open(process.env.VUE_APP_DOMAIN + '/oauth2/authorization/' + service, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

      window.addEventListener('message', this.oAuthDone);
    },
    oAuthDone(e) {
      if(e.origin === process.env.VUE_APP_DOMAIN) {
        this.notify('success', '소셜 로그인에 성공하였습니다.')

        sessionStorage.removeItem('address')
        sessionStorage.removeItem('market')

        sessionStorage.setItem('token', e.data.accessToken)
        sessionStorage.setItem('refresh_token', e.data.refreshToken)
        localStorage.setItem('autoLogin', e.data.passToken)
        localStorage.removeItem('guest')
        this.refreshPush()
        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)

        this.setPushAlarm(() => {
          this.$router.push({ name: 'Dashboard' })
        })

        window.removeEventListener('message', this.oAuthDone)
      }
    },

    login() {
      if(!this.autoLogin) {
        localStorage.removeItem('autoLogin')
      }

      this.doLogin()
    },

    doLogin() {
      let formData = new FormData()
      formData.append('id', this.id.trim())
      formData.append('password', this.password.trim())
      formData.append('remember', this.autoLogin)

      /*기존 일반 로그인 시 게스트 토큰으로 게스트 계정 삭제 하던부분 주석 처리.*/
      // if(localStorage.getItem('guest') === 'true') {
      //   formData.append('token', sessionStorage.getItem('token'))
      // }

      this.$post(this.$USER_LOGIN, 'login_doLogin', formData, (result) => {
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('market')

        sessionStorage.setItem('token', result.data.accessToken)
        sessionStorage.setItem('refresh_token', result.data.refreshToken)
        localStorage.removeItem('guest')
        if(this.autoLogin) {
          localStorage.setItem('autoLogin', result.data.passToken)
        }

        this.refreshPush()

        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)

        this.setPushAlarm(() => {
          this.$router.push({ name: 'Dashboard' })
        })
      }, (result) => {
        this.httpError(result.data)

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('refresh_token')
        localStorage.removeItem('autoLogin')
      })
    },

    // setPushAlarm(callback) {
    //   this.$get(this.$USER_PUSH, 'login_getPush', (result) => {
    //     let alarm = result.data
    //     this.getMarket((result) => {
    //       let marketIdx = result.data.idx
    //       window.subscribedTopic('notice')
    //       alarm.alarmMarket ? window.subscribedTopic('event_all') : window.unsubscribedTopic('event_all')
    //       alarm.alarmMarket ? window.subscribedTopic(`event_${marketIdx}`) : window.unsubscribedTopic(`event_${marketIdx}`)
    //       alarm.alarmService ? window.subscribedTopic('service_all') : window.unsubscribedTopic('service_all')
    //       alarm.alarmService ? window.subscribedTopic(`service_${marketIdx}`) : window.unsubscribedTopic(`service_${marketIdx}`)
    //       callback()
    //     })
    //
    //   }, (result) => {
    //     this.httpError(result.data)
    //     callback()
    //   })
    // },

    doAutoLogin(passToken) {
      let formData = new FormData()
      formData.append('passToken', passToken)

      this.$post(this.$USER_AUTOLOGIN, 'login_doAutoLogin', formData, (result) => {
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('market')

        sessionStorage.setItem('token', result.data.accessToken)
        sessionStorage.setItem('refresh_token', result.data.refreshToken)
        localStorage.setItem('autoLogin', result.data.passToken)
        localStorage.removeItem('guest')

        this.refreshPush()

        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)

        this.setPushAlarm(() => {
          this.$router.push({ name: 'Dashboard' })
        })

      }, (result) => {
        this.httpError(result.data)

        sessionStorage.removeItem('token')
        sessionStorage.removeItem('refresh_token')
        localStorage.removeItem('autoLogin')
      })
    }
  }
}
</script>

<style scoped>

</style>
