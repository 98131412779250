<template>
  <div class="all-content">
    <header class="header-wrap">
      <p>찜</p>
    </header>
    <div class="product-wrap heart-list">
      <div class="product-list bg-white">
        <p class="heart-list-title">찜한 매장</p>
        <div v-for="(shop, shopIdx) in heartShops" v-bind:key="shopIdx" class="product-item">
          <router-link :to="{ name: 'DashboardShopInfo', params: { shopIdx: shop.shopIdx } }">
            <div class="product-item-thumbnail">
              <img v-if="shop.imgPath" v-bind:src="getImage(shop.imgPath, 200, 200, 1)" alt="상점 이미지" @error="getOriginImage($event, shop.imgPath)" />
              <img v-else src="/assets/image/thumbnail_preparing.svg" alt="이미지 준비중" />
            </div>
            <div class="shop-item-content">
              <p class="product-item-badge">
                <span v-for="(category, categoryIdx) in shop.category" v-bind:key="categoryIdx" class="badge" v-bind:class="'badge-'+getCategoryName(category)">{{ category }}</span>
              </p>
              <p class="product-item-shop-name">{{ shop.shopName }}</p>
              <p class="product-item-name">{{ shop.shopInfo }}</p>
            </div>
          </router-link>
          <button type="button" class="btn btn-heart" @click="confirmShop(shopIdx, shop.shopIdx)">
            <img src="/assets/image/icon_shop_heart_on.svg" alt="찜한 매장" />
          </button>
        </div>
        <div v-if="loaded && heartShops.length === 0" class="product-item">
          <div class="list-none">
            <p><img src="/assets/image/character_sad.svg" alt="캐릭터" /></p>
            <p class="list-none-title">찜한 매장이 없습니다.</p>
          </div>
        </div>
      </div>
      <div class="product-list bg-white">
        <p class="heart-list-title">찜한 상품</p>
        <div v-for="(product, productIdx) in heartProducts" v-bind:key="productIdx" class="product-item">
          <router-link :to="{ name: 'DashboardProductInfo', params: { productIdx: product.productIdx } }">
            <div class="product-item-thumbnail">
              <img v-if="product.imgPath" v-bind:src="getImage(product.imgPath, 200, 200, 1)" alt="상품 이미지" @error="getOriginImage($event, product.imgPath)" />
              <img v-else src="/assets/image/thumbnail_preparing.svg" alt="이미지 준비중" />
            </div>
            <div class="shop-item-content">
              <p class="product-item-badge">
                <span v-for="(category, categoryIdx) in product.category" v-bind:key="categoryIdx" class="badge" v-bind:class="'badge-'+getCategoryName(category)">{{ category }}</span>
              </p>
              <p class="product-item-shop-name">{{ product.productName }}</p>
              <p class="product-item-name">{{ product.shopName }}</p>
            </div>
          </router-link>
          <button type="button" class="btn btn-heart" @click="confirmProduct(productIdx, product.productIdx)">
            <img src="/assets/image/icon_shop_heart_on.svg" alt="찜한 상품" />
          </button>
        </div>
        <div v-if="loaded && heartProducts.length === 0" class="product-item">
          <div class="list-none">
            <p><img src="/assets/image/character_sad.svg" alt="캐릭터" /></p>
            <p class="list-none-title">찜한 상품이 없습니다.</p>
          </div>
        </div>
      </div>
    </div>
    <dashboard-utils-menu menu="2"></dashboard-utils-menu>
  </div>
</template>

<script>
import DashboardUtilsMenu from "@/components/dashboard/utils/menu";

export default {
  name: "DashboardHeartList",
  components: {
    DashboardUtilsMenu,
  },
  data() {
    return {
      heartProducts: [],
      heartShops: [],
      loaded: false,
    }
  },
  mounted() {
    if(localStorage.getItem('guest') === 'true') {
      this.$router.back()
      this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
        localStorage.removeItem('autoLogin')
        this.$router.push({ name: 'Login' })
      }, () => {

      })
    } else {
      this.getHeart()
    }
  },

  methods: {
    confirmShop(i, idx) {
      this.$root.$refs.Confirm.showDialog('찜한 매장을 삭제하시겠습니까?', '예', '아니오', () => {
        this.toggleHeart(i, '1', idx)
      }, () => {})
    },
    confirmProduct(i, idx) {
      this.$root.$refs.Confirm.showDialog('찜한 상품을 삭제하시겠습니까?', '예', '아니오', () => {
        this.toggleHeart(i, '0', idx)
      }, () => {})
    },
    getHeart() {
      this.$get(this.$USER_WISH, 'heart_list_getHeart', (result) => {
        this.heartProducts = result.data.product
        this.heartShops = result.data.shop

        this.loaded = true
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleHeart(i, type, idx) {
      let formData = new FormData()
      formData.append('type', type)

      this.$put(this.$USER_WISH+'/'+idx, 'heart_list_toggleHeart', formData, () => {
        if(type === '1') {
          this.heartShops.splice(i, 1)
        } else {
          this.heartProducts.splice(i, 1)
        }
      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
