<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <button type="button" class="btn btn-back" @click="doBack($route.params.back)">
        <i class="icon-back"></i>
      </button>
      <p>장바구니</p>
    </header>
    <div class="cart-wrap mt-50">
      <div v-for="(shop,shopIdx) in carts.shops" v-bind:key="shopIdx" class="product-wrap">
        <div class="title">
          {{ shop.shopName }}
          <strong class="color-pink">{{ shop.marketName }}</strong>
        </div>
        <div class="products">
          <div v-for="(product,productIdx) in shop.product" v-bind:key="productIdx" class="product">
            <strong class="name">{{ product.productName }}</strong>
            <ul v-if="product.option || product.addOption">
              <li v-if="product.option">{{ product.option.name }} : {{ (product.price + product.option.price)|numberWithCommas }}원</li>
              <li v-for="(addOption,addOptionIdx) in product.addOption" v-bind:key="addOptionIdx">{{ addOption.name }} : {{ addOption.price|numberWithCommas }}원</li>
            </ul>
            <div class="etc">
              <div class="quantity">
                <span @click="changeQuantity(shopIdx, productIdx, product.cartIdx, product.quantity-1)" v-bind:class="{disabled:product.quantity===1}">-</span>
                <span>{{ product.quantity }}</span>
                <span @click="changeQuantity(shopIdx, productIdx, product.cartIdx, product.quantity+1)">+</span>
              </div>
              <strong class="price">{{ product.totalPrice|numberWithCommas }}원</strong>
            </div>
            <span class="btn-remove" @click="deleteCart(shopIdx, productIdx, product.cartIdx)">
              <i class="icon-close"></i>
            </span>
          </div>
        </div>
        <div class="total">
          상품합계
          <!-- TODO: 상품 합계(상품별 가격 합산) -->
          <strong>{{ shop.totalPrice|numberWithCommas }}원</strong>
        </div>
      </div>
      <!--TODO: 여기서 상품 loop 종료-->
      <div class="payment-info">
        <ul>
          <li>총 상품금액 <span class="product-total">{{ carts.totalPrice|numberWithCommas }}원</span></li>
          <li>배달요금 <span class="delivery-fee">{{ carts.deliveryPrice|numberWithCommas }}원</span></li>
          <hr />
          <li>총 주문 금액<strong class="color-pink">{{ carts.orderPrice|numberWithCommas }}원</strong></li>
        </ul>
      </div>
      <div class="fnb-btn-group">
        <a role="button" @click="$router.back()">상품 추가하기</a>
        <router-link :to="{ name: 'Payment' }">주문하기<p class="bagde color-pink">{{ cartCount }}</p></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentCart",
  data() {
    return {
      cartCount: 0,
      carts: [],
    }
  },
  mounted() {
    this.getCart()
  },
  methods: {
    getCart() {
      this.$get(this.$USER_CART, 'cart_getCart', (result) => {
        this.cartCount = 0
        for(let i=0; i<result.data.shops.length; i++) {
          this.cartCount += result.data.shops[i].product.length
        }
        this.carts = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    changeQuantity(i, j, idx, quantity) {
      if(quantity > 0) {
        let formData = new FormData()
        formData.append('idx', idx)
        formData.append('quantity', quantity)

        this.$put(this.$USER_CART, 'cart_changeQuantity', formData, () => {
          this.getCart()
        }, (result) => {
          this.httpError(result.data)
        })
      }
    },
    deleteCart(i, j, idx) {
      this.$delete(this.$USER_CART + '?cartIdx=' + idx, 'cart_deleteCart', () => {
        this.getCart()
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>