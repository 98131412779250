<template>
  <div class="all-content">
    <header class="header-wrap">
      <p>이벤트</p>
      <button type="button" class="btn btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="mypage-content bg-white">
      <div class="event-view">
        <p class="event-view-date">{{ event.regDate }}</p>
        <p class="event-view-title">{{ event.title }}</p>
        <img v-bind:src="getImage(event.imgPath, 1000, null, null)" alt="이벤트 배너 이미지"  @error="getOriginImage($event, event.imgPath)"/>
        <p class="mb-20">
          <span v-if="event.timeS" class="event-date">이벤트기간 :  {{ event.timeS }} ~ {{ event.timeE }}</span>
          <span v-if="event.state==='1'" class="badge badge-pink event-view-badge">진행중</span>
          <span v-else class="badge event-view-badge">종료</span>
        </p>
        <p class="event-view-content" v-html="event.content">
        </p>
        <button v-if="event.link" @click="open(event.link)" class="btn btn-darkgray">{{ event.link }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageEventInfo",
  data() {
    return {
      promotionIdx: this.$route.params.promotionIdx,
      event: {
        content: '',
        imgPath: '',
        promotionIdx: 0,
        regDate: '',
        state: '',
        timeE: '',
        timeS: '',
        title: '',
      },
    }
  },
  mounted() {
    this.getEvent()
  },
  methods: {
    open(url) {
      window.openWeb(url)
    },
    getEvent() {
      this.$get(this.$USER_EVENT+'/'+this.promotionIdx, 'event_info_getEvent', (result) => {
        this.event = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>