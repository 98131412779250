<template>
  <div v-if="show">
    <div class="bg-block-ui overlay_bg"></div>
    <div class="confirm-wrap overlay_popup">
      <div class="confirm-layer">
        <p class="confirm-layer-btn">
          <button type="button" class="btn btn-close" @click="close()"><i class="icon-close"></i></button>
        </p>
        <div class="confirm-layer-box">
          <p v-html="msg"></p>
          <div class="content-btn">
            <button type="button" class="btn-left btn btn-confirm btn-outline-pink" @click="no()">{{ textNo }}</button>
            <button type="button" class="btn-right btn btn-confirm btn-pink" @click="ok()">{{ textOk }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  data() {
    return {
      show: false,
      msg: '',
      callback: null,
      cancel: null,
      textOk: '',
      textNo: '',
    }
  },
  methods: {
    showDialog(msg, textOk, textNo, callback, cancel) {
      this.msg = msg
      this.textOk = textOk
      this.textNo = textNo
      this.callback = callback
      this.cancel = cancel

      this.show = true
    },
    close() {
      this.show = false
    },
    ok() {
      this.close()
      this.callback()
    },
    no() {
      this.close()
      this.cancel()
    }
  }
}
</script>

<style scoped>
.overlay_popup {
  z-index: 100;
  background: #fff;
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 50%;
}
.overlay_bg {
  z-index: 100;
}
</style>
