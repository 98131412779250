<template>
  <div>
    <div class="dashboard-product-wrap">
      <div class="product-list">
        <div v-for="(usedShop, usedShopIdx) in usedShops" v-bind:key="usedShopIdx">
          <p class="dashboard-info">
            <span>{{ usedShop.orderDate }}</span>
            <span class="delivery">{{ getShopState(usedShop.orderState) }}</span>
          </p>
          <div class="product-item">
            <router-link :to="{ name: 'DashboardShopInfo', params: { shopIdx: usedShop.shopIdx } }">
              <div class="product-item-thumbnail">
                <img v-if="usedShop.imgPath" v-bind:src="getImage(usedShop.imgPath, 200, 200, 1)" alt="상점 이미지" @error="getOriginImage($event, usedShop.imgPath)">
                <img v-else src="/assets/image/thumbnail_preparing.svg" alt="이미지 준비중">
              </div>
              <div class="dashboard-item-content">
                <p class="product-item-badge">
                  <span class="badge">{{ usedShop.marketName }}</span>
                  <span class="badge badge-onnuri" v-if="usedShop.onNuri">온누리</span>
                </p>
                <p class="product-item-shop-name">{{ usedShop.shopName }}</p>
                <p class="product-item-name">운영시간 {{ usedShop.orderTimeS_H }}:{{ usedShop.orderTimeS_M }} ~ {{ usedShop.orderTimeE_H }}:{{ usedShop.orderTimeE_M }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded && usedShops.length === 0" class="used-shop-none">
      <p><img src="/assets/image/character_sad.svg" alt="최근 이용 매장이 없습니다." /></p>
      <p class="ment">최근 이용 매장이 없습니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardTabsUsed",
  data() {
    return {
      usedShops: [],
      loaded: false,
    }
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      if(localStorage.getItem('guest') !== 'true') {
        this.$get(this.$USER_RECENTLY_USEDSHOP, 'used_shop_getHistory', (result) => {
          this.usedShops = result.data

          this.loaded = true
        }, (result) => {
          if(result.data.error !== '아직 이용한 가맹점이 없습니다.') {
            this.httpError(result.data)
          }
        })
      } else {
        this.$parent.tabPosition = 0
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {

        })
      }
    },
  }
}
</script>

<style scoped>

</style>
