<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>공지사항</p>
    </header>
    <div class="board-form" v-bind:class="{ vertical: notices.length + fixed.length === 0 }">
      <div v-if="notices.length + fixed.length > 0" class="notice-wrap bg-white">
        <ul v-for="(notice, noticeIdx) in fixed" v-bind:key="notice.idx">
          <li @click="toggleNotice($event)">
              <i class="notice-helper" v-bind:class="noticeIdx===0?'icon-arrow-up':'icon-arrow-down'"></i>
              <div class="notice-helper notice">
                <span class="badge" :class="{ 'badge-restaurant': notice.name !== '관리자', 'badge-fish': notice.name === '관리자' }">{{ notice.name }}</span>
                {{ notice.title }}
              </div>
              {{ notice.regDate }}
          </li>
          <li v-bind:style="noticeIdx!==0?'':'display: block'">
            <div class="content" v-html="notice.content">
            </div>
          </li>
        </ul>
        <ul v-for="(notice, noticeIdx) in notices" v-bind:key="notice.idx">
          <li @click="toggleNotice($event)">
            <i class="notice-helper" v-bind:class="noticeIdx===0?'icon-arrow-up':'icon-arrow-down'"></i>
            <div class="notice-helper">
              <span class="badge" :class="{ 'badge-restaurant': notice.name !== '관리자', 'badge-fish': notice.name === '관리자' }">{{ notice.name }}</span>
              {{ notice.title }}
            </div>
            {{ notice.regDate }}
          </li>
          <li v-bind:style="noticeIdx!==0?'':'display: block'">
            <div class="content" v-html="notice.content">
            </div>
          </li>
        </ul>
      </div>
      <div v-if="notices.length + fixed.length === 0" class="ment">
          <img src="/assets/image/character_sad.svg" alt="내역이 없습니다!" />
          <strong>내역이 없습니다!</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageNotice",
  data() {
    return {
      notices: [],
      fixed: [],
    }
  },
  mounted() {
    this.getNotice()
  },
  methods: {
    getNotice() {
      this.$get(this.$USER_NOTICE, 'notice_getNotice', (result) => {
        this.notices = []
        this.fixed = []
        for (let i=0;i<result.data.length;i++) {
          let item = result.data[i]
          if (item.notice === '공지') {
            this.fixed.push(item)
          } else {
            this.notices.push(item)
          }
        }
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleNotice(event) {
      if(window.$(event.target).parent().children('li:last-child').is(":visible")) {
        window.$(event.target).children('i').removeClass('icon-arrow-up')
        window.$(event.target).children('i').addClass('icon-arrow-down')

        window.$(event.target).parent().children('li:last-child').slideUp()
      } else {
        window.$(event.target).children('i').removeClass('icon-arrow-down')
        window.$(event.target).children('i').addClass('icon-arrow-up')

        window.$(event.target).parent().children('li:last-child').slideDown()
      }
    },
  }
}
</script>

<style scoped>
.notice-helper {
  pointer-events: none;
}
</style>
