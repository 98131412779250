<template>
  <div class="all-content bg-white">
    <header class="header-wrap header-step">
      <button type="button" class="btn btn-back" @click="$router.replace({ name: 'Login' })"><i class="icon-back"></i></button>
      <p>회원가입</p>
    </header>
    <div class="auth-form regist-form">
      <div class="regist-form-wrap">
        <ul class="step-list">
          <li class="step-item active">
            <span class="step-num"><i class="icon-check"></i></span>
            <span class="step-txt">계정 입력</span>
          </li>
          <li class="step-item active">
            <span class="step-num"><i class="icon-check"></i></span>
            <span class="step-txt">이메일 입력</span>
          </li>
          <li class="step-item active">
            <span class="step-num"><i class="icon-check"></i></span>
            <span class="step-txt">휴대폰 인증</span>
          </li>
          <li class="step-item active">
            <span class="step-num">4</span>
            <span class="step-txt">완료</span>
          </li>
        </ul>
        <div class="step-content">
          <p class="step-title color-pink">step 4</p>
          <h3 class="step-sub-title">회원가입 완료</h3>
          <p class="step-content-txt">
            <span>회원가입이 완료되었습니다.</span>
          </p>
          <router-link :to="{ name: 'Login' }" replace class="btn btn-pink">로그인</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistStep4",
  mounted() {
    if(typeof this.$route.params.done !== 'undefined') {
      window.location.reload()
    }
  }
}
</script>

<style scoped>

</style>