export default Object.freeze({
    install(Vue) {
        // 토스트 스타일 알림
        Vue.prototype.notify = function (type, msg, is_alert=false) {
            if(is_alert) {
                alert(msg)
            } else {
                if(type === 'error') {
                    window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-alert-fail\"></i> <span>"+msg+"</span></div>")
                } else {
                    window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-check\"></i> <span>"+msg+"</span></div>")
                }
                setTimeout(function() {
                    window.$('#alert-wrap div:first-child').remove()
                }, 3000)
            }
        }

        // 기본 HTTP 에러 처리
        Vue.prototype.httpError = function (data) {
            if(Array.isArray(data.error)) {
                for(let i=0; i<data.error.length; i++) {
                    this.notify('error', data.error[i].message, false)
                }
            } else {
                if(data.state === 401) {
                    if(data.error === '해당 액세스 토큰의 유효기간이 만료되었음') {
                        sessionStorage.removeItem('refresh_token')
                        sessionStorage.removeItem('token')

                        // this.$router.push({ name: 'Login', params: { msg: '해당 액세스 토큰의 유효기간이 만료되었습니다.' } }).catch(() => {})
                        this.$router.push({ name: 'Login'}).catch(() => {})
                    }

                    sessionStorage.removeItem('refresh_token')
                    sessionStorage.removeItem('token')

                    if(!localStorage.getItem('autoLogin')) {
                        this.$router.push({ name: 'Login', params: { msg: '로그인이 필요한 서비스입니다.' } }).catch(() => {})
                    }
                    this.$router.push({ name: 'Login' }).catch(() => {})
                } else {
                    this.notify('error', data.error, false)
                }
            }
        }

        // 디바이스 푸시 갱신
        Vue.prototype.refreshPush = function () {
            if(window.getVersion()) {
                let formData = new FormData()
                formData.append('device', window.getDeviceType()?window.getDeviceType():'')
                formData.append('fcmToken', window.getFCMToken()?window.getFCMToken():'')

                this.$put(this.$USER_FCM_UPDATE, 'refreshPush', formData, () => {
                    //
                }, () => {
                    this.notify('error', '푸시 서비스 등록에 문제가 발생했습니다.')
                })
            }
        }

        // 토큰 갱신
        Vue.prototype.refreshTokenRate = 5*60*1000
        Vue.prototype.refreshToken = function () {
            let refreshTokan = sessionStorage.getItem('refresh_token')
            if(!refreshTokan) {
                return
            }

            let formData = new FormData()
            formData.append('refreshToken ', refreshTokan)

            this.$post(this.$USER_REFRESHTOKEN, 'refreshToken', formData, (result) => {
                sessionStorage.setItem('token', result.data.accessToken)
                sessionStorage.setItem('refresh_token', result.data.refreshToken)
                if(localStorage.getItem('autoLogin')) {
                    localStorage.setItem('autoLogin', result.data.passToken)
                }

                setTimeout(() => {
                    this.refreshToken()
                }, this.refreshTokenRate)
            }, (result) => {
                this.httpError(result.data)

                sessionStorage.removeItem('token')
                sessionStorage.removeItem('refresh_token')
            })
        }

        Vue.prototype.getOrderState = function (value) {
            if(value === '0') {
                return '주문'
            } else if(value === '1') {
                return '주문취소'
            } else if(value === '2') {
                return '주문확인중'
            } else if(value === '3') {
                return '상품준비중'
            } else if(value === '4') {
                return '배달준비중'
            } else if(value === '5') {
                return '배달중'
            } else if(value === '6') {
                return '배달완료'
            }
        }

        Vue.prototype.getShopState = function (value) {
            if(value === '0') {
                return '주문완료'
            } else if(value === '1') {
                return '접수완료'
            } else if(value === '2') {
                return '픽업요청'
            } else if(value === '3') {
                return '픽업완료'
            } else if(value === '4') {
                return '분류완료'
            } else if(value === '5') {
                return '배달완료'
            } else if(value === '6') {
                return '주문취소'
            } else if(value === '7') {
                return '접수취소'
            }
        }

        Vue.prototype.getCategoryName = function (categoryName) {
            if(categoryName === '먹거리') {
                return 'food'
            } else if(categoryName === '음식점') {
                return 'restaurant'
            } else if(categoryName === '축산') {
                return 'livestock'
            } else if(categoryName === '야채') {
                return 'vegetable'
            } else if(categoryName === '과일') {
                return 'fruit'
            } else if(categoryName === '수산') {
                return 'fish'
            } else if(categoryName === '떡/방앗간') {
                return 'ricecake'
            } else if(categoryName === '건어물') {
                return 'driedfish'
            } else if(categoryName === '반찬') {
                return 'sidedish'
            } else if(categoryName === '생활용품') {
                return 'household-goods'
            } else if(categoryName === '건강식품') {
                return 'healthfood'
            } else if(categoryName === '원예') { //화훼 -> 원예 변경 20210226 pk
                return 'flower'
            } else if(categoryName === '온누리') {
                return 'onnuri'
            } else if(categoryName === '신나고') {
                return 'sinnago'
            } else {
                return 'default'
            }
        }

        Vue.prototype.getAddress = function (callback, save=false) {
            if(!save) {
                let address = sessionStorage.getItem('address')
                if (address) {
                    let json_address = JSON.parse(address)
                    if(callback) {
                        callback({ success: true, data: json_address })
                    }
                    return
                }
            }

            this.$get(this.$USER_ADDRESS, 'getAddress', (result) => {
                sessionStorage.setItem('address', JSON.stringify(result.data))
                if(callback) {
                    callback({ success: true, data: result.data })
                }
            }, (result) => {
                if(callback) {
                    callback({success: false, data: result.data})
                } else {
                    this.httpError(result.data)
                }
            })
        }

        Vue.prototype.getMarket = function (callback, save=false) {
            if(!save) {
                let market = sessionStorage.getItem('market')
                if (market) {
                    let json_market = JSON.parse(market)
                    if(callback) {
                        callback({ success: true, data: json_market })
                    }
                    return
                }
            }

            this.$get(this.$USER_MYMARKET, 'getMarket', (result) => {
                sessionStorage.setItem('market', JSON.stringify(result.data))
                if(callback) {
                    callback({ success: true, data: result.data })
                }
            }, (result) => {
                if(callback) {
                    callback({success: false, data: result.data})
                } else {
                    this.httpError(result.data)
                }
            })
        }

        Vue.prototype.setPushAlarm = function (callback) {
            this.$get(this.$USER_PUSH, 'login_getPush', (result) => {
                let alarm = result.data
                this.getMarket((result) => {
                    let marketIdx = result.data.idx
                    window.subscribedTopic('notice')
                    alarm.alarmMarket ? window.subscribedTopic('event_all') : window.unsubscribedTopic('event_all')
                    alarm.alarmMarket ? window.subscribedTopic(`event_${marketIdx}`) : window.unsubscribedTopic(`event_${marketIdx}`)
                    alarm.alarmService ? window.subscribedTopic('service_all') : window.unsubscribedTopic('service_all')
                    alarm.alarmService ? window.subscribedTopic(`service_${marketIdx}`) : window.unsubscribedTopic(`service_${marketIdx}`)
                    callback()
                })
            }, (result) => {
                this.httpError(result.data)
                callback()
            })
        }

        Vue.prototype.doBack = function (back, name='Dashboard', redirect=false) {
            if(typeof back !== 'undefined') {
                this.$router.back()
            } else {
                if(redirect) {
                    this.$router.push({ name: name })
                } else {
                    this.$router.replace({ name: name })
                }
            }
        }

        Vue.prototype.getImage = function (imgPath, w, h, b) {
            if (imgPath) {
                let path = this.$DOMAIN + '/user/image?path=' + encodeURIComponent('/'+imgPath.split('/').splice(3).join('/'))

                if(w) {
                    path += '&w=' + w
                }
                if(h) {
                    path += '&h=' + h
                }
                if(b) {
                    path += '&b=' + b
                }

                return path
            } else {
                return null
            }
        }
        Vue.prototype.getOriginImage = function (e, image) {
            if (e.target.src === image) {
                e.target.src = '/assets/image/photo_preparing_product.svg'
            } else {
                e.target.src = image
            }
        }

        Vue.filter('numberWithCommas', function (x) {
            return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
    }
})
