<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>1:1 문의</p>
    </header>
    <div class="inquiry-write">
      <ul>
        <li>
          <label>이름</label>
          <input type="text" class="input-box" v-bind:placeholder="address.name" disabled>
        </li>
        <li>
          <label>이메일</label>
          <input type="text" class="input-box" v-bind:placeholder="address.email" disabled>
        </li>
        <li v-if="shop.shopIdx">
          <label>문의처</label>
          <input type="text" class="input-box" v-bind:placeholder="shop.shopName" disabled>
        </li>
        <li v-else>
            <label>문의처</label>
            <input type="text" class="input-box" v-bind:placeholder="market.name" disabled>
        </li>
        <li>
          <label>제목</label>
          <input type="text" class="input-box" placeholder="제목을 입력하세요." v-model="title">
        </li>
        <li>
          <label>내용</label>
          <textarea class="textarea-default" placeholder="문의내용을 입력하세요." v-model="content"></textarea>
        </li>
        <li>
          <label class="file-attachments">첨부파일</label>
          <!--TODO: X 버튼 Tap 시 삭제-->
          <p class="file-attachments">
            <span class="input-file">
              <input id="images_text" type="text" class="input-box file-attachments" placeholder="jpg, png, gif만 가능, 최대 10MB" onclick="$('#images').click()" v-model="fileName" readonly>
              <button type="button" class="btn input-delete" @click="removeImage()"><i class="icon-close"></i></button>
              <input id="images" type="file" class="input-box file-attachments" @change="changeImage($event)" style="display: none" accept="image/*">
            </span>
            <button type="button" class="attachments" onclick="$('#images').click()"><img src="/assets/image/icon_file.svg" alt="파일 아이콘" /></button>
          </p>
        </li>
      </ul>
    </div>
    <div class="fnb-btn-group">
      <button type="button" class="btn btn-pink" @click="setInquiry()">등록하기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageQuestionInquiryWrite",
  data() {
    return {
      address: {
        name: '',
        email: '',
      },
      shop: {
        shopIdx: this.$route.params.shopIdx,
        shopName: this.$route.params.shopName,
      },
      market: {
        name: '',
      },
      title: '',
      content: '',
      images: '',
      fileName: '',
    }
  },
  mounted() {
    this.getAddress((result) => {
      if(result.success) {
        this.address = result.data
      } else {
        this.httpError(result.data)
      }
    })

    this.getMarket((result) => {
      if(result.success) {
        this.market = result.data
      }
    })
  },
  methods: {
    changeImage(event) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }

      this.images = files

      this.fileName = ''
      for(let i=0; i<this.images.length; i++) {
        this.fileName += files[i].name + ', '
      }

      this.fileName = this.fileName.substr(0, this.fileName.length-2)
      if(this.fileName.length > 27) {
        this.fileName = this.fileName.substr(0, 24) + '...'
      }
    },
    removeImage() {
      this.images = '';
      this.fileName = '';
    },
    setInquiry() {
      let formData = new FormData()

      formData.append('content', this.content.trim())
      formData.append('title', this.title.trim())
      for(let i=0; i<this.images.length; i++) {
        formData.append('image', this.images[i])
      }

      let idx = ''
      if(this.shop.shopIdx) {
        idx = '/'+this.shop.shopIdx
      }

      if(localStorage.getItem('guest') !== 'true') {
        this.$post(this.$USER_QNA + idx, 'inquiry_write_setInquiry', formData, () => {
          this.$router.back()
          this.notify('success', '1:1 문의가 등록되었습니다.')
        }, (result) => {
          this.httpError(result.data)
        })
      } else {
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {

        })
      }

    },
  }
}
</script>

<style scoped>

</style>
