<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-arrow-left"></i></button>
      <p>알림</p>
    </header>
    <div class="alram-wrap" v-if="alarms.length > 0" id="alarmDiv">
      <div class="alram-list" v-for="(alarm, alarmIdx) in alarms" v-bind:key="alarmIdx">
        <p class="alram-list-header">
          {{ alarm.title }}
        </p>
        <p class="alram-list-contents">
          <span>{{ /\[.*\]/.test(alarm.content) ? /\[.*\]/.exec(alarm.content)[0] : ''}}</span>
          {{  /\[.*\]/.test(alarm.content) ? alarm.content.replace(/\[.*\]/.exec(alarm.content)[0], "") : alarm.content }}
        </p>
        <p class="alram-list-footer">
          {{ alarm.regDate }}
        </p>
      </div>
    </div>
    <div v-if="loaded && alarms.length === 0" class="list-none-wrap">
      <div class="list-none">
        <p><img src="/assets/image/character_sad.svg" alt="캐릭터" /></p>
        <p class="list-none-title">알림이 없습니다!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardAlarm",
  data() {
    return {
      alarms: [],
      page: 1,
      loading: false,
      loaded: false,
    }
  },
  mounted() {
    this.getAlarm()

    window.$(window).on('scroll', this.onScrollEvent)
  },
  beforeDestroy() {
    window.$(window).off('scroll', this.onScrollEvent)
  },
  methods: {
    onScrollEvent() {
      if(window.$(window).scrollTop() + window.$(window).innerHeight() >= window.$('#alarmDiv')[0].scrollHeight - 10) {
        this.getAlarm()
      }
    },
    getAlarm() {
      this.loaded = false
      if(this.loading) {
        return
      }

      this.loading = true

      this.$get(this.$USER_NOTIFICATION+'?page='+this.page, 'alarm_getAlarm', (result) => {
        if(result.data !== '알림이 없습니다!') {
          if(result.data.length > 0) {
            this.alarms = this.alarms.concat(result.data)
            this.page++

            this.loading = false
          }
        }

        this.loaded = true
      }, (result) => {
        this.httpError(result.data)

        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
