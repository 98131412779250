<template>
  <footer class="footer-wrap bg-white">
    <ul class="footer-menu">
      <li class="footer-item-wrap">
        <router-link :to="{ name: 'Dashboard' }" class="footer-item" v-bind:class="{ active: menu==='0' }"><i class="icon-home"></i><span>홈</span></router-link>
      </li>
      <li class="footer-item-wrap">
        <router-link :to="{ name: 'DashboardOrderList' }" class="footer-item" v-bind:class="{ active: menu==='1' }"><i class="icon-history"></i><span>주문내역</span></router-link>
      </li>
      <li class="footer-item-wrap">
        <router-link :to="{ name: 'DashboardHeartList' }" class="footer-item" v-bind:class="{ active: menu==='2' }"><i class="icon-heart"></i><span>찜</span></router-link>
      </li>
      <li class="footer-item-wrap">
        <router-link :to="{ name: 'Mypage' }" class="footer-item" v-bind:class="{ active: menu==='3' }"><i class="icon-my"></i><span>마이</span></router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "DashboardUtilsMenu",
  props: [
    'menu',
  ],
}
</script>

<style scoped>

</style>