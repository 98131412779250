<template>
  <div class="all-content">
    <header class="header-wrap">
      <p>주문내역</p>
    </header>
    <div id="orderDiv" class="order-list-wrap">
      <div v-for="(order, orderIdx) in orders" v-bind:key="orderIdx" class="product-list">
        <div class="order-list-title">
          <span class="order-list-date"><strong>{{ order.orderRegDate.split(' ')[0] }}</strong>{{ order.orderRegDate.split(' ')[1] }}</span>
          <p class="order-list-status">
            <span v-bind:class="classOrderState(order.orderState)">{{ getOrderState(order.orderState) }}</span>
            <span v-if="order.orderState === '2' || order.orderState === '3' || order.orderState === '4'" class="info">({{ textOrderState(order.orderState) }})</span>
          </p>
        </div>
        <div v-for="(shop, shopIdx) in order.shopList" v-bind:key="shopIdx" class="product-item">
          <router-link :to="{ name: 'DashboardOrderInfo', params: { shopIdx: shop.shopIdx, orderIdx: order.orderIdx } }">
            <div class="product-item-thumbnail">
              <img v-if="shop.imgPath" v-bind:src="getImage(shop.imgPath, 200, 200, 1)" alt="상점 이미지" @error="getOriginImage($event, shop.imgPath)">
              <img v-else src="/assets/image/thumbnail_preparing.svg" alt="상점 이미지">
            </div>
            <div class="order-item-content" :class="{ 'order-after': shop.review }">
              <p class="product-item-shop-name" v-bind:class="{ disabled: order.orderState === '1' || shop.shopOrderState === '6' || shop.shopOrderState === '7' }">{{ shop.shopName }}</p>
              <p class="product-item-name" v-if="shop.etcProductCount > 0">{{ shop.titleProductName }} 외 {{ shop.etcProductCount }}개 {{ shop.totalPrice|numberWithCommas }}원</p>
              <p class="product-item-name" v-else>{{ shop.titleProductName }} {{ shop.totalPrice|numberWithCommas }}원</p>
              <p v-if="shop.review" class="review-star-score">
                <img v-bind:src="'/assets/image/star_score_m_'+Math.ceil(shop.starRating)+'.svg'" v-bind:alt="'별점 '+shop.starRating+'점'" />
              </p>
            </div>
          </router-link>
          <span v-if="(order.orderState === '0' || order.orderState === '2') && shop.shopOrderState === '0'" class="order-item-button">
            <button type="button" class="btn btn-outline-pink" @click="cancelOrder(orderIdx, shopIdx, order.orderIdx, shop.shopIdx)">주문 취소</button>
          </span>
          <span v-if="order.orderState === '1' || shop.shopOrderState === '6' || shop.shopOrderState === '7'"  class="order-item-button">
            <button type="button" class="btn btn-outline-disabled">주문 취소됨</button>
          </span>
          <span v-if="order.orderState === '6' && !shop.review" class="order-item-button">
            <button type="button" class="btn btn-outline-pink" @click="$router.push({ name: 'PaymentReview', params: { orderIdx: order.orderIdx, shopIdx: shop.shopIdx, shopData: shop } })">리뷰 등록</button>
          </span>
          <span v-if="order.orderState === '6' && shop.review" class="order-item-button">
            <button type="button" class="btn btn-pink" @click="$router.push({ name: 'DashboardShopInfo', params: { tab: 3, shopIdx: shop.shopIdx } })">리뷰 보기</button>
          </span>
        </div>
      </div>
    </div>
    <div v-if="loaded && orders.length === 0" class="list-none-wrap">
      <div class="list-none">
        <p><img src="/assets/image/character_sad.svg" alt="주문내역이 없습니다." /></p>
        <p class="list-none-title">주문내역이 없습니다!</p>
      </div>
    </div>
    <dashboard-utils-menu menu="1"></dashboard-utils-menu>
  </div>
</template>

<script>
import DashboardUtilsMenu from "@/components/dashboard/utils/menu";

export default {
  name: "DashboardOrderList",
  components: {
    DashboardUtilsMenu,
  },
  data() {
    return {
      orders: [],
      loaded: false,
      page: 1,
      loading: false,
    }
  },
  mounted() {
    if(localStorage.getItem('guest') === 'true') {
      this.$router.back()
      this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
        localStorage.removeItem('autoLogin')
        this.$router.push({ name: 'Login' })
      }, () => {
      })
    } else {
      this.getOrder()

      window.$('#orderDiv').on('scroll', this.onScrollEvent)
    }
  },
  beforeDestroy() {
    window.$('#orderDiv').off('scroll', this.onScrollEvent)
  },
  methods: {
    onScrollEvent() {
      if(window.$('#orderDiv').scrollTop() + window.$('#orderDiv').innerHeight() >= window.$('#orderDiv')[0].scrollHeight - 10) {
        this.getOrder()
      }
    },
    textOrderState(state) {
      if(state === '0') {
        return ''
      } else if(state === '1') {
        return ''
      } else if(state === '2') {
        return '고객님의 주문을 확인 중입니다.'
      } else if(state === '3') {
        return '고객님의 상품을 준비중입니다.'
      } else if(state === '4') {
        return '고객님의 상품을 배달 준비중입니다.'
      } else if(state === '5') {
        return ''
      } else if(state === '6') {
        return ''
      }
    },
    classOrderState(state) {
      if(state === '0') {
        return 'status'
      } else if(state === '1') {
        return 'status-after disabled'
      } else if(state === '2') {
        return 'status'
      } else if(state === '3') {
        return 'status'
      } else if(state === '4') {
        return 'status'
      } else if(state === '5') {
        return 'status'
      } else if(state === '6') {
        return 'status-after'
      }
    },
    getOrder() {
      this.loaded = false
      if(this.loading) {
        return
      }

      this.loading = true

      this.$get(this.$USER_ORDER+'?page='+this.page, 'order_list_getOrder', (result) => {
        if(result.data.length > 0) {
          this.orders = this.orders.concat(result.data)
          this.page++

          this.loading = false
        }

        this.loaded = true
      }, (result) => {
        this.httpError(result.data)

        this.loading = false
      })
    },
    cancelOrder(i, j, orderIdx, shopIdx) {
      this.$root.$refs.Confirm.showDialog('주문을 취소하시겠습니까?', '예', '아니오', () => {
        this.$put(this.$USER_ORDER+'/'+shopIdx+'/'+orderIdx, 'order_list_cancelOrder', null, () => {
          this.orders[i].shopList[j].shopOrderState = '6'
          let flag = true
          for(let k=0; k<this.orders[i].shopList.length; k++) {
            if(this.orders[i].shopList[k].shopOrderState !== '6') {
              flag = false
            }
          }
          if(flag) {
            this.orders[i].orderState = '1'
          }
        }, (result) => {
          this.httpError(result.data)
        })
      }, () => {})
    },
  }
}
</script>

<style scoped>

</style>
