<template>
  <div class="all-content bg-white" id="scrollDiv">
    <div>
      <header class="header-wrap">
        <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-arrow-left"></i></button>
        <p>{{ shop.shopName }}</p>
      </header>
      <div class="shop-info-wrap">
        <div class="shop-info">
          <p class="shop-name">{{ shop.shopName }}</p>
          <p class="star-score">
            <span><img v-bind:src="'/assets/image/star_score_'+Math.ceil(shop.starRating)+'.svg'" v-bind:alt="'별점 '+shop.starRating+'점'" /></span>
            <span class="star-score-text">{{ shop.starRating }}</span>
          </p>
        </div>
        <div class="shop-link">
          <div class="shop-link-set">
            <span><img v-bind:src="'/assets/image/icon_marker_'+getCategoryName(shop.categoryName).replace('-', '_')+'_m.svg'" v-bind:alt="shop.categoryName?shop.categoryName:'기타'"></span>
            <span class="shop-link-category" v-bind:class="'category-'+getCategoryName(shop.categoryName)">{{ shop.categoryName?shop.categoryName:'기타' }}</span>
          </div>

          <div class="shop-link-set">
            <a role="button" @click="toggleHeart(shop.shopIdx)">
              <span>
                <img v-bind:src="shop.myWish?'/assets/image/icon_heart_m_on.svg':'/assets/image/icon_heart_m_off.svg'" alt="찜하기">
              </span>
              <span class="shop-link-text">찜</span><span>{{ shop.wishCount }}</span>
            </a>
          </div>
          <div class="shop-link-set">
            <a role="button" @click="share()">
              <span><img src="/assets/image/icon_sns_m.svg" alt="공유하기" /></span>
              <span>공유</span>
            </a>
          </div>
        </div>
        <div class="shop-photo">
          <p v-if="shop.logoImgPath">
            <a role="button" @click="showImage()">
              <img v-bind:src="getImage(shop.logoImgPath, null, 500, null)" alt="상점 이미지" @error="getOriginImage($event, shop.logoImgPath)">
            </a>
          </p>
          <p v-else>
            <img src="/assets/image/photo_preparing_shop.svg" alt="이미지 준비중입니다.">
          </p>
        </div>
        <!--<p class="shop-coupon"><img src="/assets/image/coupon_sample.svg" alt="샘플쿠폰" /></p>-->
        <!--탭메뉴-->
        <div class="tab-wrap">
          <ul>
            <li v-bind:class="{ active: tab===0 }" @click="changeTab(0)"><a role="button">상품안내</a></li>
            <li v-bind:class="{ active: tab===1 }" @click="changeTab(1)"><a role="button">매장안내</a></li>
            <li v-bind:class="{ active: tab===2 }" @click="changeTab(2)"><a role="button">시장안내</a></li>
            <li v-bind:class="{ active: tab===3 }" @click="changeTab(3)"><a role="button">리뷰 ({{ shop.reviewCount }})</a></li>
          </ul>
        </div>
        <dashboard-shop-info-home v-if="tab===0" v-bind:shopIdx="shopIdx"></dashboard-shop-info-home>
        <dashboard-shop-info-info v-if="tab===1" v-bind:shopIdx="shopIdx"></dashboard-shop-info-info>
        <dashboard-shop-info-market v-if="tab===2" v-bind:shopIdx="shopIdx"></dashboard-shop-info-market>
        <dashboard-shop-info-review ref="dashboardShopInfoReview" v-if="tab===3" v-bind:shopIdx="shopIdx"></dashboard-shop-info-review>
      </div>
    </div>
    <dashboard-utils-cart ref="dashboardUtilsCart" fnb="1" v-if="tab===0"></dashboard-utils-cart>
    <dashboard-utils-product ref="dashboardUtilsProduct" v-show="selectedProductIdx" v-if="tab===0"></dashboard-utils-product>
    <photo ref="photo"></photo>
  </div>
</template>

<script>
import DashboardShopInfoInfo from "@/components/dashboard/shop_info/info";
import DashboardShopInfoHome from "@/components/dashboard/shop_info/home";
import DashboardShopInfoReview from "@/components/dashboard/shop_info/review";
import DashboardShopInfoMarket from "@/components/dashboard/shop_info/market";
import DashboardUtilsProduct from "@/components/dashboard/utils/product";
import DashboardUtilsCart from "@/components/dashboard/utils/cart";
import Photo from "@/components/photo";

export default {
  name: "DashboardShopInfo",
  components: {
    DashboardShopInfoInfo,
    DashboardShopInfoHome,
    DashboardShopInfoReview,
    DashboardShopInfoMarket,
    DashboardUtilsProduct,
    DashboardUtilsCart,
    Photo,
  },
  data() {
    return {
      shopIdx: this.$route.params.shopIdx,
      tab: 1,
      shop: {
        logoImgPath: '',
        shopIdx: 0,
        shopInfoIdx: 0,
        shopName: '',
        starRating: '0',
        categoryName: '',
        myWish: false,
        wishCount: 0,
        reviewCount: 0,
      },
      selectedProductIdx: null,
    }
  },
  mounted() {
    if(typeof this.$route.params.tab !== 'undefined') {
      this.changeTab(this.$route.params.tab)
    } else {
      let tab = sessionStorage.getItem('DashboardShopInfoTab')
      if (tab) {
        this.changeTab(parseInt(tab))
      }
    }

    this.getShop()
  },
  methods: {
    share() {
      window.shareShop(this.shop.shopName, window.location.href)
    },
    showImage() {
      this.$refs.photo.showPhoto([this.shop.logoImgPath,]);
    },
    popupDone() {
      this.selectedProductIdx = null
      this.$refs.dashboardUtilsCart.getCart()
    },
    showPopup(idx) {
      this.selectedProductIdx = idx
      this.$refs.dashboardUtilsProduct.setIdx(this.selectedProductIdx)
    },
    changeTab(tab) {
      this.tab = tab
      sessionStorage.setItem('DashboardShopInfoTab', tab)
    },
    getShop() {
      this.$get(this.$USER_SHOP_DETAIL+'/'+this.shopIdx, 'shop_info_getShop', (result) => {
        this.shop = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleHeart(idx) {
      if(localStorage.getItem('guest') === 'true') {
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {

        })
      } else {
        let formData = new FormData()
        formData.append('type', '1')

        this.$put(this.$USER_WISH+'/'+idx, 'shop_info_toggleHeart', formData, (result) => {
          if(result.data.state) {
            this.shop.wishCount++
          } else {
            this.shop.wishCount--
          }
          this.shop.myWish = result.data.state
        }, (result) => {
          this.httpError(result.data)
        })
      }

    },
  }
}
</script>

<style scoped>

</style>