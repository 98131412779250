<template>
  <div v-if="show">
    <div class="overlay_popup">
      <header class="header-wrap header-wrap-black">
        <!-- 페이징 -->
        <div id="photoSwiperPagination" class="swiper-pagination"></div>
        <button type="button" class="btn btn-close" @click="close()"><i class="icon-close"></i></button>
      </header>
      <div class="photo-full-popup-wrap">
        <div class="photo-full-popup">
          <!-- 사진 스와이프  - 사진 최대 5장 -->
          <div id="photoSwiperContainer" class="swiper-container">
            <div class="swiper-wrapper">
              <div v-for="(image, imageIdx) in images" v-bind:key="imageIdx" class="swiper-slide"><img v-bind:src="image" alt="팝업 이미지"></div>
            </div>
            <!-- 스와이프 좌우 버튼 -->
            <div id="photoSwiperPrev" class="swiper-button-prev"></div>
            <div id="photoSwiperNext" class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Photo",
  data() {
    return {
      show: false,
      images: [],
      swiperObj: null,
    }
  },
  methods: {
    showPhoto(images) {
      this.images = images
      setTimeout(() => {
        this.initSwiper()
      }, 500)

      this.show = true
    },
    close() {
      this.show = false
    },
    initSwiper() {
      this.swiperObj = new window.Swiper('#photoSwiperContainer', {
        slidesPerView: "auto",
        speed: 400,
        autoplay: {
          delay : 5000,
          disableOnInteraction : false
        },
        pagination: {
          el: "#photoSwiperPagination",
          type: "fraction",
          renderFraction: function (currentClass, totalClass) {
            return '<div class="fr swiper-custom-pagination br-10 mr-10"><span class="' + currentClass + '"></span>' +
            '<span> / </span>' +
            '<span class="' + totalClass + '"></span></div>';
          }
        },
        navigation: {
          nextEl: '#photoSwiperNext',
          prevEl: '#photoSwiperPrev',
        },
      });
    }
  }
}
</script>

<style scoped>
.overlay_popup {
  z-index: 99;
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}
</style>