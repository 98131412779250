<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.replace({ name: 'Login' })"><i class="icon-back"></i></button>
      <p>아이디 찾기</p>
    </header>
    <div class="auth-form d-table">
      <p class="l24 mb-15">
        회원님의 아이디는<br />
        <strong>{{ id }}</strong>입니다.
      </p>
      <router-link :to="{ name: 'Login' }" replace class="btn btn-pink">로그인</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindIdResult",
  data() {
    return {
      id: this.$route.params.id,
    }
  },
}
</script>

<style scoped>

</style>