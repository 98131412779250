import axios from 'axios';

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
        timeout: 60000
    }
}
let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN + "/v1"

        Vue.prototype.$USER_CONFIRM_IDPASSWORD = "/user/confirmIdPassword"
        Vue.prototype.$USER_CONFIRM_EMAIL = "/user/confirmEmail"
        Vue.prototype.$USER_CONFIRM_PHONE = "/user/confirmPhone"
        Vue.prototype.$USER_SIGNUP = "/user/signUp"
        Vue.prototype.$USER_LOGIN = "/user/login"
        Vue.prototype.$USER_AUTOLOGIN = "/user/autoLogin"
        Vue.prototype.$USER_ADDRESS = "/user/address"
        Vue.prototype.$USER_UPDATEADDRESS = "/user/updateAddress"
        Vue.prototype.$USER_MARKET = "/user/market"
        Vue.prototype.$USER_UPDATEMARKET = "/user/updateUserMarket"
        Vue.prototype.$USER_MYMARKET = "/user/myMarket"
        Vue.prototype.$USER_REFRESHTOKEN = "/user/refreshToken"
        Vue.prototype.$USER_CATEGORY = "/user/category"
        Vue.prototype.$USER_PRODUCT = "/user/product"
        Vue.prototype.$USER_SHOP = "/user/shop"
        Vue.prototype.$USER_CART_COUNT = "/user/cart/count"
        Vue.prototype.$USER_CART = "/user/cart"
        Vue.prototype.$USER_PRODUCT_POP = "/user/product/pop"
        Vue.prototype.$USER_RECENTLY_USEDSHOP = "/user/recentlyUsedShop"
        Vue.prototype.$USER_WISH = "/user/wish"
        Vue.prototype.$USER_NOTIFICATION = "/user/notification"
        Vue.prototype.$USER_NOTIFICATION_STATE = "/user/notification/state"
        Vue.prototype.$USER_SHOP_DETAIL = "/user/shopDetail"
        Vue.prototype.$USER_SHOP_DETAIL_SHOP = "/user/shopDetail/shop"
        Vue.prototype.$USER_SHOP_DETAIL_MARKET = "/user/shopDetail/market"
        Vue.prototype.$USER_SHOP_DETAIL_PRODUCT = "/user/shopDetail/product"
        Vue.prototype.$USER_REVIEW = "/user/review"
        Vue.prototype.$USER_ORDER_FORM = "/user/order/form"
        Vue.prototype.$USER_ORDER_PAYMENT = "/user/order/payment"
        Vue.prototype.$USER_ORDER = "/user/order"
        Vue.prototype.$USER_PUSH = "/user/push"
        Vue.prototype.$USER_EVENT = "/user/event"
        Vue.prototype.$USER_EVENT_BANNER = "/user/eventBanner"
        Vue.prototype.$USER_VERSION = "/user/version"
        Vue.prototype.$USER_NOTICE = "/user/notice"
        Vue.prototype.$USER_FAQ = "/user/faq"
        Vue.prototype.$USER_QNA = "/user/qna"
        Vue.prototype.$USER_UPDATE_PHONE = "/user/updatePhone"
        Vue.prototype.$USER_RESET_MY_PASSWORD = "/user/resetMyPassword"
        Vue.prototype.$USER_FIND_ID = "/user/findId"
        Vue.prototype.$USER_FIND_PASSWORD = "/user/findPassword"
        Vue.prototype.$USER_RESET_PASSWORD = "/user/resetPassword"
        Vue.prototype.$USER_RESET_PHONE = "/user/resetPhone"
        Vue.prototype.$USER_AROUND_MAP = "/user/aroundShop"
        Vue.prototype.$USER_FCM_UPDATE = "/user/fcmUpdate"
        Vue.prototype.$USER_SECESSION = "/user/secession"
        Vue.prototype.$USER_LOGIN_GUEST = "/user/login/guest"
        Vue.prototype.$USER_GUEST = "/user/guest"

        Vue.prototype.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.data.message === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                if (response.data.message === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                if (response.data.message === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.message === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }
    }
})

