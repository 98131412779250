<template>
  <div class="overlay-content" v-if="show">
    <header class="header-wrap">
      <p>서비스 이용 동의</p>
      <button type="button" class="btn btn-close" @click="toggle()"><i class="icon-close"></i></button>
    </header>
    <div class="terms-txt-wrap auth-form policy ">
      <h3 class="terms-title">(필수) 이용약관</h3>
      <div class="terms-detail">
        <p>"마켓나우 서비스를 이용해 주셔서 감사합니다."</p>
        <p>마켓나우 서비스에 대한 이용약관(이하 “본 약관”이라 합니다) 안내입니다. 마켓나우 서비스는 다음과 같은 내용을 담고 있습니다.</p>

        <dl>
          <dt>제 1조 (목적)</dt>
          <dd>본 약관은 마켓나우 서비스(이하 ‘마켓나우’)의 이용과 관련하여 서비스를 제공하는 주식회사 모스트피엔에스(이하 ‘모스트피엔에스’)와 이를 이용하는 서비스 회원(이하 ‘회원’) 또는 비회원과의 관계를 설명하며, 아울러 서비스를 이용하시면서 도움이 될 수 있는 다양한 정보를 포함하고 있습니다.<br />또한, 회원의 권리. 의무 및 책임 사항을 규정함을 목적으로 합니다.</dd>
          <dd>※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」</dd>

          <dt>제 2조 (용어의 정의)</dt>
          <dd>
            <ol>
              <li>
                ‘마켓나우’란 ‘모스트피엔에스’가 상품(이하 ‘상품’이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 상품 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
              </li>
              <li>
                ‘이용자’란 ‘마켓나우’에 접속하여 본 약관에 따라 ‘마켓나우’가 제공하는 서비스를 받는 회원을 말합니다.
              </li>
              <li>
                ‘회원’이라 함은 ‘마켓나우’에 회원등록을 한 자로서, 계속적으로 ‘마켓나우’가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
              </li>
              <li>
                ‘서비스’라 함은 제4조 제1항에 따라 ‘회사’가 “고객”에게 제공하는 전자상거래 관련 서비스를 말합니다.
              </li>

            </ol>
          </dd>

          <dt>제 3조 (약관 등의 명시와 설명 및 개정)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호 ․ 모사전송번호 ․ 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 ‘마켓나우’의 서비스화면에 게시합니다. 다만, 본 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</li>
              <li>‘마켓나우’는 이용자가 본 약관에 동의하기에 앞서 본 약관에 정하여져 있는 내용 중 청약철회 ․ 배송책임 ․ 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.</li>
              <li>‘마켓나우’는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</li>
              <li>‘마켓나우’가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 ‘마켓나우’의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 ‘마켓나우’는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.</li>
              <li>‘마켓나우’가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 ‘마켓나우’의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 ‘마켓나우’는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.</li>
              <li>본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.</li>
              <li>이용자는 전항에 따라 변경되는 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 ‘마켓나우’ 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다.</li>

            </ol>
          </dd>

          <dt>제4조 (‘마켓나우’의 제공 및 변경)</dt>
          <dd>
            <ol>
              <li>
                ‘마켓나우’는 다음과 같은 업무를 수행합니다.
                <ul>
                  <li>상품 거래를 위한 플랫폼 운영 서비스</li>
                  <li>‘마켓나우’의 개발 및 운영 서비스</li>
                  <li>상품 구매 관련 업무 지원 서비스</li>
                  <li>상품 정보 검색 서비스</li>
                  <li>기타 전자상거래와 관련하여 ‘회사’가 정하는 서비스</li>
                </ul>
              </li>
              <li>‘마켓나우’는 상품 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 상품의 내용을 변경할 수 있습니다. 이 경우에는 변경된 상품의 내용 및 제공 일자를 명시하여 현재의 상품의 내용을 게시한 곳에 즉시 공지합니다.</li>
            </ol>
          </dd>
          <dt>제5조 (대리 및 보증의 부인) </dt>
          <dd>
            <ol>
              <li>‘회사’는 관련 법령에 따라 ‘회사’가 책임을 부담하는 경우를 제외하고는 통신판매중개자로서 통신판매중개 서비스의 제공을 위한 ‘사이트’ 등의 운영 및 관리 책임만을 부담하고, ‘사이트’에서의 ‘가맹점’과 ‘이용자’ 간의 상품의 거래와 관련하여 ‘가맹점’ 또는 ‘이용자’를 대리하지 아니하며, ‘가맹점’과 ‘이용자’ 간의 상품 거래에 관한 일체의 책임은 해당 ‘가맹점’과 ‘이용자’가 직접 부담합니다.</li>
              <li>‘회사’는 관련 법령에 따라 ‘회사’가 책임을 부담하는 경우를 제외하고는 ‘마켓나우’에서의 ‘가맹점’과 ‘이용자’ 간의 상품 거래와 관련하여 거래당사자의 판매의사 또는 구매의사의 존부 및 진정성, 상품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한 비침해성, ‘가맹점’이 제공하거나 등록한 정보 및 자료의 진실성 또는 적법성 등을 보증하지 아니하며, ‘가맹점’과 ‘이용자’ 간의 상품 거래에 관한 일체의 위험과 책임은 해당 ‘가맹점’과 ‘이용자’가 직접 부담합니다.</li>
            </ol>
          </dd>

          <dt>제6조 (‘마켓나우’의 중단)</dt>

          <dd>
            <ol>
              <li>‘마켓나우’는 컴퓨터 등 정보통신설비의 보수점검 ․ 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
              <li>‘마켓나우’는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제 3자가 입은 손해에 대하여 배상합니다. 단, ‘마켓나우’가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
              <li>사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우, ‘마켓나우’는 제8조에 정한 방법으로 이용자에게 통지하고 당초 ‘마켓나우’에서 제시한 조건에 따라 소비자에게 보상합니다.</li>
            </ol>
          </dd>

          <dt>제7조 (회원가입)</dt>
          <dd>
            <ol>
              <li>이용자는 ‘마켓나우’가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.</li>
              <li>
                ‘마켓나우’는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
                <ul>
                  <li>가입신청자가 이 약관 제 7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 다만, 제 7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 ‘마켓나우’의 회원재가입 승낙을 얻은 경우에는 예외로 한다.</li>
                  <li>등록 내용에 허위, 기재 누락, 오기가 있는 경우</li>
                  <li>기타 회원으로 등록하는 것이 ‘마켓나우’의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                </ul>
              </li>
              <li>회원가입계약의 성립 시기는 ‘마켓나우’의 승낙이 회원에게 도달한 시점으로 합니다.</li>
              <li>회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 ‘마켓나우’에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</li>
            </ol>
          </dd>

          <dt>제8조 (회원 탈퇴 및 자격 상실 등)</dt>
          <dd>
            <ol>
              <li>회원은 ‘마켓나우’에 언제든지 탈퇴를 요청할 수 있으며 ‘마켓나우’는 즉시 회원탈퇴를 처리합니다.</li>
              <li>
                회원이 다음 각 호의 사유에 해당하는 경우, ‘마켓나우’는 회원자격을 제한 및 정지시킬 수 있습니다.
                <ul>
                  <li>가입 신청 시에 허위 내용을 등록한 경우</li>
                  <li>‘마켓나우’를 이용하여 구입한 상품 등의 대금, 기타 ‘마켓나우’이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
                  <li>다른 사람의 ‘마켓나우’ 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
                  <li>‘마켓나우’를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                </ul>
              </li>
              <li>‘마켓나우’가 회원 자격을 제한 또는 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 ‘마켓나우’는 회원자격을 상실시킬 수 있습니다.</li>
              <li>‘마켓나우’가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</li>
            </ol>
          </dd>

          <dt>제9조 (회원에 대한 통지)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’가 회원에 대한 통지를 하는 경우, 회원이 ‘마켓나우’와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.</li>
              <li>‘마켓나우’는 불특정다수 회원에 대한 통지의 경우 1주일 이상 ‘마켓나우’ 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.</li>
            </ol>
          </dd>

          <dt>제10조 (구매신청 및 개인정보 제공 동의 등)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’이용자는 ‘마켓나우’상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, ‘마켓나우’는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.</li>
              <li>‘마켓나우’가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)</li>
              <li>‘마켓나우’가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.</li>
            </ol>
          </dd>

          <dt>제11조 (계약의 성립)</dt>
          <dd>
            <ol>
              <li>
                ‘마켓나우’는 제9조에 따른 구매신청이 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
                <ul>
                  <li>신청 내용에 허위, 기재 누락, 오기가 있는 경우</li>
                  <li>미성년자가 담배, 주류 등 청소년 보호법에서 금지하는 상품을 구매하는 경우</li>
                  <li>기타 구매신청에 승낙하는 것이 ‘마켓나우’ 기술상 현저히 지장이 있다고 판단하는 경우</li>
                </ul>
              </li>
              <li>‘마켓나우’의 승낙이 제 12조 제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.</li>
              <li>‘마켓나우’의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.</li>
            </ol>
          </dd>

          <dt>제12조 (지급방법)</dt>
          <dd>‘마켓나우’에서 구매한 상품 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, ‘마켓나우’는 이용자의 지급방법에 대하여 상품 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.</dd>
          <dd>
            <ol>
              <li>인터넷뱅킹 등의 각종 계좌이체</li>
              <li>선불카드, 직불카드, 신용카드 등의 각종 카드 결제</li>
              <li>전자화폐에 의한 결제</li>
              <li>수령 시 대금지급</li>
              <li>‘마켓나우’와 계약을 맺었거나 ‘마켓나우’가 인정한 상품권에 의한 결제</li>
              <li>기타 전자적 지급 방법에 의한 대금 지급 등</li>
            </ol>
          </dd>

          <dt>제13조 (수신확인통지․구매신청 변경 및 취소)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.</li>
              <li>수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 ‘마켓나우’는 상품 포장 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 상품을 수령한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.</li>
            </ol>
          </dd>

          <dt>제14조 (상품 등의 공급)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’는 이용자와 상품 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 당일에 상품 등을 배송할 수 있도록 제작 및 포장 요청 등 기타의 필요한 조치를 취합니다.</li>
              <li>‘마켓나우’는 이용자가 구매한 상품에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 ‘마켓나우’가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 ‘마켓나우’가 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다.</li>
              <li>‘마켓나우’는 이용자가 상품 결제 방법을, 수령시 대금지급(‘만나서 현금결제’)을 선택할 경우, 배송 매니저가 현금과 상품을 교환하는 것을 원칙으로 합니다. 만약 이용자가 대금을 지급하지 아니하면 상품 배송을 취소할 수 있습니다.</li>
            </ol>
          </dd>

          <dt>제15조 (환급)</dt>
          <dd>
            ‘마켓나우’는 이용자가 구매신청한 상품 등을 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 상품 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다. 단, 수령시 대금지급 방법을 선택한 이용자는 환급 대상이 아닙니다.
          </dd>

          <dt>제16조 (청약철회 등)</dt>
          <dd>
            <ol>
              <li>상품을 구매한 ‘이용자’는 가맹점이 주문을 확정하기 전까지 구매를 취소할 수 있습니다.</li>
              <li>상품을 구매한 ‘이용자’는 상품의 주문이 확정된 이후에는 구매를 취소할 수 없고, 반품 절차에 준하여 상품의 반환 및 환불을 신청할 수 있습니다.</li>
              <li>상품을 구매한 ‘이용자는 전자상거래법에 따라 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 ‘상품’의 공급이 늦게 이루어진 경우에는 ‘상품’을 공급받거나 ‘상품’이 공급이 시작된 날을 말합니다)부터 7일 이내에 ‘상품’의 교환 또는 반품을 신청할 수 있습니다.</li>
              <li>‘이용자’가 교환을 신청한 상품이 품절되어 교환이 불가한 경우에는 반품 절차에 준하여 상품의 반환 및 환불을 진행합니다.</li>
              <li>‘상품’의 반품에 관한 일반적인 사항은 전자상거래법 등 관련 법령의 강행규정이 ‘가맹점’이 제시한 조건에 우선하여 적용됩니다.</li>
              <li>상품의 반품을 신청한 ‘이용자’는 상품을 ‘가맹점’에게 즉시 반환하여야 하며, ‘이용자’가 ‘가맹점’에게 해당 상품을 반환하지 않은 경우에는 상품의 구매 취소 또는 반품 신청 의사를 철회한 것으로 간주합니다.</li>
              <li>‘회사’는 ‘가맹점’이 ‘이용자’의 구매취소, 교환, 반품 신청에 대한 사실확인을 게을리하는 경우에는, ‘이용자’를 통하여 구매취소, 교환, 반품에 대한 원인을 파악하고 직권으로 해당 구매계약을 취소할 수 있습니다.</li>
              <li>‘상품’의 반품에 소요되는 비용은 반품에 대하여 귀책사유 있는 자가 부담합니다.</li>
              <li>
                이용자는 상품 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
                <ul>
                  <li>이용자에게 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우(다만, 복제 등이 불가능한 상품 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 예외로 합니다.)</li>
                  <li>이용자의 사용 또는 일부 소비에 의하여 상품 등의 가치가 현저히 감소한 경우</li>
                  <li>시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우</li>
                  <li>같은 품질을 지닌 상품 등으로 복제가 가능한 경우 그 원본인 상품 등의 포장을 훼손한 경우</li>
                  <li>그 밖에 전자상거래법에서 청약철회 제한 사유로 정하는 경우</li>
                </ul>
              </li>
              <li>제 2항 제 2호 내지 제 4호의 경우에 ‘마켓나우’가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.</li>
              <li>이용자는 제1항 및 제2항의 규정에 불구하고 상품 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 상품 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.</li>

            </ol>
          </dd>

          <dt>제17조 (청약철회 등의 효과)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’는 이용자로부터 상품 등을 반환받은 경우 3영업일 이내에 이미 지급받은 상품 등의 대금을 환급합니다. 이 경우 ‘마켓나우’가 이용자에게 상품 등의 환급을 지연할 때에는 그 지연 기간에 대하여 「전자상거래 등에서의 소비자 보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.</li>
              <li>‘마켓나우’는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 상품 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 상품 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.</li>
              <li>청약철회 등의 경우 공급받은 상품 등의 반환에 필요한 비용은 이용자가 부담합니다. ‘마켓나우’는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 상품 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 상품 등의 반환에 필요한 비용은 ‘마켓나우’가 부담합니다.</li>
              <li>이용자가 상품 등을 제공받을 때 발송비를 부담한 경우에 ‘마켓나우’는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.</li>
            </ol>
          </dd>

          <dt>제18조 (개인정보보호)</dt>

          <dd>
            <ol>
              <li>‘마켓나우’는 이용자의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</li>
              <li>‘마켓나우’는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.</li>
              <li>‘마켓나우’는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</li>
              <li>‘마켓나우’는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</li>
              <li>‘마켓나우’가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</li>
              <li>이용자는 언제든지 ‘마켓나우’가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 ‘마켓나우’는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 ‘마켓나우’는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</li>
              <li>‘마켓나우’는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</li>
              <li>‘마켓나우’ 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공 받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.</li>
              <li>‘마켓나우’는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.</li>
              <li>
                ‘마켓나우’는 다음 각호의 경우 일정기간 동안 예외적으로 수집한 회원정보의 전부 또는 일부를 보관할 수 있습니다.
                <ul>
                  <li>고객요구사항 처리 목적 : 수집한 회원정보를 회원탈퇴 후 30일간 보유</li>
                  <li>기타 당사가 필요에 의해 별도로 동의를 득한 경우 : 별도 동의를 받은 범위 (회원정보 및 보유 기간) 내에서 보유</li>
                </ul>
              </li>
              <li>
                ‘마켓나우’는 위 10항에도 불구하고 상법 및 ‘전자상거래 등에서 소비자보호에 관한 법률' 등 관련 법령의 규정에 의하여 다음과 같이 일정기간 보유해야 할 필요가 있을 경우에는 관련 법령이 정한 기간 또는 다음 각 호의 기간 동안 회원정보를 보유할 수 있습니다.
                <ul>
                  <li>계약 또는 청약철회 등에 관한 기록 : 5년</li>
                  <li>대금결제 및 재화등의 공급에 관한 기록 : 5년</li>
                  <li>소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                </ul>
              </li>
              <li>‘마켓나우’는 수집된 개인정보의 처리 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 업체에 위탁할 수 있으며 위탁업체의 세부내용은 ‘마켓나우’ 혹은 제휴사의 서비스 어플리케이션의 ‘개인정보처리방침’을 통해 확인할 수 있습니다.</li>
            </ol>
          </dd>

          <dt>제19조 (‘마켓나우’의 의무)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 상품을 제공하는데 최선을 다하여야 합니다.</li>
              <li>‘마켓나우’는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</li>
              <li>‘마켓나우’가 상품에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.</li>
              <li>‘마켓나우’는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</li>
            </ol>
          </dd>

          <dt>제20조 (회원의 ID 및 비밀번호에 대한 의무)</dt>
          <dd>
            <ol>
              <li>제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</li>
              <li>회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</li>
              <li>회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 ‘마켓나우’에 통보하고 ‘마켓나우’의 안내가 있는 경우에는 그에 따라야 합니다.</li>
            </ol>
          </dd>

          <dt>제21조 (이용자의 의무)</dt>
          <dd>
            <ol>
              <li>
                이용자는 다음 행위를 하여서는 안됩니다.
                <ul>
                  <li>신청 또는 변경 시 허위 내용의 등록</li>
                  <li>타인의 정보 도용</li>
                  <li>‘마켓나우’에 게시된 정보의 변경</li>
                  <li>‘마켓나우’가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</li>
                  <li>‘마켓나우’ 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                  <li>‘마켓나우’ 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                  <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위</li>
                  <li>상품 주문 후 의도적인 다수의 주문 취소</li>
                </ul>
              </li>
            </ol>
          </dd>

          <dt>제22조 (저작권의 귀속 및 이용제한)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’가 작성한 저작물에 대한 저작권 기타 지식재산권은 ‘마켓나우’에 귀속합니다.</li>
              <li>이용자는 ‘마켓나우’를 이용함으로써 얻은 정보 중 ‘마켓나우’에게 지식재산권이 귀속된 정보를 ‘마켓나우’의 사전 서면 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</li>
              <li>‘마켓나우’는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.</li>
            </ol>
          </dd>

          <dt>제23조 (광고게재 및 광고주와의 거래)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고를 통한 수익으로부터 나옵니다. 서비스를 이용하고자 하는 자는 서비스 이용 시 푸쉬 알림받는 광고에 대해 동의하는 것으로 간주됩니다.</li>
              <li>‘마켓나우’는 회원의 동의를 얻어 영리목적의 광고성 e-mail 및 SMS, DM를 발송하거나 별도의 안내 권유 전화를 할 수 있으며 회원이 명백히 수신거부의사를 표시할 경우 당사는 이를 즉시 중지하여야 합니다. 당사는 재화/용역에 대하여「표시 광고의 공정화에 관한 법률」제3조 소정의 부당한 표시 광고행위를 하지 않습니다.</li>
              <li>‘마켓나우’는 본 서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 책임을 지지 않습니다.</li>
            </ol>
          </dd>

          <dt>제24조 (분쟁해결)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.</li>
              <li>‘마켓나우’는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.</li>
              <li>‘마켓나우’와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</li>
            </ol>
          </dd>

          <dt>제25조 (면책조항)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</li>
              <li>‘마켓나우’는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</li>
              <li>‘마켓나우’는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.</li>
              <li>‘마켓나우’는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.</li>
              <li>‘마켓나우’는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 회원의 고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.</li>
            </ol>
          </dd>

          <dt>제26조 (재판권 및 준거법)</dt>
          <dd>
            <ol>
              <li>‘마켓나우’와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소를 관할하는 지방법원의 전속관할에 속하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법에 따른 재판적을 가지는 관할법원에 제기합니다.</li>
              <li>‘마켓나우’와 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.</li>
            </ol>
          </dd>
        </dl>

        <p>[부칙]</p>

        <p>이 약관은 2021년 03월 29일부터 적용됩니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsUse",
  data() {
    return {
      show: false,
    }
  },
  methods: {
    toggle() {
      this.show = !this.show
    },
  }
}
</script>

<style scoped>
.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 51;
}
</style>
