<template>
  <div>
    <div class="market-photo-set">
      <p class="market-name">{{ market.marketName }}</p>
      <p v-if="market.imgPath" class="market-photo" @click="showImage()"><img v-bind:src="getImage(market.imgPath, null, 500, null)" alt="시장 이미지" @error="getOriginImage($event, market.imgPath)"></p>
    </div>
    <div class="shop-info">
      <p class="sub-title">시장 소개</p>
      <p class="shop-description" v-html="market.marketMemo">
      </p>
    </div>
    <div class="shop-info">
      <p class="sub-title">안내 및 혜택</p>
      <ul class="shop-info-detail">
        <li>
          <span class="detail-title">상인회 연락처</span>
          <span class="detail-content">{{ market.marketTel }}</span>
        </li>
        <li>
          <span class="detail-title">상인회 대표자</span>
          <span class="detail-content">{{ market.marketBoss }}</span>
        </li>
      </ul>
    </div>
    <div class="shop-info">
      <p class="sub-title">영업정보</p>
      <ul class="shop-info-detail">
        <li>
          <span class="detail-title">운영일자</span>
          <span class="detail-content">{{ textOperationDay(market.operationDay) }}</span>
        </li>
        <li>
          <span class="detail-title">운영시간</span>
          <span class="detail-content">{{ market.orderTimeS_H }}:{{ market.orderTimeS_M }} ~ {{ market.orderTimeE_H }}:{{ market.orderTimeE_M }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardShopInfoMarket",
  props: [
    'shopIdx'
  ],
  data() {
    return {
      market: {
        imgPath: '',
        marketBoss: '',
        marketIdx: 0,
        marketMemo: '',
        marketName: '',
        marketTel: '',
        operationDay: [],
        orderTimeE_H: '',
        orderTimeE_M: '',
        orderTimeS_H: '',
        orderTimeS_M: '',
      },
    }
  },
  mounted() {
    this.getMarketInfo()
  },
  methods: {
    showImage() {
      if(this.market.imgPath) {
        this.$parent.$refs.photo.showPhoto([this.market.imgPath,])
      }
    },
    getMarketInfo() {
      this.$get(this.$USER_SHOP_DETAIL_MARKET+'/'+this.shopIdx, 'market_getMarketInfo', (result) => {
        this.market = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    textOperationDay(operationDay) {
      let week = ['일', '월', '화', '수', '목', '금', '토']

      let result = '';

      for(let i=0; i<operationDay.length; i++) {
        result += week[operationDay[i]] + ', '
      }

      result = result.substr(0, result.length-2)

      if(result === '일, 월, 화, 수, 목, 금, 토') {
        result = "매일";
      }

      return result
    },
  }
}
</script>

<style scoped>

</style>