<template>
  <div class="all-content">
    <header class="header-wrap">
      <p>리뷰작성</p>
      <button type="button" class="btn btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="review-wrap">
      <p class="review-title">상품은 만족하셨나요?</p>
      <div class="review-box">
        <div class="review-score">
          <p class="review-shop-name">{{ shopData.shopName }}</p>
          <p class="review-product-name" v-if="shopData.etcProductCount > 0">{{ shopData.titleProductName }} 외 {{ shopData.etcProductCount }}개</p>
          <p class="review-product-name" v-else>{{ shopData.titleProductName }}</p>
          <p class="review-star-score">
            <img v-bind:src="'/assets/image/review_star_score_'+Math.ceil(star)+'.svg'" v-bind:alt="'별점 '+star+'점'" @click="setStar($event)">
          </p>
          <p v-if="star===0" class="color-pink">별점을 선택해주세요.</p>
          <p v-else class="color-pink">{{ star }}점</p>
        </div>
      </div>
      <div class="review-box">
        <label>어떤 점이 좋았나요??</label>
        <textarea class="textarea-default" placeholder="리뷰를 작성해주세요. (최소 10자 이상)" v-model="content"></textarea>
      </div>
      <div class="review-box">
        <label>파일첨부</label>
        <p>
          <span class="input-file">
            <input type="text" id="images_text" class="input-box" placeholder="jpg, png, gif만 가능, 최대 10MB" v-model="fileName" onclick="$('#images').click()" readonly>
            <button type="button" class="btn input-delete" @click="removeImage()"><i class="icon-close"></i></button>
            <input type="file" id="images" class="input-box" @change="changeImage($event)" style="display: none" accept="image/*">
          </span>
          <button type="button" class="attachments" onclick="$('#images').click()"><img src="/assets/image/icon_file.svg" alt="파일 아이콘" /></button>
        </p>
      </div>
      <div class="fnb-btn-group">
        <button class="btn btn-pink" @click="setReview()">리뷰 등록</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentReview",
  data() {
    return {
      shopIdx: this.$route.params.shopIdx,
      orderIdx: this.$route.params.orderIdx,
      shopData: this.$route.params.shopData,
      star: 0,
      content: '',
      images: '',
      fileName: '',
    }
  },
  methods: {
    setStar(event) {
      let star_w = event.target.clientWidth / 5
      let pos = event.offsetX

      let star = Math.ceil(pos / star_w)
      if(star < 0) {
        star = 0
      }

      if(star > 5) {
        star = 5
      }

      this.star = star
    },
    changeImage(event) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        return
      }

      this.images = files

      this.fileName = ''
      for(let i=0; i<this.images.length; i++) {
        this.fileName += files[i].name + ', '
      }

      this.fileName = this.fileName.substr(0, this.fileName.length-2)
      if(this.fileName.length > 27) {
        this.fileName = this.fileName.substr(0, 24) + '...'
      }
    },
    removeImage() {
      this.images = '';
      this.fileName = '';
    },
    setReview() {
      let formData = new FormData()
      formData.append('content', this.content.trim())
      for(let i=0; i<this.images.length; i++) {
        formData.append('images', this.images[i])
      }
      formData.append('rating ', this.star)

      this.$post(this.$USER_REVIEW+'/'+this.orderIdx+'/'+this.shopIdx, 'review_write_setReview', formData, () => {
        this.$router.back()
      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
