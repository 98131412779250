<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <p>주문완료</p>
    </header>
    <div class="cart-wrap bg-white auth-form vertical">
      <div class="ment">
        <p><img src="/assets/image/character_happy.svg" alt="주문이 완료되었습니다." /></p>
        <strong>주문이 완료되었습니다.</strong>
      </div>
      <div class="fnb-btn-group">
        <router-link :to="{ name: 'Dashboard' }">홈으로 가기</router-link>
        <router-link :to="{ name: 'DashboardOrderList' }">주문내역으로 가기</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess"
}
</script>

<style scoped>

</style>