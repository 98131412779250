<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>자주묻는 질문</p>
    </header>
    <div class="board-form" v-bind:class="{ vertical: faqs.length === 0 }">
      <div v-if="faqs.length > 0" class="faq-wrap bg-white">
        <ul v-for="(faq, faqIdx) in faqs" v-bind:key="faqIdx">
          <li @click="toggleFaq($event)">
              <i class="faq-helper" v-bind:class="faqIdx===0?'icon-arrow-up':'icon-arrow-down'"></i>
              <div class="faq-helper">
                Q. {{ faq.title }}
              </div>
              {{ faq.regDate }}
          </li>
          <li v-bind:style="faqIdx!==0?'':'display: block'" class="answer">
            <div class="content" v-html="faq.content">
            </div>
          </li>
        </ul>
      </div>
      <div v-if="faqs.length === 0" class="ment">
          <img src="/assets/image/character_sad.svg" alt="내역이 없습니다!" />
          <strong>내역이 없습니다!</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageQuestionFaq",
  data() {
    return {
      faqs: [],
    }
  },
  mounted() {
    this.getFaq()
  },
  methods: {
    getFaq() {
      this.$get(this.$USER_FAQ + '?type=2', 'faq_getFaq', (result) => {
        this.faqs = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleFaq(event) {
      if(window.$(event.target).parent().children('li:last-child').is(":visible")) {
        window.$(event.target).children('i').removeClass('icon-arrow-up')
        window.$(event.target).children('i').addClass('icon-arrow-down')

        window.$(event.target).parent().children('li:last-child').slideUp()
      } else {
        window.$(event.target).children('i').removeClass('icon-arrow-down')
        window.$(event.target).children('i').addClass('icon-arrow-up')

        window.$(event.target).parent().children('li:last-child').slideDown()
      }
    },
  }
}
</script>

<style scoped>
.faq-helper {
  pointer-events: none;
}
</style>