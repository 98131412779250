<template>
  <div class="all-content bg-white">
    <header class="header-wrap header-step">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>회원가입</p>
    </header>
    <div class="auth-form regist-form">
      <div class="regist-form-wrap">
        <ul class="step-list">
          <li class="step-item active">
            <span class="step-num">1</span>
            <span class="step-txt">계정 입력</span>
          </li>
          <li class="step-item">
            <span class="step-num">2</span>
            <span class="step-txt">이메일 입력</span>
          </li>
          <li class="step-item">
            <span class="step-num">3</span>
            <span class="step-txt">휴대폰 인증</span>
          </li>
          <li class="step-item">
            <span class="step-num">4</span>
            <span class="step-txt">완료</span>
          </li>
        </ul>
        <div class="step-content">
          <p class="step-title color-pink">step 1</p>
          <h3 class="step-sub-title">아이디, 비밀번호 입력</h3>
          <div class="input-group">
            <input type="text" class="input-default" placeholder="아이디 입력 (영문 소문자, 숫자 6 ~20자)" v-model="id">
            <p class="input-fail" v-show="idCheck===0">영문 소문자, 숫자 6 ~ 20자리 사이, 첫글자 영어로 입력해주세요.</p>
            <p class="input-fail" v-show="idCheck===1">중복되는 아이디입니다.</p>
            <p class="input-success" v-show="idCheck===2">사용가능한 아이디입니다.</p>
          </div>
          <div class="input-group">
            <input type="password" class="input-default m-letter" placeholder="비밀번호 입력(8~16자의 영문 대/소문자, 숫자, 특수문자 사용)" v-model="password">
            <p class="input-fail" v-show="passwordCheck===0">비밀번호입력(8~16자의 영문 대/소문자, 숫자, 특수문자 사용)</p>
            <p class="input-success" v-show="passwordCheck===1">사용가능한 비밀번호입니다.</p>
          </div>
          <div class="input-group">
            <input type="password" class="input-default m-letter" placeholder="비밀번호를 한번 더 입력하세요." v-model="passwordConfirm">
            <p class="input-fail" v-show="passwordConfirmCheck===0">비밀번호가 일치하지 않습니다.</p>
            <p class="input-success" v-show="passwordConfirmCheck===1">비밀번호가 일치합니다.</p>
          </div>
          <!--TODO: 모든값이 통과될 경우 active 클레스 추가 및 링크 활성화 -->
          <button type="button" class="btn btn-pink" @click="check()">다음</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistStep1",
  data() {
    return {
      marketing: false,
      idCheck: -1,
      passwordCheck: -1,
      passwordConfirmCheck: -1,
      id: '',
      password: '',
      passwordConfirm: '',
    }
  },
  mounted() {
    if(typeof this.$route.params.marketing === 'undefined') {
      this.$router.back()
    }

    this.marketing = this.$route.params.marketing
  },
  methods: {
    check() {
      if(this.id === '') {
        this.idCheck = 0
      } else {
        this.idCheck = -1
      }

      if(this.password === '') {
        this.passwordCheck = 0
      } else {
        this.passwordCheck = -1
      }

      if(this.password !== this.passwordConfirm) {
        this.passwordConfirmCheck = 0
      } else {
        this.passwordConfirmCheck = -1
      }

      if(this.id !== '' && this.password !== '' && this.password === this.passwordConfirm) {
        this.idCheck = 2
        this.passwordCheck = 1
        this.passwordConfirmCheck = 1

        let formData = new FormData()
        formData.append('id', this.id.trim())
        formData.append('password', this.password.trim())
        formData.append('passwordConfirm', this.passwordConfirm.trim())

        this.$post(this.$USER_CONFIRM_IDPASSWORD, 'regist_step1_check', formData, () => {
          this.$router.push({
            name: 'RegistStep2',
            params: { marketing: this.marketing, id: this.id, password: this.password }
          })
        }, (result) => {
          if(Array.isArray(result.data.error)) {
            for(let i=0; i<result.data.error.length; i++) {
              if(result.data.error[i].field === 'id') {
                this.idCheck = 0
              } else if(result.data.error[i].field === 'password') {
                this.passwordCheck = 0
              }
            }
          } else {
            if(result.data.error === '이미 존재하는 아이디입니다.') {
              this.idCheck = 1
            }
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>