<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <router-link :to="{ name: 'Dashboard' }" tag="button" class="btn btn-back"><i class="icon-back"></i></router-link>
      <p>매장</p>
      <button type="button" class="btn btn-search">
        <img src="/assets/image/icon_search.svg" />
      </button>
      <div class="search-product-popup hidden">
        <input type="search" placeholder="어떤 매장을 찾으시나요?" v-model="keyword" @keyup.enter="search()">
        <button class="close" @click="search()">닫기</button>
      </div>
    </header>
    <div class="category-position">
      <div id="categoryList" class="category-wrap">
        <div class="category-list">
          <div class="category-item" v-bind:class="{active: category.name === ''}" @click="setCategory(null)">전체보기</div>
          <div v-for="(item,idx) in categories" v-bind:key="idx" class="category-item" v-bind:class="{active: category.name === item.name}" @click="setCategory(item)">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="bg-block-ui hidden"></div>
    <div class="list-filter">
      <button v-if="keyword" class="btn btn-filter search-helper">검색어 ({{ keyword }})</button>
      <button type="button" class="btn btn-filter">
        <span class="list-filter-name">{{ getSortName(sort) }}</span><i class="icon-arrow-down"></i>
      </button>
      <div class="list-filter-popup hidden search-helper-wrap">
        <ul>
          <li>
            <div class="radio-wrap">
              <input type="radio" name="list-filter-item" id="list-filter-item-2" value="1" v-model="sort">
              <label for="list-filter-item-2">구매 순</label>
            </div>
          </li>
          <li>
            <div class="radio-wrap">
              <input type="radio" name="list-filter-item" id="list-filter-item-1" value="0" v-model="sort">
              <label for="list-filter-item-1">평점 순</label>
            </div>
          </li>
          <li>
            <div class="radio-wrap">
              <input type="radio" name="list-filter-item" id="list-filter-item-3" value="2" v-model="sort">
              <label for="list-filter-item-3">가나다 순</label>
            </div>
          </li>
<!--          <li>-->
<!--            <div class="radio-wrap">-->
<!--              <input type="radio" name="list-filter-item" id="list-filter-item-4" value="3" v-model="sort">-->
<!--              <label for="list-filter-item-4">리뷰 순</label>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="radio-wrap">-->
<!--              <input type="radio" name="list-filter-item" id="list-filter-item-5" value="4" v-model="sort">-->
<!--              <label for="list-filter-item-5">찜 순</label>-->
<!--            </div>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
    <div id="shopDiv" class="product-wrap">
      <div class="product-list">
        <div v-for="(shop, shopIdx) in shops" v-bind:key="shopIdx" class="product-item" :style="{opacity: shop.state ? 1.0 : 0.4}">
          <router-link :to="{ name: 'DashboardShopInfo', params: { tab: 1, shopIdx: shop.shopIdx } }">
            <div class="product-item-thumbnail">
              <img v-if="shop.imgPath" v-bind:src="getImage(shop.imgPath, 200, 200, 1)" alt="매장 이미지" @error="getOriginImage($event, shop.imgPath)">
              <img v-else src="/assets/image/thumbnail_preparing.svg" alt="이미지 준비중입니다.">
            </div>
            <div class="shop-item-content">
              <p class="product-item-badge">
                <span v-for="(category,categoryIdx) in shop.categoryName" v-bind:key="categoryIdx" class="badge" v-bind:class="'badge-'+getCategoryName(category)">{{ category }}</span>
                <span v-if="shop.brand" class="badge" v-bind:class="'badge-sinnago'">{{ shop.brand }}</span>
                <span v-if="shop.onnuri" class="badge" v-bind:class="'badge-onnuri'">온누리</span>
              </p>
              <p class="product-item-shop-name">{{ shop.shopName }}</p>
              <p class="product-item-name">{{ shop.shopInfo }}</p>
            </div>
          </router-link>
          <button type="button" class="btn btn-heart" @click="toggleHeart(shopIdx, shop.shopIdx)">
            <img v-bind:src="shop.wish ? '/assets/image/icon_shop_heart_on.svg' : '/assets/image/icon_shop_heart_off.svg'" alt="찜매장">
          </button>
        </div>
        <div class="cart-wrap" v-if="shops.length > 0">
          <div class="company-info">
            <p>
                <span class="company-info-icon">
                  <img src="/assets/image/icon_caution.svg" alt="통신판매중개자 안내" />
                </span>
              <span class="company-info-ment">
                  ㈜모스트피엔에스는 통신판매중개자로서 <br />‘마켓나우’ 서비스의 거래당사자가 아닙니다.
                </span>
            </p>
            <p>
              따라서 ㈜모스트피엔에스는 상품정보 및 거래에 대하여 책임을 지지 않습니다. 상품 및 거래에 관하여 정확한 정보는 해당 판매자에게 직접 확인하여 주시기 바랍니다.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded && shops.length === 0"  class="list-none-wrap">
      <div class="list-none">
        <p><img src="/assets/image/character_sad.svg" alt="캐릭터" /></p>
        <p v-if="keyword" class="list-none-title">검색 결과가 없습니다.</p>
        <p v-else class="list-none-title">해당 카테고리 상품 준비중입니다.</p>
      </div>
    </div>
    <dashboard-utils-cart></dashboard-utils-cart>
    <dashboard-utils-menu menu="0"></dashboard-utils-menu>
  </div>
</template>

<script>
import DashboardUtilsCart from "./utils/cart";
import DashboardUtilsMenu from "@/components/dashboard/utils/menu";

export default {
  name: "DashboardShop",
  components: {
    DashboardUtilsCart,
    DashboardUtilsMenu,
  },
  data() {
    return {
      category: {
        categoryIdx: 0,
        name: '',
        imgPath: '',
      },
      categories: '',
      shops: [],
      sort: '0',
      keyword: '',
      page: 1,
      loading: false,
      loaded: false,
    }
  },
  mounted() {
    this.initDom()

    if(typeof this.$route.params.category !== 'undefined') {
      this.category = this.$route.params.category
    }

    this.getCategory()
    this.getShop()

    window.$('#shopDiv').on('scroll', this.onScrollEvent)
  },
  beforeDestroy() {
    window.$('#shopDiv').off('scroll', this.onScrollEvent)
  },
  methods: {
    onScrollEvent() {
      if(window.$('#shopDiv').scrollTop() + window.$('#shopDiv').innerHeight() >= window.$('#shopDiv')[0].scrollHeight - 10) {
        this.getShop()
      }
    },
    getSortName(sort) {
      if(sort === '0') {
        return '평점 순'
      } else if(sort === '1') {
        return '구매 순'
      } else if(sort === '2') {
        return '가나다 순'
      } else if(sort === '3') {
        return '댓글 순'
      } else if(sort === '4') {
        return '찜 순'
      }
    },
    initDom() {
      // 필터 팝업 바인딩
      window.$(".btn-filter").click (function () {
        let popup = window.$(".list-filter-popup");
        if (popup.hasClass ("hidden")) {
          popup.removeClass ("hidden");
          window.$(this).find ("i").removeClass ("icon-arrow-down");
          window.$(this).find ("i").addClass ("icon-arrow-up");
          window.$(".bg-block-ui").removeClass ("hidden");
        } else {
          popup.addClass ("hidden");
          window.$(this).find ("i").removeClass ("icon-arrow-up");
          window.$(this).find ("i").addClass ("icon-arrow-down");
          window.$(".bg-block-ui").addClass ("hidden");
        }
      });

      // 상품 검색 팝업 바인딩
      window.$(".btn-search, .search-product-popup button.close").click (function () {
        let popup = window.$(".search-product-popup");
        if (popup.hasClass ("hidden")) {
          popup.removeClass ("hidden");
          window.$(".bg-block-ui").css({"z-index" : 4}).removeClass ("hidden");
        } else {
          popup.addClass ("hidden");
          window.$(".bg-block-ui").css({"z-index" : 2}).addClass ("hidden");
        }
      });
    },
    getCategory() {
      this.$get(this.$USER_CATEGORY, 'shop_list_getCategory', (result) => {
        this.categories = result.data

        setTimeout(() => {
          if(window.$('.category-item.active')[0]) {
            if(window.$('.category-item.active')[0].offsetLeft > window.$('#categoryList').width() - window.$('.category-item.active').outerWidth()) {
              window.$('#categoryList').animate({scrollLeft: window.$('.category-item.active')[0].offsetLeft - window.$('#categoryList').width() + window.$('.category-item.active').outerWidth()}, 100)
            }
          }
        }, 100)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    setCategory(category) {
      if(!category) {
        category = {
          categoryIdx: 0,
          name: '',
          imgPath: '',
        }
      } else {
        this.keyword = ''
      }
      this.category = category
      this.page = 1
      this.shops = []
      this.loading = false
      this.getShop()
    },
    getShop() {
      this.loaded = false
      if(this.loading) {
        return
      }

      this.loading = true

      this.$get(this.$USER_SHOP+'?categoryIdx='+this.category.categoryIdx+'&sort='+this.sort+'&keyWord='+this.keyword.trim()+'&page='+this.page, 'shop_list_getShop', (result) => {
        if(result.data.shops.length > 0) {
          this.shops = this.shops.concat(result.data.shops)
          this.page++

          this.loading = false
        }

        this.loaded = true
      }, (result) => {
        this.httpError(result.data)

        this.loading = false
      })
    },
    toggleHeart(i, idx) {
      let formData = new FormData()
      formData.append('type', '1')

      if(localStorage.getItem('guest') !== 'true') {
        this.$put(this.$USER_WISH+'/'+idx, 'shop_list_toggleHeart', formData, (result) => {
          this.shops[i].wish = result.data.state
        }, (result) => {
          this.httpError(result.data)
        })
      } else {
        this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
          localStorage.removeItem('autoLogin')
          this.$router.push({ name: 'Login' })
        }, () => {

        })
      }
    },
    search() {
      this.page = 1
      this.setCategory(null)
    },
  },
  watch: {
    sort() {
      this.page = 1
      this.shops = []
      this.loading = false
      this.getShop()

      window.$(".list-filter-popup").addClass ("hidden");
      window.$(this).find ("i").removeClass ("icon-arrow-up");
      window.$(this).find ("i").addClass ("icon-arrow-down");
      window.$(".bg-block-ui").addClass ("hidden");
    },
  }
}
</script>

<style scoped>
.search-helper {
  float: left;
  margin-left: 5px;
}
.search-helper-wrap {
  float: left;
  top: 0;
}
</style>
