<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>비밀번호 변경</p>
    </header>
    <div class="mypage-wrap">
      <div class="mypage-password">
        <form>
          <div class="password-input-group">
            <label for="Password">기존 비밀번호 입력</label>
            <input id="Password" type="password" class="m-letter" placeholder="기존 비밀번호를 입력하세요." v-model="password" autocomplete="false">
          </div>
          <div class="password-input-group">
            <label for="newPassword">새 비밀번호 입력</label>
            <input id="newPassword" type="password" class="m-letter" placeholder="새 비밀번호 입력(8~16자의 영문대/소문자, 숫자, 특수문자 사용)" v-model="newPassword" autocomplete="false">
          </div>
          <div class="password-input-group">
            <label for="newPasswordConfirm">새 비밀번호 확인</label>
            <input id="newPasswordConfirm" type="password" placeholder="비밀번호를 한번 더 입력하세요." v-model="newPasswordConfirm" autocomplete="false">
          </div>
          <div class="password-btn">
            <a role="button" class="btn btn-pink mt-0" @click="setPassword()">비밀번호 변경</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageResetPassword",
  data() {
    return {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    }
  },
  methods: {
    setPassword() {
      let formData = new FormData()
      formData.append('oldPassword', this.password.trim())
      formData.append('password', this.newPassword.trim())
      formData.append('passwordConfirm', this.newPasswordConfirm.trim())

      this.$put(this.$USER_RESET_MY_PASSWORD, 'reset_password_setPassword', formData, () => {
        this.notify('success', '비밀번호가 변경되었습니다.')
        this.$router.replace({ name: 'MypageSetup' })
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>