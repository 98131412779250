<template>
  <div class="basket-wrap shadow" v-bind:class="{ 'none-fnb': fnb }" v-if="cartCount > 0">
    <router-link :to="{ name: 'PaymentCart' }">
      <div class="basket-btn-wrap shadow">
        <span>{{ cartCount }}</span>
      </div>
      <img src="/assets/image/icon_basket_ellipse.svg" />
      <img src="/assets/image/icon_basket.svg" class="icon-basket" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DashboardUtilsCart",
  props: [
    'fnb',
  ],
  data() {
    return {
      cartCount: 0,
    }
  },
  mounted() {
    this.getCart()
  },
  methods: {
    getCart() {
      this.$get(this.$USER_CART_COUNT, 'cart_getCart', (result) => {
        this.cartCount = result.data.cartCount
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>
