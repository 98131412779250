import Vue from 'vue'
import App from './App.vue'
import router from './router'

router.beforeEach((to, from, next) => {
  let tokenMiddle = [
    'Dashboard',
    'Market',
    'MarketStep1',
    'MarketStep2',
    'DashboardAlarm',
    'DashboardProduct',
    'DashboardProductInfo',
    'DashboardShop',
    'DashboardShopInfo',
    'Payment',
    'PaymentCart',
    'PaymentReview',
    'Mypage',
    'MypageSetup',
    'MypageAgreement',
    'MypageEvent',
    'MypageEventInfo',
    'MypageMyMarket',
    'MypageResetPassword',
    'MypageChangePhone',
    'MypageNotice',
    'MypageQuestion',
    'MypageQuestionFaq',
    'MypageQuestionInquiry',
    'MypageQuestionInquiryWrite',
    'DashboardOrderList',
    'DashboardOrderInfo',
    'DashboardHeartList',
  ]

  if(tokenMiddle.indexOf(to.name) !== -1) {
    let token = sessionStorage.getItem('token')
    if(!token) {
      // window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-check\"></i> <span>로그인이 필요한 서비스입니다.</span></div>")
      // setTimeout(function() {
      //   window.$('#alert-wrap div:first-child').remove()
      // }, 3000)
      next({ name: 'Intro' })
    }
  }

  next()
})

Vue.config.productionTip = false

import Vuex from 'vuex'
Vue.use(Vuex)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import api from './commonjs/api';
Vue.use(api)

import common from "@/commonjs/common";
Vue.use(common)

import store from "./commonjs/store";

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
