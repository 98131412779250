<template>
  <div class="all-content">
    <header class="header-wrap bg-white">
      <p>주문실패</p>
    </header>
    <div class="cart-wrap bg-white auth-form vertical">
      <div class="ment">
        <p><img src="/assets/image/character_sad.svg" alt="주문이 실패되었습니다." /></p>
        <strong>주문이 실패되었습니다.</strong>
        <span>주문화면으로 이동합니다.</span>
      </div>
      <div class="fnb-btn-group">
        <button class="btn btn-pink" @click="$router.push({ name: 'Payment' })">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentFail"
}
</script>

<style scoped>

</style>