<template>
  <div>
    <div v-for="(type, typeIdx) in products" v-bind:key="typeIdx">
      <div v-if="type.product.length > 0" class="shop-category border-top mt-20" @click="toggleSub($event)">
        <span class="sub-helper">{{ type.classificationName }}</span>
        <button type="button" class="btn-right sub-helper"><i class="sub-helper icon-arrow-up"></i></button>
      </div>
      <div v-if="type.product.length > 0" class="product-list">
        <div v-for="(product, productIdx) in type.product" v-bind:key="productIdx" class="product-item">
          <router-link :to="{ name: 'DashboardProductInfo', params: { productIdx: product.productIdx } }">
            <div class="product-item-thumbnail">
              <img v-if="product.imgPath" v-bind:src="getImage(product.imgPath, 200, 200, 1)" alt="상품 이미지" @error="getOriginImage($event, product.imgPath)">
              <img v-else src="/assets/image/thumbnail_preparing.svg" alt="이미지 준비중">
            </div>
            <div class="shop-item-content">
              <p class="product-item-shop-name shop-none-badge">{{ product.productName }}</p>
              <p class="product-item-name mt-8">{{ product.productMemo }}</p>
              <p class="shop-home-price">{{ product.productPrice|numberWithCommas }}원</p>
            </div>
          </router-link>
          <button type="button" class="btn btn-add" @click="$parent.showPopup(product.productIdx)"><img src="/assets/image/icon_plus.svg" alt="상품 담기" /></button>
        </div>
      </div>
    </div>
    <div v-if="loaded && products.length === 0" class="product-list">
      <div class="product-item mt-20 b-none">
        <div class="list-none">
          <p><img src="/assets/image/character_sad.svg" alt="캐릭터" /></p>
          <p class="list-none-title">상품 준비중입니다.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardShopInfoHome",
  props: [
    'shopIdx'
  ],
  data() {
    return {
      products: [],
      loaded: false,
    }
  },
  mounted() {
    this.getProduct()
  },
  methods: {
    getProduct() {
      this.$get(this.$USER_SHOP_DETAIL_PRODUCT+'/'+this.shopIdx, 'home_getProduct', (result) => {
        let productArr = result.data
        productArr.forEach(product => {
          if(product !== null)
          this.products.push(product)
        })

        this.loaded = true
      }, (result) => {
        this.httpError(result.data)
      })
    },
    toggleSub(event) {
      if(window.$(event.target).parent().children('.product-list').is(":visible")) {
        window.$(event.target).children('button').children('i').removeClass('icon-arrow-up')
        window.$(event.target).children('button').children('i').addClass('icon-arrow-down')

        window.$(event.target).parent().children('.product-list').slideUp()
      } else {
        window.$(event.target).children('button').children('i').removeClass('icon-arrow-down')
        window.$(event.target).children('button').children('i').addClass('icon-arrow-up')

        window.$(event.target).parent().children('.product-list').slideDown()
      }
    },
  }
}
</script>

<style scoped>
.sub-helper {
  pointer-events: none;
  word-break: break-all;
}
</style>
