<template>
  <div>
    <div v-for="(review, reviewIdx) in reviews" v-bind:key="reviewIdx" v-bind:id="'review_'+review.reviewIdx" class="shop-review">
      <p class="review-title-set">
        <span>{{ review.userId }} 님</span>
        <span class="review-date">{{ review.lastDate }}</span>
        <span class="review-star-score"><img v-bind:src="'/assets/image/star_score_m_'+Math.ceil(review.starRating)+'.svg'" v-bind:alt="'별점 '+review.starRating+'점'" /></span>
      </p>
      <div>
        <a role="button" v-for="(image, imageIdx) in review.imgPaths" v-bind:key="imageIdx" v-bind:class="{ 'review-photo-1': review.imgPaths.length===1, 'review-photo-2': review.imgPaths.length===2, 'review-photo-3': review.imgPaths.length>2 }" @click="showImage(reviewIdx)">
          <img v-bind:src="getImage(image, null, 800, null)" alt="리뷰 이미지" @error="getOriginImage($event, image)">
        </a>
      </div>
      <p class="review-description" v-html="review.content">
      </p>
      <p>
        <span v-for="(product, productIdx) in review.productName" v-bind:key="productIdx" class="badge badge-review">{{ product }}</span>
      </p>
      <div v-html="review.answer" v-show="review.answer !== ''" class="review-answer"></div>
    </div>
    <!-- TODO:리뷰가 없을 경우 노출 -->
    <div v-if="loaded && reviews.length === 0" class="shop-review mt-20">
      <div class="list-none">
        <p><img src="/assets/image/character_sad.svg" alt="캐릭터" /></p>
        <p class="list-none-title">제일 먼저 리뷰를 남겨주세요.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardShopInfoReview",
  props: [
    'shopIdx'
  ],
  data() {
    return {
      reviews: [],
      loaded: false,
      page: 1,
      loading: false,
    }
  },
  mounted() {
    this.getReview()

    window.$('#scrollDiv').on('scroll', this.onScrollEvent)
  },
  beforeDestroy() {
    window.$('#scrollDiv').off('scroll', this.onScrollEvent)
  },
  methods: {
    onScrollEvent() {
      if(window.$('#scrollDiv').scrollTop() + window.$('#scrollDiv').innerHeight() >= window.$('#scrollDiv')[0].scrollHeight - 10) {
        this.getReview()
      }
    },
    showImage(idx) {
      if(this.reviews[idx].imgPaths.length > 0) {
        this.$parent.$refs.photo.showPhoto(this.reviews[idx].imgPaths)
      }
    },
    getReview() {
      this.loaded = false
      if(this.loading) {
        return
      }

      this.loading = true

      this.$get(this.$USER_REVIEW+'/'+this.shopIdx+'?page='+this.page, 'review_getReview', (result) => {
        console.log(result)
        if(result.data.length > 0) {
          this.reviews = this.reviews.concat(result.data)
          this.page++

          this.loading = false
        }

        this.loaded = true
      }, (result) => {
        this.httpError(result.data)

        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
