<template>
  <div>
    <div v-if="shop.imgPathList.length > 0" class="shop-photo-set">
      <div class="shop-photo-m" v-bind:style="'width: ' + shop.imgPathList.length * 140 + 'px;'">
        <ul>
          <li v-for="(img, imgIdx) in shop.imgPathList" v-bind:key="imgIdx" @click="showImage()">
            <img v-bind:src="getImage(img, null, 200, null)" alt="상점 이미지" style="object-fit: cover;" @error="getOriginImage($event, img)">
          </li>
          <li v-if="shop.imgPathList.length === 0"><img src="/assets/image/photo_preparing_shop_m.svg" alt="이미지 준비중"></li>
        </ul>
      </div>
    </div>
    <div class="shop-info">
      <p class="sub-title">{{ shop.shopName }}</p>
      <p class="shop-description" v-html="shop.shopInfo">
      </p>
    </div>
    <div class="shop-info">
      <p class="sub-title">안내 및 혜택</p>
      <ul class="shop-info-detail">
        <li>
          <span class="detail-title">연락처</span>
          <span class="detail-content detail-phone" @click="callPhone(shop.shopTel)">{{ shop.shopTel }}</span>
        </li>
        <li>
          <span class="detail-title">대표자</span>
          <span class="detail-content">{{ shop.shopBoss }}</span>
        </li>
        <li v-if="shop.category.length > 0">
          <span class="detail-title">혜택</span>
          <p class="detail-content">
            <span v-for="(category, index) in shop.category" :key="index" class="badge badge-sinnago">
              {{ category }}
            </span>
          </p>
        </li>
      </ul>
    </div>
    <div class="shop-info">
      <p class="sub-title">영업정보</p>
      <ul class="shop-info-detail">
        <li>
          <span class="detail-title">운영일자</span>
          <span class="detail-content">{{ textOperationDay(shop.orderDay) }}</span>
        </li>
        <li>
          <span class="detail-title">운영시간</span>
          <span class="detail-content">{{ shop.orderTimeS_H }}:{{ shop.orderTimeS_M }} ~ {{ shop.orderTimeE_H }}:{{ shop.orderTimeE_M }}</span>
        </li>
      </ul>
    </div>
    <div class="shop-info">
      <p class="sub-title">배달비용 안내</p>
      <ul class="shop-info-detail">
        <li>
          <span class="detail-title">배달비용</span>
          <span class="detail-content">{{ shop.deliveryFee|numberWithCommas }}원</span>
        </li>
      </ul>
    </div>
    <div class="shop-info">
      <router-link :to="{ name: 'MypageQuestionInquiryWrite', params: { shopIdx: shopIdx, shopName: shop.shopName } }" class="btn btn-pink">문의 하기</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashbordShopInfoInfo",
  props: [
    'shopIdx'
  ],
  data() {
    return {
      shop: {
        category: [],
        orderDay: [],
        orderTimeE_H: '',
        orderTimeE_M: '',
        orderTimeS_H: '',
        orderTimeS_M: '',
        shopBoss: '',
        shopInfo: '',
        shopTel: '',
        shopName: '',
        imgPathList: [],
        deliveryFee: 0,
      },
    }
  },
  mounted() {
    this.getShop()
  },
  methods: {
    showImage() {
      if(this.shop.imgPathList.length > 0) {
        this.$parent.$refs.photo.showPhoto(this.shop.imgPathList)
      }
    },
    textOperationDay(operationDay) {
      let week = ['일', '월', '화', '수', '목', '금', '토']

      let result = '';

      for(let i=0; i<operationDay.length; i++) {
        result += week[operationDay[i]] + ', '
      }

      result = result.substr(0, result.length-2)

      if(result === '일, 월, 화, 수, 목, 금, 토') {
        result = "매일";
      }

      return result
    },
    getShop() {
      this.$get(this.$USER_SHOP_DETAIL_SHOP+'/'+this.shopIdx, 'info_getShop', (result) => {
        this.shop = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
    callPhone(_phone) {
      window.location.href = 'tel:' + _phone
    }
  }
}
</script>

<style scoped>

</style>
