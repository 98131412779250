<template>
  <div class="all-content">
    <header class="header-wrap">
      <p>마이페이지</p>
    </header>
    <div class="dashboard-wrap">
      <div class="mypage-content">
        <div class="mypage-title bg-white">
          <p class="user-name">
            <strong>{{ address.name }}</strong> <span>님</span>
          </p>
          <router-link :to="{ name: 'MypageSetup' }" class="btn btn-setup">
            <img src="/assets/image/icon_setup.svg" alt="Button 설정" />
          </router-link>
        </div>
        <div class="mypage-content-list mypage-content-main bg-white">
          <div class="mypage-box" @click="$router.push({ name: 'MypageAgreement' })">
            <span>약관</span>
            <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
          </div>
          <div class="mypage-box" @click="$router.push({ name: 'MypageEvent' })">
            <span>이벤트</span>
            <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
          </div>
          <div class="mypage-box" @click="$router.push({ name: 'MypageMyMarket' })">
            <span>내시장</span>
            <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
          </div>
          <div class="mypage-box">
            <span>버전정보</span>
            <span class="mypage-version">{{ version }}</span>
          </div>
          <div class="mypage-box" @click="$router.push({ name: 'MypageNotice' })">
            <span>공지사항</span>
            <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
          </div>
          <div class="mypage-box" @click="$router.push({ name: 'MypageQuestion' })">
            <span>FAQ/문의</span>
            <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
          </div>
        </div>
      </div>
    </div>
    <dashboard-utils-menu menu="3"></dashboard-utils-menu>
  </div>
</template>

<script>
import DashboardUtilsMenu from "@/components/dashboard/utils/menu";

export default {
  name: "Mypage",
  components: {
    DashboardUtilsMenu,
  },
  data() {
    return {
      address: {
        name: '',
      },
      version: 'WEB',
    }
  },
  mounted() {
    if(localStorage.getItem('guest') === 'true') {
      this.$router.back()
      this.$root.$refs.Confirm.showDialog('로그인이 필요한 서비스입니다.', '로그인', '취소', () => {
        localStorage.removeItem('autoLogin')
        this.$router.push({ name: 'Login' })
      }, () => {

      })
    } else {
      this.getAddress((result) => {
        if(result.success) {
          this.address = result.data
        } else {
          this.httpError(result.data)
        }
      })

      if(window.getVersion()) {
        this.version = window.getVersion()
      }
    }

  }
}
</script>

<style scoped>

</style>
