<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>내 동네 설정</p>
    </header>
    <div class="auth-form h-unset">
      <div class="input-group">
        <div class="input-btn-group">
          <input type="text" class="input-default" placeholder="건물명, 도로명 또는 지번으로 검색" v-model="keyword" @keyup.enter="searchAddress()">
          <button type="button" class="btn btn-outline-pink br-20" @click="searchAddress()">검색</button>
        </div>
      </div>
      <router-link :to="{ name: 'MarketStep1' }" replace class="btn btn-outline-pink w-100 br-20">현재 위치로 주소 찾기</router-link>
    </div>
    <div id="address-wrap" class="address-wrap">
      <div v-for="(item, idx) in result" v-bind:key="idx" class="b-line-card">
        <router-link :to="{ name: 'MarketStep1', params: { address: item } }" replace>
          <strong>{{ item.address.address_name }}</strong>
          <span>도로명</span> {{ item.road_address.address_name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Market",
  data() {
    return {
      keyword: '',
      result: [],
    }
  },
  methods: {
    searchAddress() {
      if(this.keyword.trim() === '') {
        this.notify('error', '건물명, 도로명 또는 지번으로 검색하세요.')
        return;
      }
      axios.get("https://dapi.kakao.com/v2/local/search/address.json?query="+this.keyword.trim()+"&size=30", {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Authorization": "KakaoAK " + process.env.VUE_APP_KAKAO_KEY
        },
        timeout: 0
      }).then(response => {
        let result = response.data.documents

        this.result = []
        for(let i=0; i<result.length; i++) {
          if(result[i].address && result[i].road_address) {
            this.result.push(result[i])
          }
        }

        if(this.result.length === 0) {
          this.notify('error', '검색 가능한 주소가 존재하지 않습니다.')
        }
      }).catch((e) => {
        console.log(e)
      })
    },

    getGuestAddress() {
      if(sessionStorage.getItem('guest') === 'true') {
        let address = sessionStorage.getItem('address')
        this.keyword = JSON.parse(address).road
      }
    },
  },

   mounted() {
    this.getGuestAddress()
   }
}
</script>

<style scoped>

</style>
