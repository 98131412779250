<template>
  <div class="all-content bg-white">
    <header class="header-wrap header-step">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>회원가입</p>
    </header>
    <div class="auth-form regist-form">
      <div class="regist-form-wrap">
        <ul class="step-list">
          <li class="step-item active">
            <span class="step-num"><i class="icon-check"></i></span>
            <span class="step-txt">계정 입력</span>
          </li>
          <li class="step-item active">
            <span class="step-num">2</span>
            <span class="step-txt">이메일 입력</span>
          </li>
          <li class="step-item">
            <span class="step-num">3</span>
            <span class="step-txt">휴대폰 인증</span>
          </li>
          <li class="step-item">
            <span class="step-num">4</span>
            <span class="step-txt">완료</span>
          </li>
        </ul>
        <div class="step-content">
          <p class="step-title color-pink">step 2</p>
          <h3 class="step-sub-title">이메일 입력</h3>
          <div class="input-group">
            <!--TODO: Focus Out event 발생시 서버측으로 이메일 중복확인 요청-->
            <input type="text" class="input-default" placeholder="이메일 주소를 입력하세요." v-model="email">
            <p class="input-fail" v-show="emailCheck === 0">사용이 불가능하거나 중복되는 이메일 주소입니다.</p>
            <p class="input-success" v-show="emailCheck === 1">사용가능한 이메일 주소입니다.</p>
          </div>
          <button type="button" class="btn btn-pink" @click="check()">다음</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistStep2",
  data() {
    return {
      marketing: false,
      id: '',
      password: '',
      email: '',
      emailCheck: -1,
    }
  },
  mounted() {
    if(typeof this.$route.params.marketing === 'undefined' || typeof this.$route.params.id === 'undefined' || typeof this.$route.params.password === 'undefined') {
      this.$router.back()
    }

    this.marketing = this.$route.params.marketing
    this.id = this.$route.params.id
    this.password = this.$route.params.password
  },
  methods: {
    check() {
      if(this.email === '') {
        this.emailCheck = 0
      } else {
        this.emailCheck = -1
      }

      if(this.email !== '') {
        this.emailCheck = 1

        let formData = new FormData()
        formData.append('email', this.email.trim())

        this.$post(this.$USER_CONFIRM_EMAIL, 'regist_step2_check', formData, () => {
          this.$router.push({
            name: 'RegistStep3',
            params: { marketing: this.marketing, id: this.id, password: this.password, email: this.email }
          })
        }, (result) => {
          if(Array.isArray(result.data.error)) {
            for(let i=0; i<result.data.error.length; i++) {
              if(result.data.error[i].field === 'email') {
                this.emailCheck = 0
              }
            }
          } else {
            if(result.data.error === '이미 존재하는 이메일입니다.') {
              this.emailCheck = 0
            }
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>