<template>
  <div class="all-content bg-white">
    <terms-use ref="termsUse"></terms-use>
    <terms-privacy ref="termsPrivacy"></terms-privacy>
    <terms-electronic ref="termsElectronic"></terms-electronic>
    <terms-marketing ref="termsMarketing"></terms-marketing>
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>서비스 이용 동의</p>
    </header>
    <div class="auth-form">
      <div class="regist-terms-all-check">
        <div class="checks">
          <input type="checkbox" id="chk1" v-model="chk1" @click="agreeAll()">
          <label for="chk1" class="big"><strong>약관 전체 동의</strong></label>
        </div>
      </div>
      <div class="regist-terms-check">
        <div class="checks">
          <input type="checkbox" id="chk2" v-model="chk2">
          <label for="chk2">(필수) 이용약관</label>
          <button type="button" class="btn" @click="showTermsUse()"><i class="icon-arrow-right"></i></button>
        </div>
        <div class="checks">
          <input type="checkbox" id="chk3" v-model="chk3">
          <label for="chk3">(필수) 개인정보정책</label>
          <button type="button" class="btn" @click="showTermsPrivacy()"><i class="icon-arrow-right"></i></button>
        </div>
        <!--<div class="checks">
          <input type="checkbox" id="chk4" v-model="chk4">
          <label for="chk4">(필수) 전자금융거래</label>
          <button type="button" class="btn" @click="showTermsElectronic();"><i class="icon-arrow-right"></i></button>
        </div>
        <div class="checks">
          <input type="checkbox" id="chk5" v-model="chk5">
          <label for="chk5">(선택) 마케팅수신(푸쉬 알림)</label>
          <button type="button" class="btn" @click="showTermsMarketing();"><i class="icon-arrow-right"></i></button>
        </div>-->
      </div>
      <div class="company-info">
        <p>
          ㈜모스트피엔에스는 통신판매중개자로서 ‘마켓나우’ 서비스의 거래당사자가 아닙니다. 따라서 ㈜모스트피엔에스는 상품정보 및 거래에 대하여 책임을 지지 않습니다. 상품 및 거래에 관하여 정확한 정보는 해당 판매자에게 직접 확인하여 주시기 바랍니다.
        </p>
        <div class="mt-20">
          <p class="company">(주)모스트피엔에스</p>
          <p>
            대표이사  -  |  사업자등록번호  - <br />
            통신판매업  - <br />
            EMAIL  - <br />
            - <br />
            TEL  - <br />
            (평일 오전 10시~오후 6시, 주말 및 공휴일 제외)
          </p>
        </div>
      </div>
      <!--TODO: 필수가 모두 선택될 경우 active 클레스 추가 및 링크 활성화 -->
      <button type="button" class="btn btn-gray" v-bind:class="{ active: chkDone }" @click="done()">확인</button>
    </div>
  </div>
</template>

<script>
import TermsElectronic from "./terms/electronic"
import TermsMarketing from "./terms/marketing"
import TermsPrivacy from "./terms/privacy"
import TermsUse from "./terms/use"

export default {
  name: "Regist",
  components: {
    TermsElectronic,
    TermsMarketing,
    TermsPrivacy,
    TermsUse,
  },
  data() {
    return {
      chk1: false,
      chk2: false,
      chk3: false,
      //chk4: false,
      //chk5: false,
      chkDone: false,
    }
  },
  methods: {
    done() {
      if(this.chkDone) {
        this.$router.push({
          name: 'RegistStep1',
          params: { marketing: false }
          //params: { marketing: this.chk5 }
        })
      } else {
        this.notify('error', '약관 동의가 필요합니다.')
      }
    },
    agreeAll() {
      if(this.chk1) {
        this.chk2 = false
        this.chk3 = false
        //this.chk4 = false
        //this.chk5 = false
        this.chkDone = false
      } else {
        this.chk2 = true
        this.chk3 = true
        //this.chk4 = true
        //this.chk5 = true
        this.chkDone = true
      }
    },
    agreeCheck() {
      this.chk1 = this.chk2 && this.chk3
      //this.chk1 = this.chk2 && this.chk3 && this.chk4 && this.chk5
      this.chkDone = this.chk2 && this.chk3
      //this.chkDone = this.chk2 && this.chk3 && this.chk4
    },
    showTermsUse() {
      this.$refs.termsUse.toggle()
    },
    showTermsPrivacy() {
      this.$refs.termsPrivacy.toggle()
    },
    showTermsElectronic() {
      this.$refs.termsElectronic.toggle()
    },
    showTermsMarketing() {
      this.$refs.termsMarketing.toggle()
    }
  },
  watch: {
    chk2: function() {
      this.agreeCheck()
    },
    chk3: function() {
      this.agreeCheck()
    },
    chk4: function() {
      this.agreeCheck()
    },
    chk5: function() {
      this.agreeCheck()
    }
  }
}
</script>

<style scoped>

</style>