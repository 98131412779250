<template>
  <div class="all-content bg-white">
    <header class="header-wrap header-step">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>회원가입</p>
    </header>
    <div class="auth-form regist-form">
      <div class="regist-form-wrap">
        <ul class="step-list">
          <li class="step-item active">
            <span class="step-num"><i class="icon-check"></i></span>
            <span class="step-txt">계정 입력</span>
          </li>
          <li class="step-item active">
            <span class="step-num"><i class="icon-check"></i></span>
            <span class="step-txt">이메일 입력</span>
          </li>
          <li class="step-item active">
            <span class="step-num">3</span>
            <span class="step-txt">휴대폰 인증</span>
          </li>
          <li class="step-item">
            <span class="step-num">4</span>
            <span class="step-txt">완료</span>
          </li>
        </ul>
        <div class="step-content">
          <p class="step-title color-pink">step 3</p>
          <h3 class="step-sub-title">휴대폰 인증</h3>
          <div class="input-group">
            <!--TODO: Focus Out event 발생시 서버측으로 이메일 중복확인 요청-->
            <input type="text" class="input-default" placeholder="휴대폰 번호를 입력하세요." v-model="phone">
            <p class="input-fail" v-show="phoneCheck === 0">사용이 불가능하거나 중복되는 휴대폰 번호입니다.</p>
            <p class="input-success" v-show="phoneCheck === 1">사용가능한 휴대폰 번호입니다.</p>
          </div>
          <button type="button" class="btn btn-pink" @click="check()">다음</button>
        </div>
      </div>
    </div>
    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "RegistStep3",
  data() {
    return {
      marketing: false,
      id: '',
      password: '',
      email: '',
      phone: '',
      phoneCheck: '',
      nice: '',
      name: '',
    }
  },
  mounted() {
    if(typeof this.$route.params.marketing === 'undefined' || typeof this.$route.params.id === 'undefined' || typeof this.$route.params.password === 'undefined' || typeof this.$route.params.email === 'undefined') {
      this.$router.back()
    }

    this.marketing = this.$route.params.marketing
    this.id = this.$route.params.id
    this.password = this.$route.params.password
    this.email = this.$route.params.email
  },
  methods: {
    check() {
      if(this.phone === '') {
        this.phoneCheck = 0
      } else {
        this.phoneCheck = -1
      }

      if(this.phone !== '') {
        this.niceEncode()
      }
    },
    niceEncode() {
      axios.post("https://nice.reappay.net/check-pay-plus/request", null, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
      }).then(response => {
        if (response.data.success === true) {
          this.niceShow(response.data.enc_data)
        } else {
          console.log(response.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
    niceDone(e) {
      if(e.origin === 'https://nice.reappay.net') {
        console.log(e)
        this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.nice = e.data.payload

        window.removeEventListener('message', this.niceDone)

        this.niceCheck()
      }
    },
    niceCheck() {
      let formData = new FormData()
      formData.append('hp', this.phone.trim())
      formData.append('niceAuth', this.nice)

      this.$post(this.$USER_CONFIRM_PHONE, 'regist_step3_niceCheck', formData, (result) => {
        this.phoneCheck = 1

        this.name = result.data.name

        this.registDone()
      }, (result) => {
        if(Array.isArray(result.data.error)) {
          for(let i=0; i<result.data.error.length; i++) {
            if(result.data.error[i].field === 'hp') {
              this.phoneCheck = 0
              this.phone = ''
            }
          }
        } else {
          if(result.data.error === '이미 존재하는 연락처입니다.') {
            this.phoneCheck = 0
            this.phone = ''
          }
        }
      })
    },
    registDone() {
      let formData = new FormData()
      formData.append('alarmMarket', this.marketing)
      formData.append('niceAuth', this.nice)
      formData.append('email', this.email.trim())
      formData.append('hp', this.phone.trim())
      formData.append('id', this.id.trim())
      formData.append('name', this.name.trim())
      formData.append('password', this.password.trim())

      this.$post(this.$USER_SIGNUP, 'regist_step3_registDone', formData, () => {
        this.$router.push({ name: 'RegistStep4', params: { done: 'ok' } })
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>