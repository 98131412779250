<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>이벤트</p>
    </header>
    <div class="mypage-content">
      <div class="event-box">
        <p class="event-title ing">진행중 이벤트</p>
        <ul class="event-list">
          <li v-for="(event, eventIdx) in event.going" v-bind:key="eventIdx">
            <!--TODO: 배너 탭 시 event_view.html 하단에서 slide in 처리 해주시면 됩니다.-->
            <router-link :to="{ name: 'MypageEventInfo', params: { promotionIdx: event.promotionIdx } }" href="event_view.html">
              <img v-bind:src="getImage(event.imgPath, 1000, 1000, 2)" alt="이벤트 이미지"  @error="getOriginImage($event, event.imgPath)"/>
            </router-link>
            <span v-if="event.timeS" class="event-date">이벤트기간 : {{ event.timeS }} ~ {{ event.timeE }}</span>
          </li>
        </ul>
      </div>
      <div class="event-box">
        <p class="event-title">종료된 이벤트</p>
        <ul class="event-list">
          <li v-for="(event, eventIdx) in event.ungoing" v-bind:key="eventIdx">
            <!--TODO: 배너 탭 시 event_view.html 하단에서 slide in 처리 해주시면 됩니다.-->
            <router-link :to="{ name: 'MypageEventInfo', params: { promotionIdx: event.promotionIdx } }" href="event_view.html">
              <img v-bind:src="getImage(event.imgPath, 1000, 1000, 2)" alt="이벤트 이미지"  @error="getOriginImage($event, event.imgPath)"/>
            </router-link>
            <span class="event-date">이벤트기간 : {{ event.timeS }} ~ {{ event.timeE }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageEvent",
  data() {
    return {
      event: {
        going: [],
        ungoing: [],
      }
    }
  },
  mounted() {
    this.getEvent()
  },
  methods: {
    getEvent() {
      this.$get(this.$USER_EVENT, 'event_getEvent', (result) => {
        this.event = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>