<template>
  <div class="all-content bg-white">
    <div class="overlay-div">
      <header class="header-wrap">
        <button type="button" class="btn btn-back" @click="$router.back()"><i class="icon-back"></i></button>
        <p>상품</p>
        <button type="button" class="btn btn-search">
          <img src="/assets/image/icon_search.svg" />
        </button>
        <div class="search-product-popup hidden">
          <input type="search" placeholder="어떤 상품을 찾으시나요?" v-model="keyword" @keyup.enter="search()">
          <button class="close" @click="search()">닫기</button>
        </div>
      </header>
      <div class="category-position">
        <div id="categoryList" class="category-wrap">
          <div class="category-list">
            <div class="category-item" v-bind:class="{active: category.name === ''}" @click="setCategory(null)">전체보기</div>
            <div v-for="(item,idx) in categories" v-bind:key="idx" class="category-item" v-bind:class="{active: category.name === item.name}" @click="setCategory(item)">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="bg-block-ui hidden"></div>
      <div class="list-filter">
        <button v-if="keyword" class="btn btn-filter search-helper">검색어 ({{ keyword }})</button>
        <button type="button" class="btn btn-filter">
          <span class="list-filter-name">{{ getSortName(sort) }}</span><i class="icon-arrow-down"></i>
        </button>
        <div class="list-filter-popup hidden search-helper-wrap">
          <ul>
            <li>
              <div class="radio-wrap">
                <input type="radio" name="list-filter-item" id="list-filter-item-1" value="0" v-model="sort">
                <label for="list-filter-item-1">구매 순</label>
              </div>
            </li>
            <li>
              <div class="radio-wrap">
                <input type="radio" name="list-filter-item" id="list-filter-item-2" value="1" v-model="sort">
                <label for="list-filter-item-2">가나다 순</label>
              </div>
            </li>
            <li>
              <div class="radio-wrap">
                <input type="radio" name="list-filter-item" id="list-filter-item-3" value="2" v-model="sort">
                <label for="list-filter-item-3">찜 순</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div id="productDiv" class="product-wrap">
        <div class="product-list">
          <div v-for="(product,productIdx) in products" v-bind:key="productIdx" class="product-item" :style="{opacity: product.state ? 1.0 : 0.4}">
            <router-link :to="{ name: 'DashboardProductInfo', params: { productIdx: product.productIdx } }">
              <div class="product-item-thumbnail">
                <img v-if="product.imgPath" v-bind:src="getImage(product.imgPath, 200, 200, 1)" alt="상품 이미지" @error="getOriginImage($event, product.imgPath)">
                <img v-else src="/assets/image/thumbnail_preparing.svg" alt="이미지 준비중입니다.">
              </div>
              <div class="product-item-content">
                <p class="product-item-badge">
                  <span v-for="(category, categoryIdx) in product.categoryName" v-bind:key="categoryIdx" class="badge" v-bind:class="'badge-'+getCategoryName(category)">{{ category }}</span>
                  <span v-if="product.brand" class="badge" v-bind:class="'badge-sinnago'">{{ product.brand }}</span>
                  <span v-if="product.onnuri" class="badge" v-bind:class="'badge-onnuri'">온누리</span>
                </p>
                <p class="product-item-shop-name">{{ product.shopName }}</p>
                <p class="product-item-name">{{ product.productName }}</p>
              </div>
            </router-link>
            <button type="button" v-show="product.state" class="btn btn-add" @click="showPopup(product.productIdx)"><img src="/assets/image/icon_plus.svg" alt="상품 담기"></button>
            <p class="product-item-price">{{ product.price|numberWithCommas }}원</p>
          </div>
          <div class="cart-wrap" v-if="products.length > 0">
            <div class="company-info">
              <p>
                <span class="company-info-icon">
                  <img src="/assets/image/icon_caution.svg" alt="통신판매중개자 안내" />
                </span>
                <span class="company-info-ment">
                  ㈜모스트피엔에스는 통신판매중개자로서 <br />‘마켓나우’ 서비스의 거래당사자가 아닙니다.
                </span>
              </p>
              <p>
                따라서 ㈜모스트피엔에스는 상품정보 및 거래에 대하여 책임을 지지 않습니다. 상품 및 거래에 관하여 정확한 정보는 해당 판매자에게 직접 확인하여 주시기 바랍니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loaded && products.length === 0" class="list-none-wrap">
        <div class="list-none">
          <p><img src="/assets/image/character_sad.svg" alt="캐릭터" /></p>
          <p v-if="keyword" class="list-none-title">검색 결과가 없습니다.</p>
          <p v-else class="list-none-title">해당 카테고리 상품 준비중입니다.</p>
        </div>
      </div>
      <dashboard-utils-cart ref="dashboardUtilsCart"></dashboard-utils-cart>
      <dashboard-utils-menu menu="0"></dashboard-utils-menu>
    </div>
    <dashboard-utils-product ref="dashboardUtilsProduct" v-show="selectedProductIdx"></dashboard-utils-product>
  </div>
</template>

<script>
import DashboardUtilsCart from "./utils/cart";
import DashboardUtilsProduct from "./utils/product";
import DashboardUtilsMenu from "@/components/dashboard/utils/menu";

export default {
  name: "DashboardProduct",
  components: {
    DashboardUtilsCart,
    DashboardUtilsProduct,
    DashboardUtilsMenu,
  },
  data() {
    return {
      products: [],
      category: {
        categoryIdx: 0,
        name: '',
        imgPath: '',
      },
      categories: '',
      selectedProductIdx: null,
      sort: '0',
      keyword: '',
      page: 1,
      loading: false,
      loaded: false,
    }
  },
  mounted() {
    this.initDom()

    if(typeof this.$route.params.category !== 'undefined') {
      this.category = this.$route.params.category
    }

    this.getCategory()
    this.getProduct()

    window.$('#productDiv').on('scroll', this.onScrollEvent)
  },
  beforeDestroy() {
    window.$('#productDiv').off('scroll', this.onScrollEvent)
  },
  methods: {
    // imgLoadErr(e) {
    //   e.target.src = '/assets/image/thumbnail_preparing.svg'
    // },
    onScrollEvent() {
      if(window.$('#productDiv').scrollTop() + window.$('#productDiv').innerHeight() >= window.$('#productDiv')[0].scrollHeight - 10) {
        this.getProduct()
      }
    },
    getSortName(sort) {
      if(sort === '0') {
        return '구매 순'
      } else if(sort === '1') {
        return '가나다 순'
      } else if(sort === '2') {
        return '찜 순'
      }
    },
    popupDone() {
      this.selectedProductIdx = null
      this.$refs.dashboardUtilsCart.getCart()
    },
    showPopup(idx) {
      this.selectedProductIdx = idx
      this.$refs.dashboardUtilsProduct.setIdx(this.selectedProductIdx)
    },
    initDom() {
      window.$(".btn-filter").click (function () {
        let popup = window.$(".list-filter-popup");
        if (popup.hasClass ("hidden")) {
          popup.removeClass ("hidden");
          window.$(this).find ("i").removeClass ("icon-arrow-down");
          window.$(this).find ("i").addClass ("icon-arrow-up");
          window.$(".bg-block-ui").removeClass ("hidden");
        } else {
          popup.addClass ("hidden");
          window.$(this).find ("i").removeClass ("icon-arrow-up");
          window.$(this).find ("i").addClass ("icon-arrow-down");
          window.$(".bg-block-ui").addClass ("hidden");
        }
      });

      // 상품 검색 팝업 바인딩
      window.$(".btn-search, .search-product-popup button.close").click (function () {
        let popup = window.$(".search-product-popup");
        if (popup.hasClass ("hidden")) {
          popup.removeClass ("hidden");
          window.$(".bg-block-ui").css({"z-index" : 4}).removeClass ("hidden");
        } else {
          popup.addClass ("hidden");
          window.$(".bg-block-ui").css({"z-index" : 2}).addClass ("hidden");
        }
      });
    },
    getCategory() {
      this.$get(this.$USER_CATEGORY, 'product_list_getCategory', (result) => {
        this.categories = result.data

        setTimeout(() => {
          if(window.$('.category-item.active')[0]) {
            if(window.$('.category-item.active')[0].offsetLeft > window.$('#categoryList').width() - window.$('.category-item.active').outerWidth()) {
              window.$('#categoryList').animate({scrollLeft: window.$('.category-item.active')[0].offsetLeft - window.$('#categoryList').width() + window.$('.category-item.active').outerWidth()}, 100)
            }
          }
        }, 100)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getProduct() {
      this.loaded = false
      if(this.loading) {
        return
      }

      this.loading = true

      this.$get(this.$USER_PRODUCT+'?categoryIdx='+this.category.categoryIdx+'&sort='+this.sort+'&keyWord='+this.keyword.trim()+'&page='+this.page, 'product_list_getProduct', (result) => {
        if(result.data.shops.length > 0) {
          this.products = this.products.concat(result.data.shops)
          this.page++

          this.loading = false
        }

        this.loaded = true
      }, (result) => {
        this.httpError(result.data)

        this.loading = false
      })
    },
    setCategory(category) {
      if(!category) {
        category = {
          categoryIdx: 0,
          name: '',
          imgPath: '',
        }
      } else {
        this.keyword = ''
      }
      this.category = category
      this.page = 1
      this.products = []
      this.loading = false
      this.getProduct()
    },
    search() {
      this.page = 1
      this.setCategory(null)
    },
  },
  watch: {
    sort() {
      this.page = 1
      this.products = []
      this.loading = false
      this.getProduct()

      window.$(".list-filter-popup").addClass ("hidden");
      window.$(this).find ("i").removeClass ("icon-arrow-up");
      window.$(this).find ("i").addClass ("icon-arrow-down");
      window.$(".bg-block-ui").addClass ("hidden");
    },
  }
}
</script>

<style scoped>
.overlay-div {
  height: 100%;
}
.search-helper {
  float: left;
  margin-left: 5px;
}
.search-helper-wrap {
  float: left;
  top: 0;
}
</style>
