<template>
  <div class="intro-login-wrap">
    <div class="intro-login-top">
      <img src="assets/image/jangboda_bi.svg" alt="마켓나우" />
    </div>
    <div class="intro-login-bottom">
      <router-link :to="{ name: 'Login' }" class="btn btn-login">로그인</router-link>
      <button type="button" v-if="!checkSocial()" class="btn btn-guest">둘러보기</button>
      <router-link :to="{ name: 'Regist', params: { back: 'IntroLogin' }}" class="btn btn-regist">회원가입</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroLogin",
  // mounted() {
  //   let autoLogin = localStorage.getItem('autoLogin')
  //   if(autoLogin) {
  //     this.doAutoLogin(autoLogin)
  //   }
  // },
  methods: {
    checkSocial() {
      return window.useSocial()
    },
    // doGuestLogin() {
    //   this.$post(this.$USER_LOGIN_GUEST, 'intro_login_doGuestLogin', null, (result) => {
    //     sessionStorage.removeItem('address')
    //     sessionStorage.removeItem('market')
    //
    //     sessionStorage.setItem('token', result.data.accessToken)
    //     sessionStorage.setItem('refresh_token', result.data.refreshToken)
    //     localStorage.setItem('autoLogin', result.data.passToken)
    //     localStorage.setItem('guest', 'true')
    //
    //     setTimeout(() => {
    //       this.refreshToken()
    //     }, this.refreshTokenRate)
    //
    //     this.$router.push({ name: 'Dashboard'})
    //   }, (result) => {
    //     this.httpError(result.data)
    //
    //     sessionStorage.removeItem('token')
    //   })
    // },

    // doAutoLogin(passToken) {
    //   // localStorage.setItem('guest', 'false')
    //   console.log('auto login guest : ' + localStorage.getItem('guest'))
    //   let formData = new FormData()
    //   formData.append('passToken', passToken)
    //
    //   this.$post(this.$USER_AUTOLOGIN, 'intro_login_doAutoLogin', formData, (result) => {
    //     sessionStorage.removeItem('address')
    //     sessionStorage.removeItem('market')
    //
    //     sessionStorage.setItem('token', result.data.accessToken)
    //     sessionStorage.setItem('refresh_token', result.data.refreshToken)
    //     localStorage.setItem('autoLogin', result.data.passToken)
    //
    //     this.refreshPush()
    //
    //     setTimeout(() => {
    //       this.refreshToken()
    //     }, this.refreshTokenRate)
    //
    //     /*일반 회원 자동로그인일때만 토픽 등록*/
    //     if(localStorage.getItem('guest') !== 'true') {
    //       this.setPushAlarm(() => {
    //         this.$router.push({ name: 'Dashboard' })
    //       })
    //     } else {
    //       this.$router.push({ name: 'Dashboard' })
    //     }
    //
    //   }, (result) => {
    //     this.httpError(result.data)
    //
    //     sessionStorage.removeItem('token')
    //     sessionStorage.removeItem('refresh_token')
    //     localStorage.removeItem('autoLogin')
    //   })
    // },


    // setPushAlarm(callback) {
    //   this.$get(this.$USER_PUSH, 'login_getPush', (result) => {
    //     this.alarm = result.data
    //     this.getMarket((result) => {
    //       this.marketIdx = result.data.idx
    //       window.subscribedTopic('notice')
    //       this.alarm.alarmMarket ? window.subscribedTopic('event_all') : window.unsubscribedTopic('event_all')
    //       this.alarm.alarmMarket ? window.subscribedTopic(`event_${this.marketIdx}`) : window.unsubscribedTopic(`event_${this.marketIdx}`)
    //       this.alarm.alarmService ? window.subscribedTopic('service_all') : window.unsubscribedTopic('service_all')
    //       this.alarm.alarmService ? window.subscribedTopic(`service_${this.marketIdx}`) : window.unsubscribedTopic(`service_${this.marketIdx}`)
    //       callback()
    //     })
    //
    //   }, (result) => {
    //     this.httpError(result.data)
    //     callback()
    //   })
    // },
  }
}
</script>

<style scoped>
.btn-guest {
  text-align: center;
  width: 53.3%;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 9px 10px 8px 10px;
  border: 1px solid #fff;
  border-radius: 20px;
  margin-top: 10px;
  background-color: transparent;
}
</style>
